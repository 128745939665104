import {
  Button,
  Typography,
  Link,
  Grid,
  InputLabel,
  Paper,
  Card,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";

import { prepareData } from "../../controllers/patientController";
import login from "../../controllers/loginController";

/*import logo from '../../assets/logo.png'
import img01 from '../../assets/img01.png'
import img02 from '../../assets/img02.png'
import img03 from '../../assets/img03.png'
import img04 from '../../assets/img04.png'
import img05 from '../../assets/img05.png'
import img_equipe from '../../assets/img_equipe.png'
import img_patrocinio from '../../assets/img_patrocinio.png'*/

import Menu from "../../components/menu";
import Header from "../../components/header";

import logo_inspirar from "../../assets/logo-inspirar.png";
import play_store from "../../assets/playstore-badge.png";
import cuarenta_y_cinco_dias from "../../assets/45-dias.png";
import astrazeneca from "../../assets/astrazeneca.png";
import petalas from "../../assets/petalas.png";
import ola_mariana from "../../assets/ola-mariana.png";
import bombinha from "../../assets/bombinha.png";
import homem from "../../assets/homem.png";
import CookieConsent from "../../components/cookiesConsent";

const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;
  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = (ele) => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const useStyles = makeStyles((theme) => ({
  top: {
    height: "14vh",
    widht: "100vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    flexDirection: "column",
  },
  footer: {
    height: "20vh",
    widht: "100vw",
    backgroundColor: "#0B0D17",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  root: {
    height: "100vh",
    widht: "100vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tab_wide: {
    height: "auto",
    widht: "24vw",
    paddingTop: "3vh",
    paddingBottom: "20vh",
    paddingLeft: "0vw",
    paddingRight: "0vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  boton2: {
    marginTop: "3vh",
    height: "10vh",
  },
  tab_wide_colorful: {
    height: "auto",
    widht: "24vw",
    paddingTop: "3vh",
    paddingLeft: "6vw", //'9vw'
    paddingRight: "6vw", //'2vw'
    backgroundColor: "#fffff", //#A99DF6
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tab_to_right: {
    height: "auto",
    widht: "100vw",
    paddingTop: "3vh",
    paddingLeft: "10vw",
    paddingRight: "4vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tab_to_left: {
    height: "auto",
    widht: "100vw",
    paddingTop: "3vh",
    paddingLeft: "4vw",
    paddingRight: "10vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  inside_tab: {
    backgroundColor: "rgba(211, 205, 250, 0.47)", //#ffffff
  },
  textTitleHeavy: {
    color: "#18191F",
    marginTop: "2vh",
    marginBottom: "4vh",
    fontFamily: "roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 50, //50
  },
  textHeavy: {
    color: "#18191F",
    fontFamily: "roboto",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 34,
  },
  textTitleLight: {
    color: "#18191F",
    marginTop: "2vh",
    marginBottom: "4vh",
    fontFamily: "roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 48,
  },
  textLight: {
    color: "#18191F",
    fontFamily: "roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: 1.6,
    letterSpacing: 0.15,
  },
  textFooter: {
    fontFamily: "roboto",
    fontSize: 20,
    fontWeight: 400,
    color: "#EEEFF4",
    marginLeft: "2vw",
    letterSpacing: 0.4,
    position: "relative",
    top: -40,
  },
  mainContent: {
    width: "100%",
    height: "100%",
  },
  columnContent: {
    height: "auto",
    paddingTop: "7vh", // '9vh'
    paddingBottom: "9vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  columnContentHeader: {
    height: "auto",
    paddingTop: "12vh",
    paddingBottom: "0vh",
    paddingLeft: "6vw", //'3vw'
    paddingRight: "6vw", //'3vw'
  },
  columnContentFooter: {
    height: "auto",
    paddingTop: "6vh",
    paddingBottom: "0vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  name: {
    fontSize: 50,
    fontWeight: 100,
    letterSpacing: 1,
    marginBottom: "12vh",
    marginLeft: "1.5vw",
  },
  skip: {
    backgroundColor: "#ffffff",
    height: "10vh",
  },
  bigSkip: {
    backgroundColor: "#ffffff",
    height: "20vh",
  },
  medSkip: {
    backgroundColor: "#ffffff",
    height: "5vh",
  },
  text_cargo: {
    fontFamily: "roboto",
    "font-weight": "bold",
    "border-top": "2px #95D9D8 dashed",
    "margin-top": "1rem",
    "padding-top": "0.5rem",
  },
  boton: {
    marginTop: "3vh",
    height: "10vh",
    marginLeft: "2vw",
    width: "16vw",
  },

  googleplay: {
    cursor: "pointer",
    marginTop: "3vh",
    height: "10vh",
    marginLeft: "2vw",
    width: "16vw",
  },
  "@media (max-width: 620px)": {
    boton: {
      width: "36vw",
    },
    googleplay: {
      width: "46vw",
    },
  },
}));

// label to check
const label = { inputProps: { "aria-label": "Checkbox demo" } };
// styles to box on modal
const stylebox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #5c57f2",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 420px)": {
    width: "80%",
    p: 2,
  },
};

export default function Home() {
  const classes = useStyles();
  const history = useHistory();

  const [visibleSection, setVisibleSection] = useState();

  const headerRef = useRef(null);
  const buttonOneRef = useRef(null);
  const buttonTwoRef = useRef(null);
  const buttonThreeRef = useRef(null);
  const buttonFourRef = useRef(null);
  const buttonFiveRef = useRef(null);
  const buttonSixRef = useRef(null);
  const buttonSevenRef = useRef(null);

  const sectionRefs = [
    { section: "main1", ref: buttonOneRef },
    { section: "main2", ref: buttonTwoRef },
    { section: "main3", ref: buttonThreeRef },
    { section: "main4", ref: buttonFourRef },
    { section: "main5", ref: buttonFiveRef },
    { section: "main6", ref: buttonSixRef },
    { section: "main7", ref: buttonSevenRef },
  ];

  useEffect(() => {
    console.log("env", process.env.REACT_APP_TEST);
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [state, setState] = React.useState({
    t_privacy: false,
    t_service: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { t_privacy, t_service } = state;
  const box_error = [t_privacy, t_service].filter((v) => v).length !== 2;

  return (
    <>
      <CookieConsent />
      <Grid>
        <Grid container component="top" className={classes.top} ref={headerRef}>
          <Grid
            container
            item={true}
            md={9}
            xl={9}
            sm={9}
            className={classes.columnContentHeader}
            direction="row"
            justifyContent="left"
          >
            <img src={logo_inspirar} style={{ margin: "1rem 0" }} />
          </Grid>
        </Grid>
        <Grid className={classes.medSkip}></Grid>

        <Grid
          container
          component="main1"
          className={classes.tab_wide_colorful}
          ref={buttonOneRef}
        >
          <Grid container className={{ backgroundColor: "#ffffff" }}>
            <Grid
              container
              item={true}
              md={6}
              xl={6}
              sm={6}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <div className={classes.textTitleHeavy}>
                Monitore sua asma com o inspir<b>ar</b>
              </div>
              <div className={classes.textHeavy}>
                inspir<b>ar</b> é um aplicativo para o monitoramento da asma.
                <br></br>
                <br></br>
              </div>
              <Grid container justifyContent="center">
                <Grid item={true} xs={6}>
                  <Button
                    onClick={() => {
                      window.open(history.push("/login"), "_self");
                    }}
                    variant={"contained"}
                    color="primary"
                    className={classes.boton}
                  >
                    Login
                  </Button>
                </Grid>

                <Grid item={true} xs={6}>
                  <Button
                    onClick={handleOpen}
                    variant={"outlined"}
                    color="primary"
                    className={classes.boton}
                  >
                    {/**style={{marginTop:'3vh', height:'10vh', marginLeft:'2vw', width:'16vw'}} */}
                    Cadastre-se
                  </Button>
                </Grid>

                <img
                  src={play_store}
                  variant={"contained"}
                  className={classes.googleplay}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=br.app.inspirar",
                      "_blank"
                    )
                  }
                />
              </Grid>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={stylebox}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Antes de iniciar seu cadastro, por favor verifique se
                    concorda com os termos de serviço e a politica de
                    privacidade do aplicativo inspirar.
                  </Typography>

                  <FormControl
                    required
                    error={box_error}
                    component="fieldset"
                    variant="standard"
                  >
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={t_privacy}
                              onChange={handleChange}
                              name="t_privacy"
                            />
                          }
                          label={
                            <div>
                              <span>Concordo com os </span>
                              <Link
                                onClick={() => {
                                  scrollTo(headerRef.current);
                                  history.push("/termsofuse");
                                }}
                              >
                                {" "}
                                Termos de Serviço
                              </Link>
                              .
                            </div>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={t_service}
                              onChange={handleChange}
                              name="t_service"
                            />
                          }
                          label={
                            <div>
                              <span>Concordo com a </span>
                              <Link
                                onClick={() => {
                                  scrollTo(headerRef.current);
                                  history.push("/privacypolicy");
                                }}
                              >
                                {" "}
                                Política de Privacidade
                              </Link>
                              .
                            </div>
                          }
                        />
                      </FormGroup>
                    </Typography>
                    <Button
                      disabled={box_error}
                      onClick={() => {
                        scrollTo(headerRef.current);
                        history.push("/register");
                      }}
                      variant={"contained"}
                      color="primary"
                      className={classes.boton2}
                    >
                      Próximo
                    </Button>
                  </FormControl>
                </Box>
              </Modal>
            </Grid>

            <Grid
              container
              item={true}
              md={6}
              xl={6}
              sm={6}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <img
                src={ola_mariana}
                style={{
                  width: "60vh",
                  height: "auto",
                  "max-width": "100%",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/*<Grid className={classes.skip}></Grid>*/}

        <Grid
          container
          component="main2"
          className={classes.tab_wide_colorful}
          ref={buttonTwoRef}
        >
          <Grid container className={classes.inside_tab}>
            <Grid
              container
              item={true}
              md={6}
              xl={6}
              sm={6}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <div className={classes.textTitleLight}>Sobre o projeto</div>
              <div className={classes.textLight}>
                O aplicativo inspir<b>ar</b> contribui para o acompanhamento
                conjunto da asma pelo paciente e pelo profissional de saúde, nas
                múltiplas estratégias do tratamento – o monitoramento dos
                sintomas e da medicação, o estímulo à atividade física
                monitorada e a informação de qualidade.<br></br>
                <br></br>
              </div>
              <Grid container justifyContent="center">
                <Button
                  onClick={() => {
                    scrollTo(headerRef.current);
                    history.push("/faq");
                  }}
                  variant={"contained"}
                  color="primary"
                  className={classes.boton}
                >
                  Perguntas Frequentes
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              item={true}
              md={6}
              xl={6}
              sm={6}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <img
                src={cuarenta_y_cinco_dias}
                style={{
                  width: "60vh",
                  backgroundColor: "#C4C4C4",
                  height: "auto",
                  "max-width": "100%",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/*<Grid className={classes.skip}></Grid>*/}

        <Grid
          container
          component="main3"
          className={classes.tab_wide_colorful}
          ref={buttonThreeRef}
        >
          {/* classes.tab_to_left*/}
          <Grid container className={{ backgroundColor: "#ffffff" }}>
            <Grid
              container
              item={true}
              md={6}
              xl={6}
              sm={6}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <div className={classes.textTitleLight}>Sobre a asma</div>
              <div className={classes.textLight}>
                A asma é uma doença crônica das vias aéreas, caracterizada por
                limitação ao fluxo aéreo expiratório que está associado a
                sintomas respiratórios como sibilos, falta de ar, aperto no
                peito e tosse. Por se tratar de uma doença que não tem cura, seu
                tratamento visa controlar os sintomas a fim de melhorar a
                qualidade de vida das pessoas com asma, evitando que elas tenham
                de ter constantes visitas ao médico, e também reduzindo
                consideravelmente o número de crises.
                <br></br>
                <br></br>
              </div>
            </Grid>
            <Grid
              container
              item={true}
              md={6}
              xl={6}
              sm={6}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <img
                src={bombinha}
                style={{
                  width: "60vh",
                  height: "auto",
                  "max-width": "120%",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/*<Grid className={classes.skip}></Grid>*/}

        <Grid
          container
          component="main4"
          className={classes.tab_wide_colorful}
          ref={buttonFourRef}
        >
          {/* classes.tab_to_left*/}
          <Grid container className={{ backgroundColor: "#ffffff" }}>
            <Grid
              container
              item={true}
              md={6}
              xl={6}
              sm={6}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <img
                src={homem}
                style={{
                  width: "60vh",
                  height: "auto",
                  "max-width": "100%",
                  "max-height": "600px",
                }}
              />
            </Grid>
            <Grid
              container
              item={true}
              md={6}
              xl={6}
              sm={6}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <div className={classes.textTitleLight}>
                Quem pode usar o inspir<b>ar</b>?
              </div>
              <div className={classes.textLight}>
                No momento, o inspir<b>ar</b> destina-se apenas a pessoas com
                asma em acompanhamento pelo ambulatório de Pneumologia e
                Fisioterapia do Hospital das Clínicas da Faculdade de Medicina
                da Universidade de São Paulo (HC-FMUSP).<br></br>
                <br></br>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/*<Grid className={classes.skip}></Grid>*/}

        <Grid
          container
          component="main5"
          className={classes.tab_wide_colorful}
          ref={buttonFiveRef}
        >
          <Grid
            container
            style={{ backgroundColor: "rgba(211, 205, 250, 0.47)" }}
            className={classes.inside_tab}
          >
            {/*backgroundColor:'#A99DF6'*/}
            <Grid container style={{ marginTop: "0vh" }}>
              <img
                src={petalas}
                style={{ position: "relative", top: "-44px" }}
              />
            </Grid>
            <Grid
              container
              item={true}
              md={9}
              xl={9}
              sm={9}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <div className={classes.textTitleLight}>A iniciativa</div>
              <div className={classes.textLight}>
                inspir<b>ar</b> é o resultado do projeto de pesquisa
                “Desenvolvimento de aplicativo móvel para controle clínico para
                pessoas com asma” junto ao HC-FMUSP sob coordenação do Prof. Dr.
                Celso R F Carvalho.<br></br>
                <br></br>O projeto de pesquisa foi aprovado pela Comissão de
                Ética para Análise de Projetos de Pesquisa (CAPPesq) sob CAAE
                51770721.4.0000.0068.<br></br>
                <br></br>O aplicativo foi concebido e desenvolvido como projeto
                de graduação multidisciplinar, com envolvimento de professores e
                alunos de três unidades da Universidade de São Paulo:<br></br>
                <ul style={{ marginLeft: 30 }}>
                  <li>
                    Departamento de Fonoaudiologia, Fisioterapia e Terapia
                    Ocupacional da Faculdade de Medicina;
                  </li>
                  <li>
                    Departamento de Engenharia de Computação e Sistemas Digitais
                    da Escola Politécnica;
                  </li>
                  <li>
                    Departamento de Projeto da Faculdade de Arquitetura e
                    Urbanismo.
                  </li>
                </ul>
              </div>
              <div className={classes.textTitleLight}>Contato</div>
              <div className={classes.textLight}>
                Para saber mais sobre esse projeto, entre em contato com a
                equipe em{" "}
                <a href="mailto:appinspirar@gmail.com">appinspirar@gmail.com</a>
                !<br></br>
                <br></br>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/*<Grid className={classes.skip}></Grid>*/}
        <Grid
          container
          component="main6"
          className={classes.tab_wide_colorful}
          ref={buttonSixRef}
        >
          <Grid container className={{ backgroundColor: "#ffffff" }}>
            <Grid
              container
              item={true}
              md={9}
              xl={12}
              sm={9}
              className={classes.columnContent}
              direction="column"
              justifyContent="left"
            >
              <div className={classes.textTitleLight}>A equipe</div>
              <div className={classes.textLight}>
                <Grid container>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Coordenação</div>
                    Prof. Dr. Celso R F Carvalho, FMUSP
                  </Grid>

                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Equipe técnica</div>
                    Profa. Dra. Lucia Villela Leite Filgueiras, EPUSP
                  </Grid>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Equipe técnica</div>
                    Prof. Dr. Leandro Manuel Reis Velloso, FAU USP
                  </Grid>

                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Desenvolvimento</div>
                    André Victor Fernandes Bacci, EPUSP
                  </Grid>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Desenvolvimento</div>
                    Bruno Henrique Koga Fazano, EPUSP
                  </Grid>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Desenvolvimento</div>
                    Pedro Cruz Gramacho, EPUSP
                  </Grid>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Desenvolvimento</div>
                    Victor Praxedes Rael, EPUSP
                  </Grid>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Desenvolvimento</div>
                    Carlos Enrique Paucar Farfán, IMEUSP
                  </Grid>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Design</div>
                    Giselle Akemi Tsukahara Jensen, FAU USP
                  </Grid>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Design</div>
                    Gabriela Yuri Kamida, ECA USP
                  </Grid>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Pesquisa</div>
                    Henrique Gerardus van der Laan, FMUSP
                  </Grid>
                  <Grid md={4} xl={3}>
                    <div className={classes.text_cargo}>Pesquisa</div>
                    Thais Erissa Haraguni, FMUSP
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/*<Grid className={classes.skip}></Grid>*/}

        <Grid
          container
          component="main7"
          className={classes.tab_wide_colorful}
          ref={buttonSevenRef}
        >
          <div className={classes.textTitleLight}>Patrocínio:</div>
          <img
            src={astrazeneca}
            style={{ width: "60vh", height: "auto", "max-width": "100%" }}
          />
        </Grid>

        <Grid className={classes.medSkip}></Grid>

        <Grid
          container
          component="footer_buttons"
          style={{ backgroundColor: "black" }}
        >
          <Grid
            container
            md={12}
            xl={12}
            sm={12}
            className={classes.columnContentHeader}
            direction="row"
            justifyContent="left"
          >
            <Button
              onClick={() => {
                scrollTo(headerRef.current);
                history.push("/termsofuse");
              }}
              variant={"outlined"}
              color="secondary"
              style={{
                marginTop: "3vh",
                height: "12vh",
                marginLeft: "2vw",
                width: "16vw",
                minWidth: "25vw",
              }}
            >
              Termos de Serviço
            </Button>
            <Button
              onClick={() => {
                scrollTo(headerRef.current);
                history.push("/privacypolicy");
              }}
              variant={"outlined"}
              color="secondary"
              style={{
                marginTop: "3vh",
                height: "12vh",
                marginLeft: "2vw",
                width: "16vw",
                minWidth: "25vw",
              }}
            >
              Política de Privacidade
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          component="footer_line"
          style={{ backgroundColor: "black" }}
        >
          <Grid
            container
            item={true}
            md={12}
            xl={12}
            sm={12}
            className={classes.columnContentFooter}
            direction="row"
            justifyContent="left"
          >
            <div className={classes.textFooter}>
              © 2024 inspir<b>ar</b>. Todos os direitos reservados.
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
