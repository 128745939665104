import Amplify, { API } from "aws-amplify";
import { AlertDialogDeleteProfessional } from "../components/dialog";

async function listProfessionals() {
   Amplify.configure({
      Auth: {
         region: process.env.REACT_APP_REGION,
         userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
         userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
         mandatorySignIn: true,
      },
      API: {
         endpoints: [
            {
               name: "webEndpoints",
               endpoint: process.env.REACT_APP_API_ENDPOINT,
            },
         ],
      },
   });

   var professionals = [];

   await API.get("webEndpoints", "/professionals/list")
      .then(async (response) => {
         professionals = response.items;
      })
      .catch((error) => {
         console.error(JSON.stringify(error));
      });

   try {
      professionals.sort((a, b) =>
         a.full_name.toUpperCase() > b.full_name.toUpperCase()
            ? 1
            : b.full_name.toUpperCase() > a.full_name.toUpperCase()
            ? -1
            : 0
      );
      return professionals;
   } catch {}
}

export async function prepareData() {
   const rows = [];
   const professionals = await listProfessionals();

   for (var i = 0; i < professionals.length; i++) {
      rows.push({
         id: professionals[i].professional_id,
         full_name: professionals[i].full_name,
         personal_phone: professionals[i].personal_phone,
         email: professionals[i].email,
         type:
            professionals[i].type.charAt(0).toUpperCase() +
            professionals[i].type.slice(1),
      });
   }

   const columns = [
      {
         field: "full_name",
         headerName: "Nome Completo",
         sortable: true,
         width: 280,
      },
      { field: "email", headerName: "Email", sortable: true, width: 250 },
      {
         field: "personal_phone",
         headerName: "Contato",
         sortable: true,
         width: 140,
      },
      { field: "type", headerName: "Tipo", sortable: true, width: 140 },
   ];

   return [rows, columns];
}
