import {Button, Typography, TextField, Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import React, { useState } from 'react';

import {confirmSignUp, resendSignUp } from '../../controllers/confirmSignUpController';
  
const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: '100vh',
    },
    content: {
      maxWidth: 500,
      paddingInline: 20,
      paddingBlock: 40
    },
    content2: {
      height: 450,
      width: 500,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      border: '1px solid #80808042',
      borderRadius: 10
    },
    content3: {
      height: 100,
      width: 500,
    },
    name: {
      fontSize: 20,
      fontWeight: 300,
      letterSpacing: 1,
      marginBottom: 15
    },
    name2: {
      fontSize: 28,
      fontWeight: 500,
      letterSpacing: 1,
      marginBottom: 15,
    },
    submit: {
      textTransform: 'capitalize',
      width: 85,
      marginTop: 20,
      height: 45
    },
    loading: {
      width: '100%',
      position: 'fixed',
      top: 0,
      height: 7
    },
    error: {
      fontSize: 12,
    },
    resend: {
      fontSize: 16,
      marginLeft: 25,
      marginTop: 20,
      color: '#5c57f2',
      cursor: 'pointer',
      '&:hover': {
        color: '#3c38d6',
      }
    },
  }));
  
export default function Confirm() {

    const classes = useStyles();
    const history = useHistory();
    const [code, setCode] = useState("")
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    async function submit() {
      setLoading(true);
      setError("")
      //console.log("google token: ", token);
      const result = await confirmSignUp(email, code);
      if (result.msg != "") alert(result.msg)
      if (result.err) {
        if (result.err_msg != "") setError(result.err_msg)
      } else {
        history.push("/")
      }
      setLoading(false)
    }

    async function resend() {
      setLoading(true);
      setError("")
      const result = await resendSignUp(email);
      if (result.msg != "") alert(result.msg)
      if (result.err) {
        if (result.err_msg != "") setError(result.err_msg)
      }
      setLoading(false)
    }

    return (
      <Grid container component="main" className={classes.root} justifyContent="center" alignItems="center">
        {loading? <LinearProgress className={classes.loading} color="primary" /> : ""}
        <Grid item className={classes.content} justifyContent="flex-start">
          <Typography className={classes.name} color="primary">
            Confirme sua conta
          </Typography>
          <form noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              color="primary"
              onChange={(e) => setEmail(e.target.value)}
              />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              label="Código"
              id="code"
              color="primary"
              onChange={(e) => setCode(e.target.value)}
            />
            <Typography color="error" className={classes.error}>
              {error}
            </Typography>
            <Grid container className={classes.content3} alignItems="center" style={{width:'100%'}}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => submit()}
                disabled={loading}
              >
                Ativar
              </Button>
              <Typography onClick={() => resend()} className={classes.resend}>
                Reenviar código
              </Typography>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
