import Amplify, { Auth, API } from 'aws-amplify';

export async function validatePersonalInfo(personalInfo, personalInfoErrors) {

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const birthRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/


    function parseDate(_input){
        // input yyyy-mm-dd
        // output dd/mm/yyyy
        let strArray = _input.split("-")
        let strOutput = ""
        for(let val of strArray){
          strOutput = val+'/'+strOutput
        }
        return strOutput.substring(0,strOutput.length-1)
    }

    function validCFP(input) {
        // extract no digits characters
        input = input.replace(/\D/g,'');
        if (input.length == 11) {
            personalInfo.cpf = input
            return true
        } else {
            return false
        }
    }

    function validPhone(input) {
        // extract no digits characters
        input = input.replace(/\D/g,'');
        if (input.length == 11) {
            personalInfo.personal_phone = input
            return true
        } else {
            return false
        }
    }

    const err = {
        full_name: "",
        cpf: "",
        email: "",
        personal_phone: "",
        birth_date: "",
        height: "",
        weight: ""
    };

    var hasErr =  false;

    if (personalInfo.full_name.length < 5) {
        err.full_name = "Nome muito curto!";
        hasErr = true;
    }
    if (!validCFP(personalInfo.cpf)) {
        err.cpf = "CPF inválido! Utilize apenas números.";
        hasErr = true;
    }
    if (personalInfo.email.length < 5 || !emailRegex.test(personalInfo.email)) {
        err.email = "Email inválido!";
        hasErr = true;
    }
    if (!validPhone(personalInfo.personal_phone)) {
        err.personal_phone = "Telefone inválido!";
        hasErr = true;
    }
    if (personalInfo.birth_date.length < 10 || !birthRegex.test(personalInfo.birth_date)) {
        err.birth_date = "Data de nascimento inválida! Verifique que ela segue o formato.";
        hasErr = true;
    } else {
        // change format to send in correct no problem format
        personalInfo.birth_date = parseDate(personalInfo.birth_date)
    }
    if (personalInfo.height < 100) {
        err.height = "A altura deve ser dada em centímetros!";
        hasErr = true;
    }
    if (!passwordRegex.test(personalInfo.password)) {
        err.password = `A sua senha deve conter:
                        Pelo menos oito caracteres;
                        Letras maiúsculas e minúsculas;
                        Pelo menos um número;
                        Pelo menos um caractere especial`
        hasErr = true;
    }

    if (personalInfo.password !== personalInfo.passwordConfirmation) {
        err.passwordConfirmation = "As senhas não são as mesmas!";
        hasErr = true;
    }

    let myInit = { 
        // headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    }
    
    await API.get("webEndpoints", '/patients/' + personalInfo.email, myInit).then(async response => {
        // FALTA IMPLEMENTAR: Atualizar a store do redux com os pacientes e redirecionar para página correta + tratar erro 404
        err.email = true;
        hasErr = true;
        alert('Email já cadastrado!')
    }).catch(error => {
    });

    if (hasErr) {
        return err;
    }

    return false;

}

export async function submit(personal, acq, barriers, goals, professional) {

    var err, msg;

    var score = 0;
    goals.steps_per_day = parseInt(goals.steps_per_day)
    goals.km_per_week = parseInt(goals.km_per_week)
    
    for (let key in barriers) {
        barriers[key] = -100000
    }
    for (let key in acq) {
        acq[key] = parseInt(acq[key].split("."[0]))
        if (key == "q7" && acq[key] == 7) {
            continue;
        }
        score = score + acq[key]
    }
    
    if (acq.q7 == 7) {
        score = score/6
    } else {
        score = score/7
    }

    acq = {...acq, type : "acq", score : score}

    // const prof_email = (await Auth.currentAuthenticatedUser()).attributes.email

    const body = {
        ...personal,
        acq : acq,
        barriers : barriers,
        goals : {...goals, active_minutes_per_day:0}
    }
    
    Amplify.configure({
        Auth: {
            region: process.env.REACT_APP_REGION,
            userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID_PAT,
            userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID_PAT
        },
        API: {
            endpoints: [
                {
                name: "webEndpoints",
                endpoint: process.env.REACT_APP_API_ENDPOINT
                }
            ]
        }
        
    });
    
    await Auth.signUp({
        username: personal.email,
        password: personal.password,
        email: personal.email
    })
    .then(data => {
        console.log('ok');
    })
    .catch(err => {
        err = true
        msg = 'Erro ao cadastrar usuário, tente novamente mais tarde'
    });

    if (err) return {err,msg}
    
    let myInit = { 
        // headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
        body: body
    }
    
    var data;

    await API.post("webEndpoints", '/patients/create', myInit).then(async response => {
        err = false
        msg = 'Sucesso'
        data = response
    }).catch(error => {
        console.error(JSON.stringify(error))
        err = true
        msg = 'Erro ao criar paciente no banco de dados, tente novamente mais tarde'
    });
    return {err, msg, data}

}
// Re send code
export async function resendPatConfirmationCode(username) {
    var er;
    Amplify.configure({
        Auth: {
            region: process.env.REACT_APP_REGION,
            userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID_PAT,
            userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID_PAT
        },
        API: {
            endpoints: [
                {
                name: "webEndpoints",
                endpoint: process.env.REACT_APP_API_ENDPOINT
                }
            ]
        }
        
    });
    try {
        await Auth.resendSignUp(username);
        alert("Código de verificação reenviado.");
        er = false
    } catch (err) {
        er = true
        var msg = err.toString().split(":");
        //console.log(msg);
        if(msg[0]=="LimitExceededException"){
            alert("Erro. Limite de tentativas excedido, tente novamente em alguns minutos.");
        }
        else if(msg[0]=="NotAuthorizedException"){
            alert("Erro. Usuário não autorizado, a conta deste usuário não foi criada corretamente.");
        }
        else if(msg[0]=="UserNotFoundException"){
            alert("Erro. Conta de usuário não encontrada.");
        }
        else if(msg[0]=="InvalidParameterException"){
            alert("Erro. O usuário já verificou sua conta.");
        }
        else{
            alert("Erro, tente novamente mais tarde.");
        }
    }
    //window.location.reload();
    return {er}
}