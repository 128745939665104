import Modal from "react-modal";
import { useEffect, useMemo, useState } from "react";
import { LuCalendarDays } from "react-icons/lu";
import { IoMdCloseCircle } from "react-icons/io";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import DatePicker, { registerLocale } from "react-datepicker";

import PropTypes from "prop-types";
import { subDays } from "date-fns";
import { ptBR } from "date-fns/locale";

import { buildChartConfigs, transformDataForApexCharts } from "./helpers";

import {
  formatDateDDMMYYYY,
  formatDateYYYYMMDD,
  getFilterStartDate,
  parseFilterDateString,
} from "../helpers";

import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { chartBySymptomFilterOptions } from "./constants";

Modal.setAppElement("#root");

registerLocale("pt-BR", ptBR);

const DailySymptomsChartBySymptom = ({
  dailySymptomChartBySymptomData,
  filterDate,
  setFilterDate,
  setSymptomValueFilter,
  symptomValueFilter,
}) => {
  const today = new Date();
  const filterDateISO = parseFilterDateString(filterDate);

  const startDate = subDays(filterDateISO, 30);

  const { categories, series } = transformDataForApexCharts(
    dailySymptomChartBySymptomData
  );
  const options = buildChartConfigs(categories, symptomValueFilter);

  const [chartSize, setChartSize] = useState({
    chartWidth: window.innerWidth >= 1200 ? "100%" : "950px",
    chartHeight: 180,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    startDate,
    filterDateISO,
  ]);

  const datePickerRange = useMemo(() => {
    return [startDate, filterDateISO];
  }, [filterDateISO, startDate]);

  const datesDisplay = useMemo(() => {
    return [formatDateDDMMYYYY(startDate), formatDateDDMMYYYY(filterDate)];
  }, [filterDate, startDate]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const onSelectDate = () => {
    setFilterDate(formatDateYYYYMMDD(selectedDates[1]));
    setModalIsOpen(false);
  };

  const handleChange = (date) => {
    const endDate = date;
    const formattedStartDate = getFilterStartDate(date, 29);
    const filterEndDateISO = parseFilterDateString(formattedStartDate);

    setSelectedDates([filterEndDateISO, endDate]);
  };

  const handleSelectChange = (value) => {
    setSymptomValueFilter(value.value);
  };

  const renderSymtomButton = (symptom) => {
    return (
      <button
        className={`chart-filter-btn ${
          symptomValueFilter === symptom.value
            ? "chart-filter-btn-selected"
            : ""
        }`}
        onClick={() => handleSelectChange(symptom)}
      >
        <span
          className={`chart-filter-btn-label ${
            symptomValueFilter === symptom.value
              ? "chart-filter-btn-label-selected"
              : ""
          }`}
        >
          {symptom.label}
        </span>
      </button>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setChartSize({
        chartHeight: "100%",
        chartWidth: window.innerWidth >= 1200 ? "100%" : "950px",
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="daily-symptoms-chart-container">
      <div className="daily-symptoms-chart-header-container">
        <h2 className="daily-symptoms-chart-header-title">
          Histórico mensal da intensidade do sintoma
        </h2>

        <div className="chart-filter-container">
          <Select
            className="chart-filter-options"
            options={chartBySymptomFilterOptions}
            onChange={handleSelectChange}
            defaultValue={chartBySymptomFilterOptions[0]}
            isSearchable={false}
          />

          <div className="chart-filter-selector" onClick={openModal}>
            <p>
              {datesDisplay[0]} {""} - {""}
              {datesDisplay[1]}
            </p>
            <LuCalendarDays className="chart-filter-icon" />
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)", // Define o fundo escurecido
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "8px",
            },
          }}
          contentLabel="Select Date"
        >
          <div className="date-picker-modal-header">
            <p className="date-picker-modal-title">
              Histórico mensal de sintoma
            </p>
            <IoMdCloseCircle
              className="date-picker-modal-icon"
              color="#5c57f2"
              onClick={() => setModalIsOpen(false)}
            />
          </div>

          <DatePicker
            calendarClassName="custom-datepicker"
            selected={datePickerRange[1]}
            onChange={handleChange}
            startDate={datePickerRange[0]}
            endDate={datePickerRange[1]}
            locale="pt-BR"
            maxDate={today}
            selectsEnd
            inline
          />
          <button className="date-picker-modal-btn" onClick={onSelectDate}>
            Confirmar
          </button>
        </Modal>
      </div>

      <div className="chart-filter-btn-container">
        {chartBySymptomFilterOptions.map((symptom) =>
          renderSymtomButton(symptom)
        )}
      </div>

      <div className="daily-symptoms-chart-wrapper">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          width={chartSize.chartWidth}
          height={chartSize.chartHeight}
        />
      </div>
    </div>
  );
};

DailySymptomsChartBySymptom.propTypes = {
  dailySymptomChartBySymptomData: PropTypes.object.isRequired,
  setFilterDate: PropTypes.func.isRequired,
  setSymptomValueFilter: PropTypes.func.isRequired,
  filterDate: PropTypes.string.isRequired,
  symptomValueFilter: PropTypes.string.isRequired,
};

export default DailySymptomsChartBySymptom;
