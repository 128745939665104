import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function PrivateRoute({ Component }) {
   const [data, setData] = useState({ user: false });

   useEffect(async () => {
      var prevData;
      try {
         var user = await Auth.currentAuthenticatedUser();
         prevData = { ...data };
         prevData.user = user;
         setData({ ...prevData });
      } catch (e) {
         prevData = { ...data };
         prevData.user = "Unauthorized";
         setData({ ...prevData });
      }
   }, []);

   if (!data.user) {
      return (
         <LinearProgress
            color="primary"
            style={{ top: 0, width: "100%", height: 5 }}
         />
      );
   } else if (data.user === "Unauthorized") {
      return <Redirect to={{ pathname: "/login" }} />;
   } else if (data.user != "Unauthorized") {
      return <Component />;
   }
   return (
      <LinearProgress
         color="primary"
         style={{ top: 0, width: "100%", height: 5 }}
      />
   );
}
