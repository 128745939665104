import { Button, useMediaQuery } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

const CookieConsent = () => {
  const [consent, setConsent] = useState();
  const isMobile = useMediaQuery("(max-width:968px)");

  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent");
    if (consentGiven) {
      setConsent(true);
      document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setConsent(true);
    document.body.classList.remove("no-scroll");
  };

  const handleNoAccept = useCallback(() => {
    setConsent(false);
  }, [setConsent]);

  if (consent) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.6)",
        color: "#fff",
        height: "100%",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        zIndex: "1000",
      }}
    >
      <div
        style={{
          width: isMobile ? "90%" : "50%",
          paddingInline: "16px",
          paddingBlock: "32px",
          backgroundColor: "#cac9ff",
          borderRadius: "16px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {consent === false ? (
          <>
            <p
              style={{
                fontSize: "1.4rem",
                textAlign: "center",
                cursor: "default",
                color: "#2d2d2d",
              }}
            >
              É preciso aceitar os termos para continuar navegando. Tente
              novamente.
            </p>
            <Button
              onClick={() => setConsent(undefined)}
              variant={"contained"}
              color="primary"
            >
              Voltar
            </Button>
          </>
        ) : (
          <>
            <p
              style={{
                fontSize: "1.4rem",
                textAlign: "center",
                cursor: "default",
                color: "#2d2d2d",
              }}
            >
              Usamos cookies para personalizar e melhorar sua experiência no
              nosso site. Acesse a nossa{" "}
              <Link
                target="_blank"
                to="privacypolicy"
                style={{
                  cursor: "pointer",
                  color: "#5c57f2",
                  textDecoration: "none",
                }}
              >
                {" "}
                Políticas de Privacidade
              </Link>{" "}
              para saber mais ou gerenciar suas preferências pessoais nos nossos{" "}
              <Link
                target="_blank"
                to="termsofuse"
                style={{
                  cursor: "pointer",
                  color: "#5c57f2",
                  textDecoration: "none",
                }}
              >
                {" "}
                Termos de Serviço
              </Link>
              .
            </p>

            <div style={{ display: "flex", gap: "16px" }}>
              <Button
                onClick={handleAccept}
                variant={"contained"}
                color="primary"
              >
                Concordo
              </Button>
              <Button
                onClick={handleNoAccept}
                variant={"contained"}
                color="primaryB"
              >
                Não concordo
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CookieConsent;
