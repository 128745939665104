import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Typography, TextField, FormControlLabel, Link, Grid, InputLabel, Paper, Card } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";
import {API, Auth} from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
    background: {
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#3434344d',
        zIndex: 10000,
        height: '100vh',
        width: '100%',
    },
    form: {
        width: 400, 
        height: 220, 
        backgroundColor: 'white', 
        borderRadius: 10,
        margin: '0 auto', 
        marginTop: '10%',
        padding: 25,
        position: 'relative'
    },
    submit: {
        width: '100%',
        marginTop: 20
    },
    title: {
        width: '100%',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: 1,
        fontWeight: 500,
        fontSize: 14,
        color: '#979797'
    },
    close: {
        position: 'absolute', 
        top: 6, 
        right: 6, 
        fontSize: 14, 
        color: '#a1a1a1',
        cursor: 'pointer'
    }
  }));

export default function LinkPatient({hide, professional_id, dispatch, setPatients, patients}) {

    async function submit() {
        setLoading(true)
        
        let myInit = { 
            headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
            body: {
                patient_email : patient_email,
                professional_id : professional_id
            }
        }

        API.post("webEndpoints", '/patient/link', myInit).then(async response => {
          dispatch(setPatients([...patients, response]))
          setLoading(false)
          hide()
        }).catch(error => {
          setLoading(false)
          alert("Erro")
        });
    }

    const classes = useStyles();
    const [patient_email, setPatientEmail] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    
    return(
        <Grid container className={classes.background}>
          <Grid item className={classes.form}>
            <Close className={classes.close} onClick={() => hide()}/>
            <Typography className={classes.title}>
                Novo Paciente
            </Typography>
            <form noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="patient_email"
                label="Email do Paciente"
                name="patient_email"
                autoFocus
                color="primary"
                onChange={(e) => setPatientEmail(e.target.value)}
                />
              <Typography color="error" className={classes.error}>
                {/* {error} */}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => submit()}
                disabled={loading}
              >
                Enviar
              </Button>
          </form>
          </Grid>
        </Grid>
    )
}