import axios from "axios";

export const requestUserData = (userEmail) => {
  return axios({
    method: "GET",
    baseURL: `${process.env.REACT_APP_GET_PATIENT_DATA_URL}/${userEmail}`,
  });
};

export const requestDailySymptoms = ({ userCPF, startDate, endDate }) => {
  return axios({
    method: "GET",
    baseURL: `${process.env.REACT_APP_GET_PATIENT_DIARY_REPORT_URL}?cpf=${userCPF}&startDate=${startDate}&endDate=${endDate}`,
  });
};

export const requestDailySymptomsBySymptom = ({
  userCPF,
  startDate,
  endDate,
  symptom,
}) => {
  return axios({
    method: "GET",
    baseURL: `${process.env.REACT_APP_GET_PATIENT_DIARY_REPORT_URL}?cpf=${userCPF}&symptom=${symptom}&startDate=${startDate}&endDate=${endDate}`,
  });
};

export const requestACQhistory = ({ userCPF, startDate, endDate }) => {
  return axios({
    method: "GET",
    baseURL: `${process.env.REACT_APP_GET_PATIENT_ACQ_REPORT_URL}?cpf=${userCPF}&startDate=${startDate}&endDate=${endDate}`,
  });
};

export const requestMedicationChartData = ({
  patientId,
  medicationId,
  startDate,
  endDate,
}) => {
  const medicationParam = medicationId ? `&medication=${medicationId}` : "";
  const startDateParam = startDate ? `&startDate=${startDate}` : "";
  const endDateParam = endDate ? `&endDate=${endDate}` : "";
  return axios({
    method: "GET",
    baseURL: `${process.env.REACT_APP_GET_PATIENT_MEDICATION_REPORT_URL}?patient=${patientId}${medicationParam}${startDateParam}${endDateParam}`,
  });
};

export const requestAccessWithToken = (token) => {
  return axios({
    method: "GET",
    baseURL: `${process.env.REACT_APP_GET_PATIENT_BY_TOKEN_URL}?token=${token}`,
  });
};
