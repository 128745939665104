import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Provider } from "react-redux";
import { createStore } from "redux";
import Amplify from "aws-amplify";
import React from "react";

import reducers from "./reducers";
import Routes from "./routes";
import { DrawerProvider } from "./components/menu";

import "./App.css";
import CookieConsent from "./components/cookiesConsent";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

let store = createStore(persistedReducer);
let persistor = persistStore(store);

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "webEndpoints",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
      },
    ],
  },
});

const theme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#d4cefb",
          "&:hover": {
            backgroundColor: "#d4cefb",
          },
        },
      },
    },
    MuiDataGrid: {
      root: {
        border: "None",
      },
    },
  },
  palette: {
    primary: {
      main: "#5c57f2",
      light: "#FFFFFF",
    },
    primaryB: {
      main: "#ff0000",
      light: "#FFFFFF",
    },
    secondary: {
      main: "#9f88f2",
      contrastText: "#000004",
    },
    auxiliar: {
      main: "#95d9d8",
    },
    error: {
      main: "#cc6363",
    },
    warning: {
      main: "#f9f224",
    },
    success: {
      main: "#60ff8a",
    },
    white: {
      main: "#ffffff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <DrawerProvider>
            <Routes />
          </DrawerProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
