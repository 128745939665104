import React, { useContext, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
   Drawer,
   CssBaseline,
   List,
   Typography,
   Divider,
   ListItem,
   ListItemIcon,
   ListItemText,
   useMediaQuery,
} from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import ContactsIcon from "@material-ui/icons/Contacts";
import MenuBook from "@material-ui/icons/MenuBook";
import logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { removeUser, removePatients } from "../actions/index";
import logout from "../controllers/logoutController";
import { createContext } from "react";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
   },
   drawer: {
      width: drawerWidth,
      flexShrink: 0,
   },
   drawerPaper: {
      width: drawerWidth,
   },
   logo: {
      height: 25,
      width: 25,
      marginLeft: 8,
   },
   name: {
      fontSize: 23,
      fontWeight: 100,
      letterSpacing: 1,
      marginLeft: 10,
   },
   logoBox: {
      display: "flex",
      flexDirection: "row",
      margin: 10,
      alignItems: "center",
   },
   config: {
      fontSize: 12,
      color: "#b9b9b9",
      marginTop: 10,
      marginLeft: 15,
      fontWeight: 100,
      letterSpacing: 1,
   },
   item: {
      fontSize: 15,
      letterSpacing: 1,
      fontWeight: 500,
   },
   selectedItem: {
      fontSize: 15,
      letterSpacing: 1,
      fontWeight: 600,
      color: "#726df4",
   },
   itemButton: {
      width: "95%",
      margin: "0 auto",
      borderRadius: 5,
      height: 40,
      marginBottom: 5,
   },
}));

export const DrawerContext = createContext();

export const DrawerProvider = ({ children }) => {
   const [isOpen, setIsOpen] = useState(true);
 
   const toggleDrawer = () => {
     setIsOpen(!isOpen);
   };
 
   return (
     <DrawerContext.Provider value={{ isOpen, toggleDrawer }}>
       {children}
     </DrawerContext.Provider>
   );
 };

export default function Menu({ history }) {
   const classes = useStyles();
   const dispatch = useDispatch();
   const user = useSelector((state) => state.currentUser);
   const { isOpen, toggleDrawer } = useContext(DrawerContext);
   const isMobile = useMediaQuery('(max-width:968px)');

   useEffect(() => {
      const handleResize = () => {
         if ((isMobile && isOpen) || (!isMobile && !isOpen)) {
           toggleDrawer()
         }
      };

      handleResize()
  
      window.addEventListener('resize', handleResize);
  
      // Remover o listener do evento de resize quando o componente for desmontado
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [isMobile]); 

   async function signOut() {
      dispatch(removePatients());
      dispatch(removeUser());
      logout(history);
   }

   if(!user.email) {
      return null
   }

   return (
      <div className={classes.root}>
         <CssBaseline />
         <Drawer
            open={isOpen}
            variant="persistent"
            classes={{
               paper: classes.drawerPaper,
            }}
            anchor="left"
         >
            <span className={classes.logoBox}>
               <img src={logo} className={classes.logo} />
               <Typography color="primary" className={classes.name}>
                  inspir<b>ar</b>
               </Typography>
            </span>
            <Divider />
            <List>
               <ListItem
                  classes={{ root: classes.itemButton }}
                  button
                  selected={history.location.pathname == "/patients"}
                  onClick={() => history.push("/patients")}
                  key="patients"
               >
                  <ListItemIcon>
                     <ContactsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                     primary="Pacientes"
                     classes={
                        history.location.pathname == "/patients"
                           ? { primary: classes.selectedItem }
                           : { primary: classes.item }
                     }
                  />
               </ListItem>

               {user && user.type == "admin" ? (
                  <ListItem
                     classes={{ root: classes.itemButton }}
                     button
                     selected={history.location.pathname == "/professionals"}
                     onClick={() => history.push("/professionals")}
                     key="professionals"
                  >
                     <ListItemIcon>
                        <DonutLargeIcon fontSize="small" />
                     </ListItemIcon>
                     <ListItemText
                        primary="Profissionais"
                        classes={
                           history.location.pathname == "/professionals"
                              ? { primary: classes.selectedItem }
                              : { primary: classes.item }
                        }
                     />
                  </ListItem>
               ) : (
                  ""
               )}
               {user && user.type == "admin" ? (
                  <ListItem
                     classes={{ root: classes.itemButton }}
                     button
                     selected={history.location.pathname == "/materials"}
                     onClick={() => history.push("/materials")}
                     key="materials"
                  >
                     <ListItemIcon>
                        <MenuBook fontSize="small" />
                     </ListItemIcon>
                     <ListItemText
                        primary="Materiais"
                        classes={
                           history.location.pathname == "/materials"
                              ? { primary: classes.selectedItem }
                              : { primary: classes.item }
                        }
                     />
                  </ListItem>
               ) : (
                  ""
               )}
            </List>
            <Divider />
            <Typography className={classes.config}>Configurações</Typography>
            <List>
               <ListItem
                  classes={{ root: classes.itemButton }}
                  button
                  selected={history.location.pathname == "/"}
                  onClick={() => signOut()}
                  key="Sair"
               >
                  <ListItemIcon>
                     <ExitToApp fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                     primary="Sair"
                     classes={{ primary: classes.item }}
                  />
               </ListItem>
            </List>
         </Drawer>
      </div>
   );
}
