import PropTypes from "prop-types";
import "../DailySymptomsChart/styles.css";
import "../styles.css";
import "./index.css";
import { format, subDays } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { TakenSVG } from "./icons/taken";
import { NotTakenSVG } from "./icons/not-taken";
import { NoDoseSVG } from "./icons/no-dose";
import { NoRegisterSVG } from "./icons/no-register";
import { Grid, useMediaQuery } from "@material-ui/core";
import { LuCalendarDays } from "react-icons/lu";
import Modal from "react-modal";
import { IoMdCloseCircle } from "react-icons/io";
import {
  formatDateDDMMYYYY,
  formatDateYYYYMMDD,
  getFilterStartDate,
  parseFilterDateString,
} from "../helpers";
import Select from "react-select";
import { BiLoader } from "react-icons/bi";
import { ptBR } from "date-fns/locale";
import DatePicker, { registerLocale } from "react-datepicker";

const medicationStatus = {
  TAKEN: <TakenSVG />,
  ["NOT-TAKEN"]: <NotTakenSVG />,
  UNKNOWN: <NoRegisterSVG />,
};

const periods = ["matutino", "vespertino", "noturno"];

Modal.setAppElement("#root");

registerLocale("pt-BR", ptBR);

const MedicationChart = ({
  data,
  filterDate,
  setFilterDate,
  setValueFilter,
  isLoading,
}) => {
  const isMobile = useMediaQuery("(max-width:968px)");
  const today = new Date();
  const filterDateISO = parseFilterDateString(filterDate);

  const startDate = subDays(filterDateISO, 30);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    startDate,
    filterDateISO,
  ]);

  const datePickerRange = useMemo(() => {
    return [startDate, filterDateISO];
  }, [filterDateISO, startDate]);

  const datesDisplay = useMemo(() => {
    return [formatDateDDMMYYYY(startDate), formatDateDDMMYYYY(filterDate)];
  }, [filterDate, startDate]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const [nMaxManha, nMaxTarde, nMaxNoite] = useMemo(() => {
    return periods.map((period) => {
      return (
        data?.datasets?.reduce((max, entry) => {
          const noturnoCount = entry.periods[period].length;
          return Math.max(max, noturnoCount);
        }, 0) || 1
      );
    });
  }, [data]);

  const handleChange = (date) => {
    const endDate = date;
    const formattedStartDate = getFilterStartDate(date, 29);
    const filterEndDateISO = parseFilterDateString(formattedStartDate);

    setSelectedDates([filterEndDateISO, endDate]);
  };

  const onSelectDate = () => {
    setFilterDate(formatDateYYYYMMDD(selectedDates[1]));
    setModalIsOpen(false);
  };

  const fillEmptyblocks = useCallback((periodLength = 0, nMaxPeriod) => {
    return Array.from(Array(nMaxPeriod - periodLength).fill(<NoDoseSVG />));
  }, []);

  const renderPeriod = useCallback(
    (date, period, nMaxPeriod) => {
      const dateDataset = data?.datasets.find(
        (dataset) => dataset.date === date
      );

      if (!dateDataset || dateDataset.periods[period].length === 0) {
        return (
          <td>
            <div>
              {fillEmptyblocks(dateDataset?.periods[period].length, nMaxPeriod)}
            </div>
          </td>
        );
      }

      return (
        <td>
          <div>
            {fillEmptyblocks(dateDataset?.periods[period].length, nMaxPeriod)}
            {dateDataset.periods[period].map(
              (item, index) => medicationStatus[item.status]
            )}
          </div>
        </td>
      );
    },
    [data, fillEmptyblocks]
  );

  if (isLoading) {
    return (
      <div className="daily-symptoms-chart-container">
        <div
          className="daily-symptoms-chart-wrapper"
          style={{ padding: 0, display: "flex", justifyContent: "center" }}
        >
          <BiLoader size={50} />
        </div>
      </div>
    );
  }

  return (
    <div className="daily-symptoms-chart-container">
      <div className="daily-symptoms-chart-header-container">
        <h2 className="daily-symptoms-chart-header-title">
          Histórico de medicação
        </h2>

        <div className="chart-filter-container">
          {data.medications.length > 0 && (
            <Select
              className="medication-chart-filter-options"
              options={data?.medications?.map((medication) => {
                return {
                  label: medication.name,
                  value: medication.medication_id,
                };
              })}
              onChange={(option) => setValueFilter(option.value)}
              defaultValue={() => {
                const medication = data?.medications?.find(
                  (medication) => medication.active
                );
                return {
                  label: medication.name,
                  value: medication.medication_id,
                };
              }}
              isSearchable={false}
            />
          )}

          <div className="chart-filter-selector" onClick={openModal}>
            <p>
              {datesDisplay[0]} {""} - {""}
              {datesDisplay[1]}
            </p>
            <LuCalendarDays className="chart-filter-icon" />
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "8px",
            },
          }}
          contentLabel="Select Date"
        >
          <div className="date-picker-modal-header">
            <p className="date-picker-modal-title">Histórico de medicação</p>
            <IoMdCloseCircle
              className="date-picker-modal-icon"
              color="#5c57f2"
              onClick={() => setModalIsOpen(false)}
            />
          </div>

          <DatePicker
            calendarClassName="custom-datepicker"
            selected={datePickerRange[1]}
            onChange={handleChange}
            startDate={datePickerRange[0]}
            endDate={datePickerRange[1]}
            locale="pt-BR"
            maxDate={today}
            selectsEnd
            inline
          />
          <button className="date-picker-modal-btn" onClick={onSelectDate}>
            Confirmar
          </button>
        </Modal>
      </div>

      <div className="daily-symptoms-chart-wrapper">
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th></th>
                {data?.labels?.map((date) => (
                  <th>{new Date(date).getUTCDate()}</th>
                ))}
              </tr>
              <tr>
                <th></th>
                <th style={{ textTransform: "Capitalize" }}>
                  {data?.labels &&
                    format(new Date(data?.labels?.[0]), "MMM", {
                      locale: ptBR,
                    })}
                </th>
                <th
                  colSpan={data?.labels?.length}
                  style={{ textAlign: "right", textTransform: "Capitalize" }}
                >
                  {data?.labels &&
                    format(
                      new Date(data?.labels?.[data?.labels.length - 1]),
                      "MMM",
                      { locale: ptBR }
                    )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>M</td>
                {data?.labels?.map((date) => {
                  return renderPeriod(date, "matutino", nMaxManha);
                })}
              </tr>
              <tr>
                <td>T</td>
                {data?.labels?.map((date) => {
                  return renderPeriod(date, "vespertino", nMaxTarde);
                })}
              </tr>
              <tr>
                <td>N</td>
                {data?.labels?.map((date) => {
                  return renderPeriod(date, "noturno", nMaxNoite);
                })}
              </tr>
            </tbody>
          </table>
        </div>

        <Grid
          container
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
          justifyContent={isMobile ? "flex-start" : "space-between"}
          className="legend"
          style={{
            color: "#4B4A50",
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "roboto",
          }}
        >
          <Grid
            item
            className="legend-item"
            style={{ marginLeft: isMobile ? 0 : "1.5rem" }}
          >
            <TakenSVG />
            Tomado
          </Grid>
          <Grid item className="legend-item">
            <NotTakenSVG />
            Não tomado
          </Grid>
          <Grid item className="legend-item">
            <NoRegisterSVG />
            Sem registro
          </Grid>
          <Grid item className="legend-item">
            <NoDoseSVG />
            Sem dose prevista
          </Grid>
        </Grid>

        <div className="chart-y-legend-container">
          <div className="chart-y-legend-label-container">
            <div>
              <span>M</span>
              <span>=</span>
            </div>
            <span>Manhã</span>
          </div>
          <div className="chart-y-legend-label-container">
            <div>
              <span>T</span>
              <span>=</span>
            </div>
            <span>Tarde</span>
          </div>
          <div className="chart-y-legend-label-container">
            <div>
              <span>N</span>
              <span>=</span>
            </div>
            <span>Noite</span>
          </div>
        </div>
      </div>
    </div>
  );
};

MedicationChart.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    medications: PropTypes.arrayOf(
      PropTypes.shape({
        medication_id: PropTypes.string,
        name: PropTypes.string,
        active: PropTypes.bool,
      })
    ),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        periods: PropTypes.shape({
          matutino: PropTypes.arrayOf(
            PropTypes.shape({
              hour: PropTypes.string,
              status: PropTypes.oneOf(["TAKEN", "NOT-TAKEN", "UNKNOWN"]),
            })
          ),
          vespertino: PropTypes.arrayOf(
            PropTypes.shape({
              hour: PropTypes.string,
              status: PropTypes.oneOf(["TAKEN", "NOT-TAKEN", "UNKNOWN"]),
            })
          ),
          noturno: PropTypes.arrayOf(
            PropTypes.shape({
              hour: PropTypes.string,
              status: PropTypes.oneOf(["TAKEN", "NOT-TAKEN", "UNKNOWN"]),
            })
          ),
        }),
      })
    ),
  }),
};

export default MedicationChart;
