import Amplify, { Auth, API } from "aws-amplify";

export default async function login(email, password) {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      mandatorySignIn: true,
    },
    API: {
      endpoints: [
        {
          name: "webEndpoints",
          endpoint: process.env.REACT_APP_API_ENDPOINT,
        },
      ],
    },
  });

  var user, data, msg, err, err_msg;

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  err_msg = "";
  if (email.length < 5 || !emailRegex.test(email)) {
    if (email == "") {
      err_msg = "Por favor coloque seu e-mail";
    } else {
      err_msg = "Email inválido";
    }
  } else if (password == "") {
    err_msg = "Por favor coloque sua senha";
  }

  if (err_msg != "") return { err: true, msg: "", err_msg };

  try {
    const response = await Auth.signIn(email, password);
    if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
      return {
        err: true,
        msg: `Necessário mudança de senha. Acesse "Esqueci minha senha" e tente novamente`,
        err_msg: "",
      };
    }
  } catch (err) {
    if (err.code === "UserNotFoundException") {
      msg = "Usuário ou senha inválidos";
    } else if (err.code === "NotAuthorizedException") {
      msg = "Usuário ou senha inválidos"; // Senha inválido ou usuário desabilitado
    } else if (err.code === "UserNotConfirmedException") {
      msg = "Usuário não confirmado, verifique seu email";
    } else if (err.code === "PasswordResetRequiredException") {
      msg = "Necessário mudança de senha, verifique seu email";
    } else {
      msg = "Erro: tente novamente mais tarde";
    }
    return { err: true, msg, err_msg: "" };
  }

  let myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  await API.get("webEndpoints", "/professionals/" + email, myInit)
    .then(async (response) => {
      // FALTA IMPLEMENTAR: Atualizar a store do redux com os pacientes e redirecionar para página correta + tratar erro 404
      data = response;
      err = false;
    })
    .catch((error) => {
      msg = "Erro interno";
      err = true;
    });

  if (err) return { err: true, msg, err_msg: "" };

  return { err: false, msg: "", data: data };
}
