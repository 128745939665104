import { combineReducers } from 'redux';

import viewReducer from './lib/viewReducer'
import userReducer from './lib/userReducer'
import patientReducer from './lib/patientReducer'
import currentPatientReducer from './lib/currentPatientReducer'
import teamReducer from './lib/teamReducer'
import {SET_TOKEN} from "../actions/lib/types";

const appReducer = combineReducers({
  currentView: viewReducer,
  currentUser: userReducer,
  patients: patientReducer,
  team: teamReducer,
  currentPatient: currentPatientReducer,
  token: (state = { token: undefined, email: undefined}, action) => {
    switch (action.type) {
      case SET_TOKEN:
        return action.payload;
      default:
        return state;
    }
  }
})

const rootReducer = (state = {}, action) => {
  return appReducer(state, action);
}

export default rootReducer;
