import {Button, Typography, TextField, Grid, useMediaQuery } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux"; 
import React, { useState } from 'react';

import Menu from '../../components/menu'  
import Header from '../../components/header'  
import {validatePersonalInfo, submit} from '../../controllers/newProfessionalController'


const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      height: "100vh",
      width: (props) => props.isMobile ? `100%` : `calc(100% - ${250}px)`,
      marginLeft: (props) => props.isMobile ? 0 : 250,
      backgroundColor: '#f4f4f4'
    },
    loading: {
      width: '100%',
      position: 'fixed',
      top: 0,
      height: 7
    },
    content: {
      width: "100%",
      padding: 20
    },
    form: {
      backgroundColor: 'white',
      borderRadius: 10,
      padding: "32px 16px",
    },
    stepper: {
      marginTop: 100,
    },
    title: {
      letterSpacing: 1,
      fontSize: 20,
      fontWeight: 600,
      marginLeft: 15
    },
    formTitle: {
      letterSpacing: 1,
      fontSize: 20,
      fontWeight: 400,
      marginTop: 20
    },
    input: {
      height: 50,
      color: '#5c57f2',
    },
    buttons: {
      marginTop: 30
    },
    button: {
      width: 150,
      height: 50,
      textTransform: 'capitalize'
    },
    buttonFinal: {
      width: 200,
      height: 50,
      textTransform: 'capitalize'
    },
    questions: {
      fontSize: 16,
      fontWeight: 550,
      color: '#04003c',
      marginTop: 30,
      marginLeft: 10,
      marginBottom: 20
    },
    question: {
      fontSize: 14,
      fontWeight: 400,
      color: '#212054',
    },
    success: {
      marginTop: 120,
      backgroundColor: '#5c57f2',
      borderRadius: 10,
      height: 500,
      margin: '0 auto',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    },
    successText: {
      color: '#eae9fd',
      fontSize: 24,
      fontWeight: 200,
      letterSpacing: 2,
      textAlign: 'center',
      marginTop: 20
    }
    
  }));

export default function NewProfessional() {
  const isMobile = useMediaQuery('(max-width:968px)');

  const classes = useStyles({ isMobile });
  const history = useHistory();
  const currentUser = useSelector(state => state.currentUser);
  
  const [loading, setLoading] = useState(false)

  const [personalInfo, setPersonalInfo] = useState({
    full_name: "",
    cpf: "",
    email: "",
    personal_phone: "",
    birth_date: ""
  })

  const initError = {
    full_name: false,
    cpf: false,
    email: false,
    personal_phone: false,
    birth_date: false
  }

  const [personalInfoErrors, setPersonalInfoErrors] = useState(initError)

  async function createProfessional() {
    setLoading(true);
    const err = await validatePersonalInfo(personalInfo, personalInfoErrors);
    if (err) {
      setLoading(false);
      return setPersonalInfoErrors(err)
    } 
    const res = await submit(personalInfo, currentUser)
    if (res.err == false) {
      setLoading(false);
      return history.push('newprofessional#success')
    }
    alert(res.msg)
    setLoading(false);
  }

  const telefoneAutoCompleteOnPress = (ev) => {
    //console.log(`Pressed keyCode ${ev.target.value}`);
    if (ev.target.value.length === 2) {
      ev.target.value += '-'
    }
  };

  function cpfAutoCompleteOnPress(ev){
    //console.log(`Pressed keyCode ${ev.key}`);
    if (ev.target.value.length === 3) {
      ev.target.value += '.'
    }
    if (ev.target.value.length === 7) {
      ev.target.value += '.'
    }
    if (ev.target.value.length === 11) {
      ev.target.value += '-'
    }
  }

  function onlyDigits(ev){
    //console.log(`Pressed keyCode ${ev.key}`);
    const modifier_keys = ['Alt', 'AltGraph', 'Control', 'Meta', 'Shift', 'CapsLock', 'NumLock', 'ScrollLock'];
    for(const value of modifier_keys){
      if(ev.key==value) return  // if it is modifier key
    }
    
    if(!isFinite(ev.key) || ev.key==' ') { // if key is digit
      ev.target.value = ev.target.value.substring(0,ev.target.value.length-1)
    }
  }

  function renderPersonal() {
  
    return(
      <Grid item xs={12} md={10} lg={8} className={classes.form}>
        <Typography color="primary" className={classes.formTitle}>
          Cadastro
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="full_name"
              label="Nome completo"
              id="full_name"
              color="primary"
              InputProps={{className: classes.input}}
              value={personalInfo.full_name}
              onChange={(e) => {
                const prevInfo = {...personalInfo}
                prevInfo[e.target.id] = e.target.value
                setPersonalInfo(prevInfo)
              }}
              size="small"
              error={!!personalInfoErrors.full_name}
              helperText={personalInfoErrors.full_name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              type="date"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="birth_date"
              label="Data de nascimento"
              id="birth_date"
              color="primary"
              InputProps={{className: classes.input}}
              value={personalInfo.birth_date}
              onChange={(e) => {
                const prevInfo = {...personalInfo}
                prevInfo[e.target.id] = e.target.value
                setPersonalInfo(prevInfo)
              }}
              size="small"
              error={!!personalInfoErrors.birth_date}
              helperText={personalInfoErrors.birth_date}
              //placeholder="dd/mm/yyyy"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="cpf"
              label="CPF"
              id="cpf"
              color="primary"
              InputProps={{className: classes.input}}
              value={personalInfo.cpf}
              onChange={(e) => {
                const prevInfo = {...personalInfo}
                prevInfo[e.target.id] = e.target.value
                setPersonalInfo(prevInfo)
              }}
              size="small"
              error={!!personalInfoErrors.cpf}
              helperText={personalInfoErrors.cpf}
              inputProps={{ maxLength: 14 }}
              onKeyPress={ (ev) => cpfAutoCompleteOnPress(ev)}
              onKeyUp={(ev) => onlyDigits(ev)}

            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              type="text"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="personal_phone"
              label="Telefone"
              id="personal_phone"
              color="primary"
              InputProps={{className: classes.input}}
              value={personalInfo.personal_phone}
              onChange={(e) => {
                const prevInfo = {...personalInfo}
                prevInfo[e.target.id] = e.target.value
                setPersonalInfo(prevInfo)
              }}
              size="small"
              error={!!personalInfoErrors.personal_phone}
              helperText={personalInfoErrors.personal_phone}
              inputProps={{ maxLength: 12 }}
              onKeyPress={ telefoneAutoCompleteOnPress}
              onKeyUp={(ev) => onlyDigits(ev)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label="E-mail"
              id="email"
              color="primary"
              InputProps={{className: classes.input}}
              value={personalInfo.email}
              onChange={(e) => {
                const prevInfo = {...personalInfo}
                prevInfo[e.target.id] = e.target.value
                setPersonalInfo(prevInfo)
              }}
              size="small"
              error={!!personalInfoErrors.email}
              helperText={personalInfoErrors.email}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" className={classes.buttons}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => history.push('/professionals')}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => createProfessional()}
            disabled={loading}
          >
            Enviar
          </Button>
        </Grid>
      </Grid> 
    );
  }

  
  function renderSuccess() {
    return(
      <Grid item xs={8} md={8} spacing={8} className={classes.success} justifyContent="center" alignContent="center">
        <CheckCircleOutlineIcon style={{color:'#eae9fd', width: 150, height:150}} />
        <Typography className={classes.successText}>
          Cadastro realizado<br></br>com sucesso!
        </Typography>
      </Grid> 
    );
  }
  
  return (
      <Grid container className={classes.root} justifyContent="center" alignContent="flex-start">
        {loading? <LinearProgress className={classes.loading} color="primary" /> : ""}
        <Header label="Novo Profissional" />
        <Menu history={history}/>
        <Grid container spacing={{ xs: 2, md: 4 }} className={classes.content}>
          {history.location.hash === "#personal" ? renderPersonal() : ""}
          {history.location.hash === "#success" ? renderSuccess() : ""}
        </Grid>
      </Grid>
    );
  }
