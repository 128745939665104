import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import {Redirect, Route} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useSelector} from "react-redux";
import {requestAccessWithToken} from "../services/userData";

export default function PrivateRouteToken({ Component, ...rest }) {
    const [data, setData] = useState({ user: false });
    const token = useSelector((state) => state.token);
    const currentUser = useSelector((state) => state.currentUser);


    useEffect(async () => {
        let prevData;
        try {
            if(!currentUser?.email && token) {
                const resp = await requestAccessWithToken(token.token);
                if(resp.data.data.email !== token?.email) {
                    prevData = { ...data };
                    prevData.user = "Unauthorized";
                    setData({ ...prevData });
                }
                setData({ user: "anonimo" });
            } else {
                var user = await Auth.currentAuthenticatedUser();
                prevData = { ...data };
                prevData.user = user;
                setData({ ...prevData });
            }

        } catch (e) {
            prevData = { ...data };
            prevData.user = "Unauthorized";
            setData({ ...prevData });
        }
    }, []);

    if (!data.user) {
        return (
            <LinearProgress
                color="primary"
                style={{ top: 0, width: "100%", height: 5 }}
            />
        );
    } else if (data.user === "Unauthorized") {
        return <Redirect to={{ pathname: "/login" }} />;
    } else if (data.user != "Unauthorized") {
        return <Route
            {...rest}
            render={props => <Component {...props} />
            }
        />
    }
    return (
        <LinearProgress
            color="primary"
            style={{ top: 0, width: "100%", height: 5 }}
        />
    );
}
