import React, { useState } from "react";
import Amplify, { API } from "aws-amplify";
import { Button } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { resendPatConfirmationCode } from "../controllers/newPatientController";
import axios from "axios";

export function AlertDialog({ email_paciente }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = (e) => {
    e.stopPropagation(); // don't select this row after clicking
    axios({
      method: "POST",
      baseURL: `${process.env.REACT_APP_UNLINK_PROFESSIONAL_PATIENT}/${email_paciente}`,
    })
      .then((response) => {
        const data = response.data;
        if (data["message"]) {
          window.location.reload();
        } else {
          return alert("incapaz de desengatar o paciente");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <Button onClick={handleClickOpen} variant="contained" color="primary">
        Desvincular paciente
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {"Tem certeza de que deseja desvincular o paciente?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            A conta do paciente <b>{email_paciente}</b> será desvinculada. Esta
            ação pode ser revertida se o profissional vincular novamente o
            paciente de sua conta.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClick}>
            sim
          </Button>
          <Button variant="contained" color="#808080" onClick={handleClose}>
            não
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function AlertDialogResendCode({ email_paciente }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    resendPatConfirmationCode(email_paciente);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} variant="contained" color="primary">
        Reenviar código
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {"Tem certeza de que deseja reenviar o código de verificação?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            O código de verificação será encaminhado para{" "}
            <b>{email_paciente}</b>. Esta ação só pode ser feita se a conta
            ainda não tiver sido verificada.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose();
              onClick();
            }}
          >
            sim
          </Button>
          <Button variant="contained" color="#808080" onClick={handleClose}>
            não
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function AlertDialogDeleteUser({ id_paciente, email_paciente }) {
  const [open, setOpen] = useState(false);
  const [double, setDouble] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    fetch(
      "https://u7hhg3hi9b.execute-api.us-east-1.amazonaws.com/" + id_paciente
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["message"]) {
          window.location.reload();
          return 0;
        } else {
          return alert("Erro. Incapaz de remover o paciente");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <Button onClick={handleClickOpen} variant="contained" color="">
        Remover
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {"Tem certeza de que deseja excluir a conta do paciente?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Todos os dados do usuário <b>{email_paciente}</b> serão excluídos.
            Esta ação não pode ser revertida.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={double}
            onClick={() => {
              setDouble(true);
              onClick();
            }}
          >
            sim
          </Button>
          <Button variant="contained" color="#808080" onClick={handleClose}>
            não
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function AlertDialogDeleteProfessional({
  id_professional,
  name_professional,
}) {
  async function deleteProfessional(id) {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        mandatorySignIn: true,
      },
      API: {
        endpoints: [
          {
            name: "webEndpoints",
            endpoint: process.env.REACT_APP_API_ENDPOINT,
          },
        ],
      },
    });

    const myInit = {
      body: {
        professional_id: id,
      },
    };

    await API.post("webEndpoints", "/professionals/delete", myInit)
      .then(async (response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
      });
  }

  const [open, setOpen] = useState(false);
  const [double, setDouble] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    deleteProfessional(id_professional);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <DeleteForeverIcon
          style={{
            cursor: "pointer",
            color: "#5c57f2",
            margin: 5,
            textAlign: "center",
          }}
        />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {"Tem certeza de que deseja excluir a conta do profissional?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Todos os dados do usuário <b>{name_professional}</b> serão
            excluídos. Esta ação não pode ser revertida.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={double}
            onClick={() => {
              setDouble(true);
              onClick();
            }}
          >
            sim
          </Button>
          <Button variant="contained" color="#808080" onClick={handleClose}>
            não
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
