import {
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Link,
  Grid,
  InputLabel,
  useMediaQuery,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useState } from "react";

import login from "../../controllers/loginController";
import { setPatients, setUser } from "../../actions/index";
import logo from "../../assets/logo.png";
import lateral from "../../assets/lateral.png";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CookieConsent from "../../components/cookiesConsent";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  lateral: {
    backgroundImage: `url(${lateral})`,
    height: "100vh",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: 30,
    paddingRight: ({ isMobile }) => (isMobile ? 30 : 100),
    paddingLeft: ({ isMobile }) => (isMobile ? 30 : 150),
  },
  logo: {
    height: 80,
    width: 80,
  },
  name: {
    fontSize: 70,
    marginLeft: 10,
    fontWeight: 300,
    letterSpacing: 1,
  },
  submit: {
    textTransform: "unset",
    width: "100%",
    marginTop: 20,
    height: 45,
  },
  loading: {
    width: "100%",
    position: "fixed",
    top: 0,
    height: 7,
  },
  error: {
    fontSize: 12,
  },
  forgot: {
    fontSize: 14,
    color: "#5C57F2",
    fontWeight: 500,
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:968px)");
  const classes = useStyles({ isMobile });

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const signin = useCallback(async () => {
    setLoading(true);
    setError("");
    const result = await login(email, password);
    if (result.msg != "") alert(result.msg);
    if (result.err) {
      if (result.err_msg != "") setError(result.err_msg);
    } else {
      dispatch(setPatients(result.data.patients));
      dispatch(setUser(result.data));
      history.push("/patients");
    }
    setLoading(false);
  }, [email, password, dispatch, history]);

  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <CookieConsent />
      <Grid
        container
        component="main"
        className={classes.root}
        alignItems="center"
      >
        {loading ? (
          <LinearProgress className={classes.loading} color="primary" />
        ) : (
          ""
        )}
        {!isMobile && <Grid item className={classes.lateral} lg={5}></Grid>}
        <Grid item className={classes.content} xs={12} lg={7}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={logo} className={classes.logo} />
            <Typography className={classes.name} color="primary">
              inspir<b>ar</b>
            </Typography>
          </div>

          <form noValidate style={{ width: "100%" }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              color="primary"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="senha"
              label="Senha"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              color="primary"
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              style={{ width: "100%" }}
            >
              <Typography color="error" className={classes.error}>
                {error}
              </Typography>
              <Typography
                onClick={() => history.push("/forgotpassword")}
                className={classes.forgot}
              >
                Esqueci minha senha
              </Typography>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => signin()}
              disabled={loading}
            >
              Entrar na conta
            </Button>
            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{ width: "100%", marginTop: "30px" }}
            >
              <Typography
                onClick={() => history.push("/token-access")}
                className={classes.forgot}
              >
                Ou entrar com token
              </Typography>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
