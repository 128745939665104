import { parse } from "date-fns";

export const handlePatientWeeklyStatusData = (patient) => {
  if (patient) {

    const imc = patient.weight / (patient.height / 100) ** 2;
    const height = patient.height;
    const weight = patient.weight;
    const email = patient.email;
    const personal_phone = patient.personal_phone;
    const cpf = patient.cpf;
    const birthDate = patient.birth_date;
    const fullName = patient.full_name;
    const id = patient.patient_id;

    var i, j;
    var data_days = [];
    var data_steps_a_day = [];
    var data_met_a_day = [];
    var data_active_minutes_a_day = [];

    if (patient.activityOverallInfo) {
      for (i = 0; i < patient.activityOverallInfo.length; i++) {
        data_days = [
          ...data_days,
          patient.activityOverallInfo[i].date.split("T")[0].split("-")[2] +
            "/" +
            patient.activityOverallInfo[i].date.split("T")[0].split("-")[1],
        ];
        data_steps_a_day = [
          ...data_steps_a_day,
          patient.activityOverallInfo[i].steps,
        ];
        data_met_a_day = [
          ...data_met_a_day,
          patient.activityOverallInfo[i].steps,
        ];
        data_active_minutes_a_day = [
          ...data_active_minutes_a_day,
          patient.activityOverallInfo[i].activeMin,
        ];
      }
    }

    var data_acq = [];
    var data_daily_tosse = [];
    var data_daily_chiado = [];
    var data_daily_bombinha = [];
    var data_daily_falta_de_ar = [];
    var data_daily_acordar = [];

    if (patient.acqs) {
      for (i = 1; i <= patient.acqs.length; i++) {
        if (
          patient.acqs[patient.acqs.length - i].type == "acq" &&
          data_acq.length == 0
        ) {
          data_acq = [
            patient.acqs[patient.acqs.length - i].q1,
            patient.acqs[patient.acqs.length - i].q2,
            patient.acqs[patient.acqs.length - i].q3,
            patient.acqs[patient.acqs.length - i].q4,
            patient.acqs[patient.acqs.length - i].q5,
            patient.acqs[patient.acqs.length - i].q6,
          ];
        }
        if (patient.acqs[i - 1].type == "diary") {
          data_daily_tosse = [...data_daily_tosse, patient.acqs[i - 1].q1];
          data_daily_chiado = [...data_daily_chiado, patient.acqs[i - 1].q2];
          data_daily_bombinha = [
            ...data_daily_bombinha,
            patient.acqs[i - 1].q3,
          ];
          data_daily_falta_de_ar = [
            ...data_daily_falta_de_ar,
            patient.acqs[i - 1].q4,
          ];
          data_daily_acordar = [...data_daily_acordar, patient.acqs[i - 1].q5];
        }
      }
    }

    var data_barriers = [
      patient.barriers.q1.N,
      patient.barriers.q2.N,
      patient.barriers.q3.N,
      patient.barriers.q4.N,
      patient.barriers.q5.N,
      patient.barriers.q6.N,
      patient.barriers.q7.N,
      patient.barriers.q8.N,
      patient.barriers.q9.N,
      patient.barriers.q10.N,
    ];

    for (i = 0; i < data_steps_a_day.length; i++) {
      data_steps_a_day[i] = parseInt(data_steps_a_day[i], 10);
    }
    for (i = 0; i < data_met_a_day.length; i++) {
      data_met_a_day[i] = parseInt(data_met_a_day[i], 10);
    }
    for (i = 0; i < data_active_minutes_a_day.length; i++) {
      data_active_minutes_a_day[i] = parseInt(data_active_minutes_a_day[i], 10);
    }
    for (i = 0; i < data_daily_tosse.length; i++) {
      data_daily_tosse[i] = parseInt(data_daily_tosse[i], 10);
    }
    for (i = 0; i < data_daily_chiado.length; i++) {
      data_daily_chiado[i] = parseInt(data_daily_chiado[i], 10);
    }
    for (i = 0; i < data_daily_bombinha.length; i++) {
      data_daily_bombinha[i] = parseInt(data_daily_bombinha[i], 10);
    }
    for (i = 0; i < data_daily_falta_de_ar.length; i++) {
      data_daily_falta_de_ar[i] = parseInt(data_daily_falta_de_ar[i], 10);
    }
    for (i = 0; i < data_daily_acordar.length; i++) {
      data_daily_acordar[i] = parseInt(data_daily_acordar[i], 10);
    }
    for (i = 0; i < data_acq.length; i++) {
      data_acq[i] = parseInt(data_acq[i], 10);
    }
    for (i = 0; i < data_barriers.length; i++) {
      data_barriers[i] = parseInt(data_barriers[i], 10);
    }

    var avg_steps_week = 0;
    var avg_met_week = 0;
    var avg_minutes_week = 0;

    for (
      i = data_steps_a_day.length - 1;
      i >= 0 && i >= data_steps_a_day.length - 7;
      i--
    ) {
      avg_steps_week += data_steps_a_day[i];
    }
    if (i < 0 && data_steps_a_day.length > 0) {
      avg_steps_week /= data_steps_a_day.length;
      avg_steps_week = avg_steps_week.toFixed(0);
    } else {
      avg_steps_week /= 7;
      avg_steps_week = avg_steps_week.toFixed(0);
    }

    for (
      i = data_met_a_day.length - 1;
      i >= 0 && i >= data_met_a_day.length - 7;
      i--
    ) {
      avg_met_week += data_met_a_day[i];
    }
    if (i < 0 && data_met_a_day.length > 0) {
      avg_met_week /= data_met_a_day.length;
      avg_met_week = avg_met_week.toFixed(0);
    } else {
      avg_met_week /= 7;
      avg_met_week = avg_met_week.toFixed(0);
    }

    for (
      i = data_active_minutes_a_day.length - 1;
      i >= 0 && i >= data_active_minutes_a_day.length - 7;
      i--
    ) {
      avg_minutes_week += data_active_minutes_a_day[i];
    }
    if (i < 0 && data_active_minutes_a_day.length > 0) {
      avg_minutes_week /= data_active_minutes_a_day.length;
      avg_minutes_week = avg_minutes_week.toFixed(0);
    } else {
      avg_minutes_week /= 7;
      avg_minutes_week = avg_minutes_week.toFixed(0);
    }

    var porcent_melhora_tosse = 0;
    var porcent_melhora_chiado = 0;
    var porcent_melhora_bombinha = 0;
    var porcent_melhora_falta_de_ar = 0;
    var porcent_melhora_acordar = 0;

    var cont;
    var result_sem_passada;
    var result_sem_retrasada;

    if (data_daily_tosse.length > 7) {
      cont = 0;
      result_sem_passada = 0;
      result_sem_retrasada = 0;

      i = data_daily_tosse.length - 1;
      j = data_daily_tosse.length - 8;
      while (cont < 7) {
        result_sem_passada += data_daily_tosse[i--];
        if (j == -1) {
          j += 7;
        }
        result_sem_retrasada += data_daily_tosse[j--];
        cont++;
      }
      if (result_sem_retrasada == 0) {
        if (result_sem_passada != 0) {
          porcent_melhora_tosse = 10000; // sintoma reapareceu!
        }
      } else {
        porcent_melhora_tosse = (
          (100 * result_sem_passada) / result_sem_retrasada -
          100
        ).toFixed(0);
      }
    }

    if (data_daily_chiado.length > 7) {
      cont = 0;
      result_sem_passada = 0;
      result_sem_retrasada = 0;

      i = data_daily_chiado.length - 1;
      j = data_daily_chiado.length - 8;
      while (cont < 7) {
        result_sem_passada += data_daily_chiado[i--];
        if (j == -1) {
          j += 7;
        }
        result_sem_retrasada += data_daily_chiado[j--];
        cont++;
      }
      if (result_sem_retrasada == 0) {
        if (result_sem_passada != 0) {
          porcent_melhora_chiado = 10000; // sintoma reapareceu!
        }
      } else {
        porcent_melhora_chiado = (
          (100 * result_sem_passada) / result_sem_retrasada -
          100
        ).toFixed(0);
      }
    }

    if (data_daily_bombinha.length > 7) {
      cont = 0;
      result_sem_passada = 0;
      result_sem_retrasada = 0;

      i = data_daily_bombinha.length - 1;
      j = data_daily_bombinha.length - 8;
      while (cont < 7) {
        result_sem_passada += data_daily_bombinha[i--];
        if (j == -1) {
          j += 7;
        }
        result_sem_retrasada += data_daily_bombinha[j--];
        cont++;
      }
      if (result_sem_retrasada == 0) {
        if (result_sem_passada != 0) {
          porcent_melhora_bombinha = 10000; // sintoma reapareceu!
        }
      } else {
        porcent_melhora_bombinha = (
          (100 * result_sem_passada) / result_sem_retrasada -
          100
        ).toFixed(0);
      }
    }

    if (data_daily_falta_de_ar.length > 7) {
      cont = 0;
      result_sem_passada = 0;
      result_sem_retrasada = 0;

      i = data_daily_falta_de_ar.length - 1;
      j = data_daily_falta_de_ar.length - 8;
      while (cont < 7) {
        result_sem_passada += data_daily_falta_de_ar[i--];
        if (j == -1) {
          j += 7;
        }
        result_sem_retrasada += data_daily_falta_de_ar[j--];
        cont++;
      }
      if (result_sem_retrasada == 0) {
        if (result_sem_passada != 0) {
          porcent_melhora_falta_de_ar = 10000; // sintoma reapareceu!
        }
      } else {
        porcent_melhora_falta_de_ar = (
          (100 * result_sem_passada) / result_sem_retrasada -
          100
        ).toFixed(0);
      }
    }

    if (data_daily_acordar.length > 7) {
      cont = 0;
      result_sem_passada = 0;
      result_sem_retrasada = 0;

      i = data_daily_acordar.length - 1;
      j = data_daily_acordar.length - 8;
      while (cont < 7) {
        result_sem_passada += data_daily_acordar[i--];
        if (j == -1) {
          j += 7;
        }
        result_sem_retrasada += data_daily_acordar[j--];
        cont++;
      }
      if (result_sem_retrasada == 0) {
        if (result_sem_passada != 0) {
          porcent_melhora_acordar = 10000; // sintoma reapareceu!
        }
      } else {
        porcent_melhora_acordar = (
          (100 * result_sem_passada) / result_sem_retrasada -
          100
        ).toFixed(0);
      }
    }

    var data_steps_chart = [];
    var data_met_chart = [];
    var data_minutes_chart = [];
    for (i = 0; i < data_days.length; i++) {
      data_steps_chart = [
        ...data_steps_chart,
        { dateTime: data_days[i], value: data_steps_a_day[i] },
      ];
      data_met_chart = [
        ...data_met_chart,
        { dateTime: data_days[i], value: data_met_a_day[i] },
      ];
      data_minutes_chart = [
        ...data_minutes_chart,
        { dateTime: data_days[i], value: data_active_minutes_a_day[i] },
      ];
    }

    return {
      id,
      cpf,
      imc,
      email,
      height,
      weight,
      fullName,
      birthDate,
      personal_phone,
      porcent_melhora_tosse,
      porcent_melhora_chiado,
      porcent_melhora_bombinha,
      porcent_melhora_falta_de_ar,
      porcent_melhora_acordar,
    };
  }

  return;
};

export function getAge(date) {
  var temp = date?.split("T")[0]?.split("-");
  var day = temp[2];
  var month = temp[1];
  var year = temp[0];

  var dayToday = new Date().getDate();
  var monthToday = new Date().getMonth() + 1;
  var yearToday = new Date().getFullYear();

  return monthToday > month || (monthToday == month && dayToday >= day)
    ? yearToday - year
    : yearToday - year - 1;
}

export function formatDateDDMMYYYY(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export function formatDateYYYYMMDD(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Meses são baseados em zero, então adicione 1
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function getFilterStartDate(dateString, limitValue) {
  // Cria um objeto Date a partir da string de data
  const date = new Date(dateString);

  // Subtrai 7 dias da data
  date.setDate(date.getDate() - limitValue);

  // Formata a data resultante no formato YYYY-MM-DD
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Meses são baseados em zero, então adicione 1
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function parseFilterDateString(filterDateString) {
  const date = parse(filterDateString, "yyyy-MM-dd", new Date());
  return date;
}

export function formatCPF(cpfString) {
  const cleaned = cpfString.replace(/\D/g, "");

  if (cleaned.length !== 11) {
    throw new Error("CPF deve ter 11 dígitos");
  }

  const formattedCPF = cleaned.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    "$1.$2.$3-$4"
  );

  return formattedCPF;
}

export function formatPhoneNumber(phoneNumber) {
  const cleaned = phoneNumber.replace(/\D/g, "");

  if (cleaned.length !== 10 && cleaned.length !== 11) {
    return phoneNumber;
  }

  const formattedPhoneNumber =
    cleaned.length === 10
      ? cleaned.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
      : cleaned.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");

  return formattedPhoneNumber;
}
