import {
  Button,
  Typography,
  Link,
  Grid,
  InputLabel,
  Paper,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import Amplify, { Auth, API } from "aws-amplify";
import LinearProgress from "@material-ui/core/LinearProgress";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CheckBox from "@material-ui/icons/CheckBox";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import { prepareData } from "../../controllers/patientController";
import login from "../../controllers/loginController";
import logo from "../../assets/logo.png";
import Menu from "../../components/menu";
import Header from "../../components/header";
import CookieConsent from "../../components/cookiesConsent";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    position: "sticky",
  },
  "@media (min-width: 1025px)": {
    root: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  loading: {
    width: "100%",
    position: "fixed",
    top: 0,
    height: 7,
  },
  upperContent: {
    flexGrow: 1,
    paddingRight: 80,
    paddingLeft: 60,
    marginTop: 90,
  },
  content: {
    flexGrow: 1,
    paddingRight: 80,
    paddingLeft: 60,
    marginTop: 40,
  },
  add: {
    width: 240,
    textTransform: "capitalize",
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: 600,
    height: 50,
    borderRadius: 8,
  },
  paper: {
    width: "100%",
    minHeight: 200,
    padding: 20,
  },
  title: {
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: 600,
    marginBottom: 20,
  },
  form: {
    width: "100%",
  },
  input: {
    width: "100%",
    marginRight: 10,
    marginBottom: 10,
  },
  "@media (min-width: 800px)": {
    input: {
      width: "40%",
      marginRight: 30,
    },
  },
  submit: {
    marginTop: 23,
    width: "12%",
    height: 40,
  },
  edit: {
    marginTop: 23,
    width: 20,
    height: 40,
    marginRight: 9,
    backgroundColor: "#5ddb84",
    color: "#fff",
  },
  delete: {
    marginTop: 23,
    width: 20,
    height: 40,
    backgroundColor: "#e95656",
    color: "#fff",
  },
}));

export default function Materials() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState(false);
  const [videos, setVideos] = useState(false);

  const [editFaq, setEditFaq] = useState("");
  const [editVideo, setEditVideo] = useState("");
  const [editFaqQuestion, setEditFaqQuestion] = useState("");
  const [editFaqAnswer, setEditFaqAnswer] = useState("");
  const [editVideoTitle, setEditVideoTitle] = useState("");
  const [editVideoUrl, setEditVideoUrl] = useState("");

  useEffect(async () => {
    setLoading(true);
    await API.get("webEndpoints", "/materials")
      .then(async (response) => {
        setFaqs(response.faqs);
        setVideos(response.video);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(JSON.stringify(error));
      });
  }, []);

  async function create(type) {
    setLoading(true);

    let myInit = {
      body: {
        type: type,
        question_or_title: question,
        answer_or_uri: answer,
      },
    };

    await API.post("webEndpoints", "/materials", myInit)
      .then(async (response) => {
        const actualData =
          type == "faq" ? [...faqs, response] : [...videos, response];
        type == "faq" ? setFaqs(actualData) : setVideos(actualData);
        setLoading(false);
        setQuestion("");
        setAnswer("");
      })
      .catch((error) => {
        setLoading(false);
        console.error(JSON.stringify(error));
      });
  }

  async function changeContent(type, id) {
    setLoading(true);

    if (type == "faq") {
      let myInit = {
        body: {
          question_or_title: editFaqQuestion,
          answer_or_uri: editFaqAnswer,
          material_id: id,
          type: "faq",
        },
      };
      await API.post("webEndpoints", "/materials/edit", myInit)
        .then(async (response) => {
          var newFaqs = [...faqs];
          const index = newFaqs.findIndex((item) => item.material_id === id);
          newFaqs[index] = myInit.body;
          setFaqs(newFaqs);
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
        });
      setLoading(false);
      setEditFaq("");
      setEditFaqQuestion("");
      setEditFaqAnswer("");
    } else if (type == "video") {
      let myInit = {
        body: {
          question_or_title: editVideoTitle,
          answer_or_uri: editVideoUrl,
          material_id: id,
          type: "video",
        },
      };
      await API.post("webEndpoints", "/materials/edit", myInit)
        .then(async (response) => {
          var newVideos = [...videos];
          const index = newVideos.findIndex((item) => item.material_id === id);
          newVideos[index] = myInit.body;
          setVideos(newVideos);
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
        });
      setLoading(false);
      setEditVideo("");
      setEditVideoTitle("");
      setEditVideoUrl("");
    }
  }

  async function deleteContent(type, id) {
    setLoading(true);
    let myInit = {
      body: {
        material_id: id,
      },
    };
    await API.post("webEndpoints", "/materials/delete", myInit);

    if (type == "faq") {
      var newFaqs = [...faqs];
      newFaqs = newFaqs.filter(function (item) {
        return item.material_id !== id;
      });
      setFaqs(newFaqs);
    } else if (type == "video") {
      var newVideos = [...videos];
      newVideos = newVideos.filter(function (item) {
        return item.material_id !== id;
      });
      setVideos(newVideos);
    }
    setLoading(false);
    setEditFaq("");
    setEditFaqQuestion("");
    setEditFaqAnswer("");
  }

  return (
    <>
      <CookieConsent />
      <Grid container className={classes.root} alignContent="flex-start">
        {loading ? (
          <LinearProgress color="primary" className={classes.loading} />
        ) : (
          ""
        )}
        <Header label="Materiais" />
        <Menu history={history} />
        <Grid container spacing={4} className={classes.upperContent}>
          <Paper elevation={1} className={classes.paper}>
            <Typography className={classes.title}>FAQ</Typography>
            {faqs.length > 0
              ? faqs.map((faq) => {
                  const edit = faq.material_id == editFaq;
                  return (
                    <Grid
                      item
                      className={classes.form}
                      justifyContent="space-between"
                      direction="row"
                      alignItems="center"
                    >
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        id="question"
                        name="question"
                        autoComplete="question"
                        multiline
                        autoFocus
                        color="primary"
                        value={edit ? editFaqQuestion : faq.question_or_title}
                        disabled={!edit}
                        onChange={(e) => setEditFaqQuestion(e.target.value)}
                      />
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        id="answer"
                        name="answer"
                        autoComplete="answer"
                        autoFocus
                        multiline
                        color="primary"
                        value={edit ? editFaqAnswer : faq.answer_or_uri}
                        disabled={!edit}
                        onChange={(e) => setEditFaqAnswer(e.target.value)}
                      />
                      {!edit ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={loading}
                          onClick={() => {
                            setEditFaq(faq.material_id);
                            setEditFaqQuestion(faq.question_or_title);
                            setEditFaqAnswer(faq.answer_or_uri);
                          }}
                        >
                          EDITAR
                        </Button>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            className={classes.edit}
                            disabled={loading}
                            onClick={async () =>
                              await changeContent("faq", faq.material_id)
                            }
                          >
                            <CheckBox fontSize="small" />
                          </Button>
                          <Button
                            variant="contained"
                            className={classes.delete}
                            disabled={loading}
                            onClick={async () =>
                              await deleteContent("faq", faq.material_id)
                            }
                          >
                            <DeleteForeverIcon fontSize="small" />
                          </Button>
                        </>
                      )}
                    </Grid>
                  );
                })
              : ""}
            <Grid
              item
              className={classes.form}
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                id="question"
                label="Questão"
                name="question"
                autoComplete="question"
                autoFocus
                multiline
                color="primary"
                onChange={(e) => setQuestion(e.target.value)}
              />
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                id="answer"
                label="Resposta"
                name="answer"
                autoComplete="answer"
                autoFocus
                multiline
                color="primary"
                onChange={(e) => setAnswer(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => create("faq")}
                disabled={loading}
              >
                Criar
              </Button>
            </Grid>
          </Paper>
        </Grid>
        <Grid container spacing={4} className={classes.content}>
          <Paper elevation={1} className={classes.paper}>
            <Typography className={classes.title}>Vídeos</Typography>
            {videos.length > 0
              ? videos.map((video) => {
                  const edit = video.material_id == editVideo;
                  return (
                    <Grid
                      item
                      className={classes.form}
                      justifyContent="space-between"
                      direction="row"
                      alignItems="center"
                    >
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        id="question"
                        name="question"
                        autoComplete="question"
                        autoFocus
                        multiline
                        color="primary"
                        value={edit ? editVideoTitle : video.question_or_title}
                        disabled={!edit}
                        onChange={(e) => setEditVideoTitle(e.target.value)}
                      />
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        id="answer"
                        name="answer"
                        autoComplete="answer"
                        autoFocus
                        multiline
                        color="primary"
                        value={edit ? editVideoUrl : video.answer_or_uri}
                        disabled={!edit}
                        onChange={(e) => setEditVideoUrl(e.target.value)}
                      />
                      {!edit ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={loading}
                          onClick={() => {
                            setEditVideo(video.material_id);
                            setEditVideoTitle(video.question_or_title);
                            setEditVideoUrl(video.answer_or_uri);
                          }}
                        >
                          EDITAR
                        </Button>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            className={classes.edit}
                            disabled={loading}
                            onClick={async () =>
                              await changeContent("video", video.material_id)
                            }
                          >
                            <CheckBox fontSize="small" />
                          </Button>
                          <Button
                            variant="contained"
                            className={classes.delete}
                            disabled={loading}
                            onClick={async () =>
                              await deleteContent("video", video.material_id)
                            }
                          >
                            <DeleteForeverIcon fontSize="small" />
                          </Button>
                        </>
                      )}
                    </Grid>
                  );
                })
              : ""}
            <Grid
              item
              className={classes.form}
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                id="title"
                label="Título"
                name="title"
                autoComplete="title"
                autoFocus
                color="primary"
                onChange={(e) => setQuestion(e.target.value)}
              />
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                id="url"
                label="URL"
                name="url"
                autoComplete="url"
                autoFocus
                color="primary"
                onChange={(e) => setAnswer(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => create("video")}
                disabled={loading}
              >
                Criar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
