import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Login from "../src/pages/Login";
import Confirm from "../src/pages/Confirm";
import Confirmation from "../src/pages/ProfessionalConfirmation";

import { Patients, PatientsAdmin } from "../src/pages/Patients";

import Professionals from "../src/pages/Professionals";
import NewPatient from "../src/pages/NewPatient";
import NewProfessional from "../src/pages/NewProfessional";
import NotFound from "../src/pages/404";
import Details from "../src/pages/Details";
import Materials from "../src/pages/Materials";
import Home from "../src/pages/Home";
import FAQ from "../src/pages/FAQ";
import TermsOfUse from "../src/pages/TermsOfUse";
import PrivacyPolicy from "../src/pages/PrivacyPolicy";
import ForgotPassword from "../src/pages/ForgotPassword";
import ResetPassword from "../src/pages/ResetPassword";

import PrivateRoute from "./components/PrivateRoute";
import TokenAccess from "./pages/TokenAccess";
import PrivateRouteToken from "./components/PrivateRouteToken";

function Routes() {
  const user = useSelector((state) => state.currentUser);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/faq" exact component={FAQ} />
        <Route path="/termsofuse" exact component={TermsOfUse} />
        <Route path="/privacypolicy" exact component={PrivacyPolicy} />
        <Route path="/login" exact component={Login} />
        <Route path="/resetpassword" exact component={ResetPassword} />
        <Route path="/forgotpassword" exact component={ForgotPassword} />
        {user && user.type !== "admin" ? (
          <PrivateRoute path="/patients" exact Component={Patients} />
        ) : (
          <PrivateRoute path="/patients" exact Component={PatientsAdmin} />
        )}

        <PrivateRouteToken path="/patient/:email" Component={Details} exact />
        <PrivateRoute path="/professionals" exact Component={Professionals} />
        <PrivateRoute path="/materials" exact Component={Materials} />
        <PrivateRoute
          path="/newprofessional"
          exact
          Component={NewProfessional}
        />
        <Route path="/token-access" exact component={TokenAccess} />
        <Route path="/register" exact component={NewPatient} />
        <Route path="/confirm" exact component={Confirm} />
        <Route path="/confirmation" exact component={Confirmation} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
