import {SET_TOKEN} from "./lib/types";

export {
    CHANGE_VIEW,
    SET_USER,
    REMOVE_USER,
    SET_PATIENTS,
    SET_PATIENT,
    REMOVE_PATIENTS,
    REMOVE_PATIENT,
    SET_TEAM,
    REMOVE_TEAM
} from './lib/types.js';

export {
    changeView
} from './lib/viewActions.js';

export const setToken = (value) => {
    return {
        type: SET_TOKEN,
        payload: value
    };
}

export {
    setUser,
    removeUser
} from './lib/userActions.js';

export {
    setPatients,
    removePatients
} from './lib/patientActions.js';

export {
    setPatient,
    removePatient
} from './lib/currentPatientActions.js';

export {
    setTeam,
    removeTeam
} from './lib/teamActions.js';

