import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

// HELPERS
import {
  formatDateYYYYMMDD,
  getFilterStartDate,
  handlePatientWeeklyStatusData,
} from "./helpers";

// REQUESTS
import {
  requestACQhistory,
  requestDailySymptoms,
  requestDailySymptomsBySymptom,
  requestMedicationChartData,
  requestUserData,
} from "../../services/userData";

// COMPONENTS
import ACQchart from "./ACQchart";
import PatientInfo from "./PatientInfo";
import Menu from "../../components/menu";
import Header from "../../components/header";
import MedicationChart from "./MedicationChart";
import DailySymptomsChart from "./DailySymptomsChart";
import DailySymptomsChartBySymptom from "./DailySymptomsChartBySymptom";

// STYLES
import { useDetailsStyles } from "./styles";
import "./styles.css";
import CookieConsent from "../../components/cookiesConsent";

export default function Details() {
  const { email } = useParams();
  const tokenEmail = useSelector((state) => state?.token?.email);
  const user = useSelector((state) => state?.currentUser);

  const history = useHistory();
  const classes = useDetailsStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [isMedicationLoading, setIsMedicationLoading] = useState(false);
  const [symptomValueFilter, setSymptomValueFilter] = useState("tosse");
  const [medicationValueFilter, setMedicationValueFilter] = useState("");

  const [formattedPatientData, setFormattedPatientData] = useState();

  const [dailySymptomChartData, setDailySymptomChartData] = useState({});
  const [medicationChartData, setMedicationChartData] = useState({});
  const [acqChartData, setAcqChartData] = useState({});
  const [dailySymptomChartBySymptomData, setDailySymptomChartBySymptomData] =
    useState({});

  const today = new Date();

  const [filterDate, setFilterDate] = useState(formatDateYYYYMMDD(today));

  useEffect(() => {
    setIsLoading(true);
    const getPatientData = async () => {
      await requestUserData(email)
        .then((response) => {
          const formattedResponse = handlePatientWeeklyStatusData(
            response.data
          );
          setFormattedPatientData(formattedResponse);
        })
        .catch((err) => {
          console.error("erro", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    getPatientData();
  }, [email]);

  useMemo(async () => {
    setIsMedicationLoading(true);
    const startDate = getFilterStartDate(filterDate, 29);

    try {
      const response = await requestMedicationChartData({
        patientId: formattedPatientData?.id,
        medicationId: medicationValueFilter,
        startDate,
        endDate: filterDate,
      });
      setMedicationChartData(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsMedicationLoading(false);
    }
  }, [formattedPatientData?.id, filterDate, medicationValueFilter]);

  useEffect(() => {
    const getDailySymptoms = async () => {
      const startDate = getFilterStartDate(filterDate, 5);

      requestDailySymptoms({
        userCPF: formattedPatientData.cpf,
        startDate: startDate,
        endDate: filterDate,
      })
        .then((response) => {
          setDailySymptomChartData(response.data);
        })
        .catch(() => {
          console.error("erro dados grafico");
        });
    };

    const getDailySymptomsByMonth = async () => {
      const startDate = getFilterStartDate(filterDate, 29);

      requestDailySymptomsBySymptom({
        userCPF: formattedPatientData.cpf,
        startDate: startDate,
        endDate: filterDate,
        symptom: symptomValueFilter,
      })
        .then((response) => {
          setDailySymptomChartBySymptomData(response.data);
        })
        .catch(() => {
          console.error("erro dados grafico");
        });
    };

    const getACQhistory = async () => {
      const startDate = getFilterStartDate(filterDate, 29);

      requestACQhistory({
        userCPF: formattedPatientData.cpf,
        startDate: startDate,
        endDate: filterDate,
      })
        .then((response) => {
          setAcqChartData(response.data);
        })
        .catch(() => {
          console.error("erro dados grafico");
        });
    };

    if (formattedPatientData?.cpf) {
      getDailySymptoms();
      getDailySymptomsByMonth();
      getACQhistory();
    }
  }, [formattedPatientData, filterDate, symptomValueFilter]);

  if (!user.email && email !== tokenEmail) {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  if (isLoading) {
    return <LinearProgress className={classes.loading} />;
  }

  return (
    <>
      <CookieConsent />
      <Grid container className={classes.root}>
        <Header />

        <Menu history={history} />

        <div className="patient-details-container">
          <PatientInfo
            name={formattedPatientData?.fullName}
            birthDate={formattedPatientData?.birthDate}
            cpf={formattedPatientData?.cpf}
            email={formattedPatientData?.email}
            height={formattedPatientData?.height}
            imc={(Math.round(formattedPatientData?.imc * 100) / 100).toFixed(1)}
            phone={formattedPatientData?.personal_phone}
            weight={formattedPatientData?.weight}
          />
        </div>

        <div className="details-chart-container">
          <h3 className="health-charts-title">Diário de sintomas</h3>

          <DailySymptomsChart
            dailySymptomChartData={dailySymptomChartData}
            setFilterDate={setFilterDate}
            filterDate={filterDate}
          />

          <DailySymptomsChartBySymptom
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            setSymptomValueFilter={setSymptomValueFilter}
            symptomValueFilter={symptomValueFilter}
            dailySymptomChartBySymptomData={dailySymptomChartBySymptomData}
          />

          <h3 className="health-charts-title">Medicação</h3>

          <MedicationChart
            data={medicationChartData}
            setFilterDate={setFilterDate}
            filterDate={filterDate}
            setValueFilter={setMedicationValueFilter}
            isLoading={isMedicationLoading}
          />

          <h3 className="health-charts-title">ACQ</h3>

          <ACQchart
            acqChartData={acqChartData}
            setFilterDate={setFilterDate}
            filterDate={filterDate}
          />
        </div>
      </Grid>
    </>
  );
}
