import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, useMediaQuery } from "@material-ui/core";
import { DrawerContext } from "./menu";
import {Menu as MenuIcon} from "@material-ui/icons"
import {useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
   root: {
      display: "flex",
      justifyContent: ({isMobile}) => isMobile ? "space-between" : "center",
      paddingInline: ({isMobile}) => isMobile ? "18px 12px" : 0,
      alignItems: "center",
      height: "50px",
      width: "100%",
      backgroundColor: "white",
      top: 0,
   },
   title: {
      fontSize: 16,
      letterSpacing: 1,
      fontWeight: 400,
      paddingRight: ({isMobile}) => isMobile ? 8 : 0,
   },
}));

export default function Menu({ label }) {
   const isMobile = useMediaQuery('(max-width:968px)');
   const classes = useStyles({isMobile});
   const { isOpen, toggleDrawer } = useContext(DrawerContext);
   const user = useSelector((state) => state.currentUser);

   return (
      <div className={classes.root}>
         <Typography className={classes.title}>{label}</Typography>
         {isMobile && user.email && <Button variant="outlined"  onClick={toggleDrawer} title={isOpen ? "Fechar menu" : "Expandir menu"}>
            <MenuIcon/>
            </Button>}
      </div>
   );
}
