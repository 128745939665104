import clsx from "clsx";
import { Button } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import {
  AlertDialog,
  AlertDialogResendCode,
  AlertDialogDeleteUser,
} from "../components/dialog";

async function listPatients() {
  const patients = await fetch(process.env.REACT_APP_GET_ALL_PATIENTS)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  try {
    patients.sort((a, b) =>
      a.full_name.toUpperCase() > b.full_name.toUpperCase()
        ? 1
        : b.full_name.toUpperCase() > a.full_name.toUpperCase()
        ? -1
        : 0
    );
    return patients;
  } catch {}
}

export async function prepareDataAdmin(classes, history) {
  const rows = [];
  const rows_nao_vinculados = [];
  const patients = await listPatients();

  for (var i = 0; i < patients.length; i++) {
    if (patients[i].associated_professional !== null) {
      rows.push({
        id: patients[i].patient_id,
        full_name: patients[i].full_name,
        personal_phone: patients[i].personal_phone,
        personal_email: patients[i].email,
        birth_date:
          patients[i].birth_date.split("T")[0].split("-")[2] +
          "/" +
          patients[i].birth_date.split("T")[0].split("-")[1] +
          "/" +
          patients[i].birth_date.split("T")[0].split("-")[0],
        google_fit_status: patients[i].google_token ? "Ativo" : "Inativo",
        nome_profetional: patients[i].professional_name,
        email_profetional: patients[i].professional_email,
      });
    } else {
      rows_nao_vinculados.push({
        id: patients[i].patient_id,
        full_name: patients[i].full_name,
        personal_phone: patients[i].personal_phone,
        personal_email: patients[i].email,
        birth_date:
          patients[i].birth_date.split("T")[0].split("-")[2] +
          "/" +
          patients[i].birth_date.split("T")[0].split("-")[1] +
          "/" +
          patients[i].birth_date.split("T")[0].split("-")[0],
        google_fit_status: patients[i].google_token ? "Ativo" : "Inativo",
      });
    }
  }

  const columns_nao_vinculados = [
    {
      field: "full_name",
      headerName: "Nome Completo",
      sortable: true,
      width: 250,
    },
    {
      field: "personal_phone",
      headerName: "Contato",
      sortable: true,
      width: 140,
    },
    {
      field: "personal_email",
      headerName: "Correio eletrônico",
      sortable: true,
      width: 250,
    },
    {
      field: "birth_date",
      headerName: "Data de Nascimento",
      sortable: true,
      width: 210,
    },
    {
      field: "google_fit_status",
      headerName: "Status da Conta",
      sortable: true,
      width: 180,
      cellClassName: (params) => {
        return clsx(classes, {
          [classes.approved]: params.value.split(" ")[0] == `Ativo`,
          [classes.eliminated]: params.value.split(" ")[0] == `Inativo`,
        });
      },
    },
    {
      field: "details",
      width: 140,
      headerName: "Detalhes",
      renderCell: (params) => {
        const filtered = patients.find((pat) => {
          return pat.patient_id === params.id;
        });

        return (
          <Button
            disabled={!filtered}
            onClick={() => history.push("patient/" + filtered?.email)}
          >
            <DoubleArrowIcon
              style={{
                cursor: "pointer",
                color: "#5c57f2",
                margin: 5,
                textAlign: "center",
              }}
            />
          </Button>
        );
      },
    },
    // Re-send sign up confirmation code
    {
      field: "action1",
      headerName: "Código de confirmação",
      sortable: false,
      width: 220,
      renderCell: (params) => {
        var filtered = patients.filter((pat) => {
          return pat.patient_id == params.id;
        });
        let email = filtered[0]["email"];

        return <AlertDialogResendCode email_paciente={email} />;
      },
    },
    // Delete user
    {
      field: "action2",
      headerName: "Deletar usuário",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        var filtered = patients.filter((pat) => {
          return pat.patient_id == params.id;
        });
        let email = filtered[0]["email"];
        let patient_id = filtered[0]["patient_id"];

        return (
          <AlertDialogDeleteUser
            id_paciente={patient_id}
            email_paciente={email}
          />
        );
      },
    },
  ];

  const columns = [
    {
      field: "full_name",
      headerName: "Nome Completo",
      sortable: true,
      width: 250,
    },
    //{ field: 'email', headerName: 'Correo', sortable: true, width: 280, hide:true},
    {
      field: "personal_phone",
      headerName: "Contato",
      sortable: true,
      width: 140,
    },
    {
      field: "personal_email",
      headerName: "Correio eletrônico",
      sortable: true,
      width: 250,
    },
    {
      field: "birth_date",
      headerName: "Data de Nascimento",
      sortable: true,
      width: 210,
    },
    {
      field: "google_fit_status",
      headerName: "Status da Conta",
      sortable: true,
      width: 180,
      cellClassName: (params) => {
        return clsx(classes, {
          [classes.approved]: params.value.split(" ")[0] == `Ativo`,
          [classes.eliminated]: params.value.split(" ")[0] == `Inativo`,
        });
      },
    },
    {
      field: "details",
      width: 140,
      headerName: "Detalhes",
      renderCell: (params) => {
        const filtered = patients.find((pat) => {
          return pat.patient_id === params.id;
        });

        return (
          <Button
            disabled={!filtered}
            onClick={() => history.push("patient/" + filtered?.email)}
          >
            <DoubleArrowIcon
              style={{
                cursor: "pointer",
                color: "#5c57f2",
                margin: 5,
                textAlign: "center",
              }}
            />
          </Button>
        );
      },
    },
    {
      field: "nome_profetional",
      headerName: "Profissional Asociado",
      sortable: true,
      width: 240,
    },
    // Adding button field with actions
    {
      field: "action1",
      headerName: "Açao",
      sortable: false,
      width: 220,
      renderCell: (params) => {
        var filtered = patients.filter((pat) => {
          return pat.patient_id == params.id;
        });
        let email = filtered[0]["email"];

        return <AlertDialog email_paciente={email} />;
      },
    },
    // Re-send sign up confirmation code
    {
      field: "action2",
      headerName: "Código de confirmação",
      sortable: false,
      width: 220,
      renderCell: (params) => {
        var filtered = patients.filter((pat) => {
          return pat.patient_id == params.id;
        });
        let email = filtered[0]["email"];

        return <AlertDialogResendCode email_paciente={email} />;
      },
    },
  ];
  return [rows, columns, rows_nao_vinculados, columns_nao_vinculados];
}

export async function prepareData(patients, classes, history) {
  const rows = [];
  try {
    patients.sort((a, b) =>
      a.full_name.toUpperCase() > b.full_name.toUpperCase()
        ? 1
        : b.full_name.toUpperCase() > a.full_name.toUpperCase()
        ? -1
        : 0
    );
  } catch {}

  for (var i = 0; i < patients.length; i++) {
    rows.push({
      id: patients[i].patient_id,
      full_name: patients[i].full_name,
      personal_phone: patients[i].personal_phone,
      birth_date:
        patients[i].birth_date.split("T")[0].split("-")[2] +
        "/" +
        patients[i].birth_date.split("T")[0].split("-")[1] +
        "/" +
        patients[i].birth_date.split("T")[0].split("-")[0],
      google_fit_status: patients[i].google_token ? "Ativo" : "Inativo",
      email: patients[i].email,
    });
  }

  const columns = [
    {
      field: "full_name",
      headerName: "Nome Completo",
      sortable: true,
      width: 280,
    },
    {
      field: "personal_phone",
      headerName: "Contato",
      sortable: true,
      width: 140,
    },
    {
      field: "birth_date",
      headerName: "Data de Nascimento",
      sortable: true,
      width: 210,
    },
    {
      field: "google_fit_status",
      headerName: "Status da Conta",
      sortable: true,
      width: 180,
      cellClassName: (params) => {
        return clsx(classes, {
          [classes.approved]: params.value.split(" ")[0] == `Ativo`,
          [classes.eliminated]: params.value.split(" ")[0] == `Inativo`,
        });
      },
    },
    {
      field: "details",
      width: 140,
      headerName: "Detalhes",
      renderCell: (params) => {
        return (
          <Button onClick={() => history.push("patient/" + params.row.email)}>
            <DoubleArrowIcon
              style={{
                cursor: "pointer",
                color: "#5c57f2",
                margin: 5,
                textAlign: "center",
              }}
            />
          </Button>
        );
      },
    },
  ];

  return [rows, columns];
}
