export const acq = [
    {
        question : "1. Em média, durante os últimos sete dias, o quão frequentemente você se acordou por causa de sua asma, durante a noite?",
        id : "q1",
        options : [
            "0. Nunca",
            "1. Quase nunca",
            "2. Poucas vezes",
            "3. Várias vezes",
            "4. Muitas vezes",
            "5. Muitíssimas vezes",
            "6. Incapaz de dormir devido a asma"
        ]
    },
    {
        question : "2. Em média, durante os últimos sete dias, o quão ruim foram os seus sintomas da asma, quando você acordou pela manhã?",
        id : "q2",
        options : [
            "0. Sem sintomas",
            "1. Sintomas muito leves",
            "2. Sintomas leves",
            "3. Sintomas moderados",
            "4. Sintomas um tanto graves",
            "5. Sintomas graves",
            "6. Sintomas muito graves"
        ]
    },
    {
        question : "3. De um modo geral, durante os últimos sete dias, o quão limitado você tem estado em suas atividades por causa de sua asma?",
        id : "q3",
        options : [
            "0. Nada limitado",
            "1. Muito pouco limitado",
            "2. Pouco limitado",
            "3. Moderadamente limitado",
            "4. Muito limitado",
            "5. Extremamente limitado",
            "6. Totalmente limitado"
        ]
    },
    {
        question : "4. De um modo geral, durante os últimos sete dias, o quanto de falta de ar você teve por causa de sua asma?",
        id : "q4",
        options : [
            "0. Nenhuma",
            "1. Muito pouca",
            "2. Alguma",
            "3. Moderada",
            "4. Bastante",
            "5. Muita",
            "6. Muitíssima"
        ]
    },
    {
        question : "5. De um modo geral, durante os últimos sete dias, quanto tempo você teve chiado?",
        id : "q5",
        options : [
            "0. Nunca",
            "1. Quase nunca",
            "2. Pouco tempo",
            "3. Algum tempo",
            "4. Bastante tempo",
            "5. Quase sempre",
            "6. Sempre"
        ]
    },
    {
        question : "6. Em média, durante os últimos sete dias, quantos jatos de medicação de resgate você usou por dia?",
        id : "q6",
        options : [
            "0. Nenhum",
            "1. De 1 a 2 jatos na maior parte dos dias",
            "2. De 3 a 4 jatos na maior parte dos dias",
            "3. De 5 a 8 jatos na maior parte dos dias",
            "4. De 9 a 12 jatos na maior parte dos dias",
            "5. De 13 a 16 jatos na maior parte dos dias",
            "6. Mais de 16 jatos por dia"
        ]
    },
    {
        question : "7. Se você tem prova de função pulmonar (valor do VEF1 em %), escolher uma opção de 0 a 6. Se não tem, clicar na opção 7:",
        id : "q7",
        options : [
            "0. > 95% do previsto",
            "1. 95-90% do previsto",
            "2. 89-80% do previsto",
            "3. 79-70% do previsto",
            "4. 69-60% do previsto",
            "5. 59-50% do previsto",
            "6. < 50% do previsto",
            "7. Sem prova de função pulmonar"
        ]
    },
]

export const barriers = [
    {
        question : "1. Com que frequência você deixa de fazer exercícios físicos porque você não tem interesse:",
        id : "q1",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
    {
        question : "2. Com que frequência você deixa de fazer exercícios físicos porque você não tem tempo:",
        id : "q2",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
    {
        question : "3. Com que frequência você deixa de fazer exercícios físicos porque você não sente energia ou disposição:",
        id : "q3",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
    {
        question : "4. Com que frequência você deixa de fazer exercícios físicos porque você tem medo de sentir falta de ar:",
        id : "q4",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
    {
        question : "5. Com que frequência você deixa de fazer exercícios físicos porque você não tem companhia ou incentivo:",
        id : "q5",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
    {
        question : "6. Com que frequência você deixa de fazer exercícios físicos porque você não tem dinheiro:",
        id : "q6",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
    {
        question : "7. Com que frequência você deixa de fazer exercícios físicos porque você tem muitos afazeres:",
        id : "q7",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
    {
        question : "8. Com que frequência você deixa de fazer exercícios físicos porque você não tem um local seguro disponível:",
        id : "q8",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
    {
        question : "9. Com que frequência você deixa de fazer exercícios físicos por causa do clima:",
        id : "q9",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
    {
        question : "10. Com que frequência você deixa de fazer exercícios físicos porque você não tem equipamentos para pratica:",
        id : "q10",
        options : [
            "0. Sempre",
            "1. Quase sempre",
            "2. Às vezes",
            "3. Raramente",
            "4. Nunca"
        ]
    },
]