import Amplify, { Auth } from 'aws-amplify';

export async function forgotPassword(email, type) {

    Amplify.configure({
        Auth: {
            region: process.env.REACT_APP_REGION,
            userPoolId: type == "patient" ? process.env.REACT_APP_COGNITO_USERPOOL_ID_PAT : process.env.REACT_APP_COGNITO_USERPOOL_ID,
            userPoolWebClientId: type == "patient" ? process.env.REACT_APP_COGNITO_CLIENT_ID_PAT : process.env.REACT_APP_COGNITO_CLIENT_ID,
            mandatorySignIn: true
        },
    });

    var response, msg, err_msg;

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (email.length < 5 || !emailRegex.test(email)) {
        if (email == '') {
            err_msg = "Por favor coloque seu e-mail";
        } else {
            err_msg = "Email inválido";
        }
        return {err: true, msg: "", err_msg};
    }

    // Resetar senha
    try {
        response = await Auth.forgotPassword(email);
        msg = "Foi enviado um email para resetar sua senha"
        return {err: false, msg, err_msg: ""};
    } catch (err) {
        if (err.code === 'UserNotFoundException') {
            msg = "Usuário não existe";
        } else if (err.code === 'NotAuthorizedException') {
            msg = "Usuário desabilitado";
        } else if (err.code === 'InvalidParameterException') {
            msg = "Usuário não confirmado, verifique seu email";
        } else {
            msg = "Erro: tente novamente mais tarde";
        }
        return {err: true, msg, err_msg: ""};
    }
}
