import {
  Button,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { prepareData } from "../../controllers/patientController";
import { prepareDataAdmin } from "../../controllers/patientController";
import Menu from "../../components/menu";
import Header from "../../components/header";
import LinkPatient from "../../components/linkPatient";
import { setPatients } from "../../actions";
import CookieConsent from "../../components/cookiesConsent";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    position: "sticky",
  },
  loading: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    position: "fixed",
    top: 0,
    height: 7,
  },
  upperContent: {
    flexGrow: 1,
    marginTop: 80,
    paddingRight: 30,
    paddingLeft: 30,
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    padding: 30,
    justifyContent: "center",
  },
  add: {
    width: 240,
    textTransform: "capitalize",
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: 600,
    height: 50,
    borderRadius: 8,
  },
  paper: {
    width: "100%",
    minHeight: 800,
    padding: 20,
  },
  title: {
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: 600,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
  },
  approved: {
    color: "#1e9c3f",
    paddingLeft: 20,
    fontWeight: 600,
  },
  eliminated: {
    color: "#cc6363",
    paddingLeft: 20,
    fontWeight: 600,
  },
  patientTable: {
    height: "80%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  patientTable2: {
    height: "97%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  "@media(min-width: 968px)": {
    upperContent: {
      justifyContent: "flex-end",
    },
    contentRoot: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
}));

export function PatientsAdmin() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const patients = useSelector((state) => state.patients);
  const [linkedPatients, setLinked] = useState({ rows: [], columns: [] });
  const [loading, setLoading] = useState(true);
  const [newPatient, setNewPatient] = useState(false);

  useEffect(async () => {
    const [linkedRows, linkedColumns] = await prepareDataAdmin(
      classes,
      history
    );

    var linked = {
      rows: linkedRows,
      columns: linkedColumns,
    };

    setLinked(linked);
    setLoading(false);
  }, [patients]);

  return (
    <Grid container className={classes.contentRoot} alignContent="flex-start">
      <Header label="Pacientes" />
      {loading && <LinearProgress className={classes.loading} />}
      {newPatient ? (
        <LinkPatient
          patients={patients}
          dispatch={dispatch}
          setPatients={setPatients}
          professional_id={currentUser.professional_id}
          hide={() => setNewPatient(false)}
        />
      ) : (
        ""
      )}
      <Menu history={history} />
      <Grid
        container
        justifyContent="flex-end"
        className={classes.upperContent}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.add}
          onClick={() => setNewPatient(true)}
          disabled={loading}
        >
          Vincular novo paciente
        </Button>
      </Grid>
      <Grid container className={classes.content}>
        <Paper elevation={1} className={classes.paper}>
          <Typography className={classes.title}>Pacientes</Typography>
          <DataGrid
            className={classes.patientTable2}
            rows={linkedPatients.rows}
            columns={linkedPatients.columns}
            pageSize={15}
            rowHeight={40}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export function Patients() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const patients = useSelector((state) => state.patients);
  const [linkedPatients, setLinked] = useState({ rows: [], columns: [] });
  const [loading, setLoading] = useState(true);
  const [newPatient, setNewPatient] = useState(false);

  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const handlePatientData = async () => {
      const [rows, columns] = await prepareData(patients, classes, history);
      var prevState = { ...linkedPatients };
      prevState.rows = rows;
      prevState.columns = columns;
      setLinked({ ...prevState });
      setLoading(false);
    };

    handlePatientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients]);

  return (
    <>
      <CookieConsent />
      <Grid container className={classes.contentRoot}>
        <Header label="Pacientes" />
        {loading && <LinearProgress className={classes.loading} />}
        {newPatient ? (
          <LinkPatient
            patients={patients}
            dispatch={dispatch}
            setPatients={setPatients}
            professional_id={currentUser.professional_id}
            hide={() => setNewPatient(false)}
          />
        ) : (
          ""
        )}
        <Menu history={history} />
        <Grid container className={classes.upperContent}>
          <Button
            variant="contained"
            color="primary"
            className={classes.add}
            onClick={() => setNewPatient(true)}
            disabled={loading}
          >
            Vincular novo paciente
          </Button>
        </Grid>
        <Grid container className={classes.content}>
          <Paper elevation={1} className={classes.paper}>
            <Typography className={classes.title}>Pacientes</Typography>
            <DataGrid
              className={classes.patientTable}
              rows={linkedPatients.rows}
              columns={linkedPatients.columns}
              pageSize={20}
              rowHeight={40}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
