import {
  Button,
  Typography,
  TextField,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Grid,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";

import { forgotPassword } from "../../controllers/forgotPasswordController";
import logo from "../../assets/logo.png";
import CookieConsent from "../../components/cookiesConsent";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  content: {
    maxWidth: 500,
    paddingInline: 20,
    paddingBlock: 40,
  },
  logo: {
    height: 40,
    width: 40,
  },
  name: {
    fontSize: 30,
    fontWeight: 100,
    letterSpacing: 1,
    marginBottom: 25,
  },
  submit: {
    textTransform: "capitalize",
    width: 250,
    marginTop: 20,
    height: 45,
  },
  loading: {
    width: "100%",
    position: "fixed",
    top: 0,
    height: 7,
  },
  error: {
    fontSize: 12,
  },
  radio: {
    fontWeight: 100,
    color: "#9e9e9e",
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [type, setType] = useState("patient");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function submit() {
    setLoading(true);
    setError("");
    const result = await forgotPassword(email, type);
    if (result.msg != "") alert(result.msg);
    if (result.err) {
      if (result.err_msg != "") setError(result.err_msg);
    } else {
      history.push("/resetpassword");
    }
    setLoading(false);
  }

  return (
    <>
      <CookieConsent />
      <Grid
        container
        component="main"
        className={classes.root}
        justifyContent="center"
        alignItems="center"
      >
        {loading ? (
          <LinearProgress className={classes.loading} color="primary" />
        ) : (
          ""
        )}
        <Grid item className={classes.content} justifyContent="flex-start">
          <img src={logo} className={classes.logo} />
          <Typography className={classes.name} color="primary">
            inspir<b>ar</b>
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <FormControlLabel
                className={classes.radio}
                value="patient"
                control={<Radio />}
                label="Paciente"
              />
              <FormControlLabel
                className={classes.radio}
                value="professional"
                control={<Radio />}
                label="Profissional"
              />
            </RadioGroup>
          </FormControl>
          <form noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              color="primary"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Typography color="error" className={classes.error}>
              {error}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => submit()}
              disabled={loading}
            >
              Recuperar minha senha
            </Button>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
