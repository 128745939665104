import Amplify, { Auth, API } from 'aws-amplify';

export async function resetPassword(email, code, password, confirm_password, type) {

    Amplify.configure({
        Auth: {
          region: process.env.REACT_APP_REGION,
          userPoolId: type == "patient" ? process.env.REACT_APP_COGNITO_USERPOOL_ID_PAT : process.env.REACT_APP_COGNITO_USERPOOL_ID,
          userPoolWebClientId: type == "patient" ? process.env.REACT_APP_COGNITO_CLIENT_ID_PAT : process.env.REACT_APP_COGNITO_CLIENT_ID,
          mandatorySignIn: true
        },
        API: {
          endpoints: [
            {
              name: "webEndpoints",
              endpoint: process.env.REACT_APP_API_ENDPOINT
            }
          ]
        }       
    })  

    var response, msg, err_msg;

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    const codeRegex = /^[0-9]+$/

    if (email.length < 5 || !emailRegex.test(email)) {
        if (email == '') {
            err_msg = "Por favor coloque seu e-mail";
        } else {
            err_msg = "Email inválido";
        }
        return {err: true, msg: "", err_msg};
    }
    if (!codeRegex.test(code)) {
        if (code == '') {
            err_msg = "Por favor coloque o código";
        } else {
            err_msg = "Expressão do código é inválida";
        }       
        return {err: true, msg: "", err_msg};
    }
    if (!passwordRegex.test(password) || !passwordRegex.test(confirm_password)) {
        if (password == '') {
            err_msg = "Por favor coloque sua nova senha";
        } else if (confirm_password == '') {
            err_msg = "Por favor confirme sua nova senha";
        } else {
            err_msg = "Senha inválida: deve conter no mínimo 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial ( @ $ ! % * ? & )"
        }
        return {err: true, msg: "", err_msg}
    } 
    if (password !== confirm_password) {
        err_msg = "Senhas não conferem";
        return {err: true, msg: "", err_msg}
    } 

    // Alterar senha
    try {
        response = await Auth.forgotPasswordSubmit(email, code, password);
        msg = "Senha alterada com sucesso"
        return {err: false, msg, err_msg: ""};
    } catch (err) {
        if (err.code === 'UserNotFoundException') {
            msg = "Usuário não existe";
        } else if (err.code === 'NotAuthorizedException') {
            msg = "Usuário desabilitado"
        } else if (err.code === 'InvalidParameterException') {
            msg = "Usuário não confirmado, verifique seu email";
        } else if (err.code === 'CodeMismatchException') {
            msg = "Código inválido";
        } else if (err.code === 'ExpiredCodeException') {
            msg = "Código expirou";
        } else if (err.code === 'LimitExceededException') {
            msg = "Erro: tente novamente mais tarde";
        } else {
            msg = "Erro ao alterar senha, tente novamente";
        }
        return {err: true, msg, err_msg: ""};
    }
}
