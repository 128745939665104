import Amplify, { Auth } from "aws-amplify";

export async function confirmSignUp(email, code) {
   Amplify.configure({
      Auth: {
         region: process.env.REACT_APP_REGION,
         userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID_PAT,
         userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID_PAT,
         mandatorySignIn: true,
      },
      API: {
         endpoints: [
            {
               name: "webEndpoints",
               endpoint: process.env.REACT_APP_API_ENDPOINT,
            },
         ],
      },
   });

   var msg, err_msg;

   const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   const codeRegex = /^[0-9]+$/;

   if (email.length < 5 || !emailRegex.test(email)) {
      if (email == "") {
         err_msg = "Por favor coloque seu e-mail";
      } else {
         err_msg = "Email inválido";
      }
      return { err: true, msg: "", err_msg };
   }
   if (!codeRegex.test(code)) {
      if (code == "") {
         err_msg = "Por favor coloque o código";
      } else {
         err_msg = "Expressão do código é inválida";
      }
      return { err: true, msg: "", err_msg };
   }

   try {
      // signIn é usado para verificar e-mail
      await Auth.signIn(email, "123");
   } catch (err) {
      if (err.code === "UserNotFoundException") {
         msg = "Usuário não existe";
         return { err: true, msg, err_msg: "" };
      } else if (err.code === "PasswordResetRequiredException") {
         msg = "Necessário mudança de senha, verifique seu email";
         return { err: true, msg, err_msg: "" };
      }
   }

   // Confirmar conta
   try {
      await Auth.confirmSignUp(email, code);
      msg = "Confirmado com sucesso";
      return { err: false, msg, err_msg: "" };
   } catch (err) {
      if (err.code === "UserNotFoundException") {
         msg = "Usuário não existe";
      } else if (err.code === "NotAuthorizedException") {
         // Usuário confirmado ou esta desabilitado
         msg =
            "Usuário já está confirmado ou é possível que esteja desabilitado";
      } else if (err.code === "CodeMismatchException") {
         msg = "Código inválido";
      } else if (err.code === "ExpiredCodeException") {
         msg = "Código expirou";
      } else {
         msg = "Erro: tente novamente mais tarde";
      }
      return { err: true, msg, err_msg: "" };
   }
}

export async function resendSignUp(email) {
   Amplify.configure({
      Auth: {
         region: process.env.REACT_APP_REGION,
         userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID_PAT,
         userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID_PAT,
         mandatorySignIn: true,
      },
   });

   var response, msg, err_msg;

   const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

   if (email.length < 5 || !emailRegex.test(email)) {
      if (email == "") {
         err_msg = "Por favor coloque seu e-mail";
      } else {
         err_msg = "Email inválido";
      }
      return { err: true, msg: "", err_msg };
   }

   try {
      // signIn é usado para verificar e-mail
      response = await Auth.signIn(email, "123");
   } catch (err) {
      if (err.code === "UserNotFoundException") {
         msg = "Usuário não existe";
         return { err: true, msg, err_msg: "" };
      } else if (err.code === "PasswordResetRequiredException") {
         msg = "Necessário mudança de senha, verifique seu email";
         return { err: true, msg, err_msg: "" };
      }
   }

   try {
      response = await Auth.resendSignUp(email);
      msg =
         "Em breve você receberá em seu e-mail o novo código de confirmação de conta";
      return { err: false, msg, err_msg: "" };
   } catch (err) {
      if (err.code === "UserNotFoundException") {
         msg = "Usuário não existe";
      } else if (err.code === "InvalidParameterException") {
         msg = "Usuário já está confirmado";
      } else if (err.code === "NotAuthorizedException") {
         msg = "Usuário desabilitado";
      } else {
         msg = "Erro: tente novamente mais tarde";
      }
      return { err: true, msg, err_msg: "" };
   }
}