export const NoRegisterSVG = () => {
    return <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6455 12H20.6455" stroke="#B2B1B4" stroke-linecap="round"/>
        <path d="M13.6455 16L17.6455 16" stroke="#B2B1B4" stroke-linecap="round"/>
        <path d="M11.6455 14H19.6455" stroke="#B2B1B4" stroke-linecap="round"/>
        <path d="M11.6455 10H19.6455" stroke="#B2B1B4" stroke-linecap="round"/>
        <path d="M13.6455 8L17.6455 8" stroke="#B2B1B4" stroke-linecap="round"/>
    </svg>


}