import React from 'react';
import {Button, Grid  } from '@material-ui/core';
import logo from '../../assets/logo.png'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
      backgroundColor: '#e2e2e2',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    notfound: {
        fontFamily: 'roboto',
        fontSize: 150,
        fontWeight: 800,
        color: '#5c57f2',
        letterSpacing: 10
    },
    text: {
        fontFamily: 'roboto',
        fontSize: 20,
        fontWeight: 400,
        color: '#808080',
        letterSpacing: 4
    }
  }));
  
export default function NotFound() {

    const classes = useStyles();
    const history = useHistory()

    return (
      <Grid container component="main" className={classes.root}>
        <img src={logo} style={{marginBottom:50, width:90, height:90}}/>
        <div className={classes.notfound}>
            404
        </div>
        <div className={classes.text}>
            PÁGINA NÃO ENCONTRADA
        </div>
        <Button onClick={() => history.goBack()} variant="outlined" color="primary" style={{marginTop:60, width:100, marginBottom:50}}>
            VOLTAR
        </Button>
      </Grid>
    );
  }
