import PropTypes from "prop-types";

import { formatCPF, formatDateDDMMYYYY, formatPhoneNumber, getAge } from "../helpers";

import "./styles.css";

const PatientDataLabel = ({ title, data }) => {
  return (
    <div className="patient-data-label-container">
      <p className="patient-data-label-info-title">{title}</p>
      <p className="patient-data-label-info-txt">{data}</p>
    </div>
  );
};

const PatientInfo = ({
  email,
  phone,
  birthDate,
  weight,
  height,
  imc,
  name,
}) => {
  const age = getAge(birthDate);


  return (
    <div className="patient-info-container">
      <h2 className="patient-info-title">Informações Pessoais</h2>

      <div className="patient-info-card-container">
        <div className="patient-info-data">
          <div className="patient-info-column">
          <PatientDataLabel title={"Nome: "} data={name} />
            <PatientDataLabel title={"Email: "} data={email} />
            <PatientDataLabel title={"Idade: "} data={`${age} anos`} />
            <PatientDataLabel title={"IMC: "} data={imc} />
          </div>

          <div className="patient-info-column">
          <PatientDataLabel
              title={"Data de Nascimento: "}
              data={formatDateDDMMYYYY(birthDate)}
            />
            <PatientDataLabel title={"Telefone: "} data={formatPhoneNumber(phone)} />
            <PatientDataLabel title={"Peso: "} data={`${weight} kg`} />
            <PatientDataLabel title={"Altura: "} data={`${height} cm`} />                     
          </div>
        </div>
      </div>
    </div>
  );
};

PatientInfo.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  birthDate: PropTypes.string.isRequired,
  weight: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  imc: PropTypes.string.isRequired,
};

export default PatientInfo;
