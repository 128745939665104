import {
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Link,
  Grid,
  InputLabel,
  RadioGroup,
  FormControl,
  Radio,
  useMediaQuery,
  Hidden,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useMemo } from "react";

import { acq } from "../../assets/acq";
import login from "../../controllers/loginController";
import logo from "../../assets/logo.png";
import Menu from "../../components/menu";
import Header from "../../components/header";
import RegisterStepper from "../../components/registerStepper";
import {
  validatePersonalInfo,
  submit,
} from "../../controllers/newPatientController";
import { setPatients } from "../../actions";
import { red } from "@material-ui/core/colors";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Box from "@mui/material/Box";
import { ConsoleLogger } from "@aws-amplify/core";
import es from "date-fns/esm/locale/es/index.js";
import CookieConsent from "../../components/cookiesConsent";

const drawerWidth = 250;

const telefoneAutoCompleteOnPress = (ev) => {
  if (ev.target.value.length == 2) {
    ev.target.value += "-";
  }
};

function cpfAutoCompleteOnPress(ev) {
  if (ev.target.value.length === 3) {
    ev.target.value += ".";
  }
  if (ev.target.value.length === 7) {
    ev.target.value += ".";
  }
  if (ev.target.value.length === 11) {
    ev.target.value += "-";
  }
}
function onlyDigits(ev) {
  //Dimensiones del dispositivo
  if (document.documentElement.clientWidth >= 412) {
    const modifier_keys = [
      "Alt",
      "AltGraph",
      "Control",
      "Meta",
      "Shift",
      "CapsLock",
      "NumLock",
      "ScrollLock",
    ];
    for (const value of modifier_keys) {
      if (ev.key == value) return; // if it is modifier key
    }
    if (!isFinite(ev.key) || ev.key == " ") {
      // if key is digit
      ev.target.value = ev.target.value.substring(
        0,
        ev.target.value.length - 1
      );
    }
  } else {
    //alert('dispositivo')
    var texto = ev.target.value;
    var ultimo_caracter = texto.substr(-1);
    if (!isFinite(ultimo_caracter) || ultimo_caracter == " ") {
      // if key is digit
      ev.target.value = texto.substring(0, texto.length - 1);
      //alert(texto.substring(0,texto.length-1));
    }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  loading: {
    width: "100%",
    position: "fixed",
    top: 0,
    height: 7,
  },
  header: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: 600,
    paddingTop: 40,
  },
  content: {
    width: "100%",
    overflow: "hidden",
    backgroundColor: "white",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    backgroundColor: "#f4f4f4",
    borderColor: "#f4f4f4",
    borderRadius: 10,
    minHeight: 500,
    minWidth: 350,
    padding: "36px !important",
  },
  stepper: {
    marginTop: 50,
  },
  title: {
    letterSpacing: 1,
    fontSize: 20,
    fontWeight: 600,
    marginLeft: 15,
  },
  formTitle: {
    letterSpacing: 1,
    fontSize: 20,
    fontWeight: 400,
  },

  input: {
    height: 50,
    color: "#5c57f2",
    backgroundColor: "white",
  },
  buttons: {
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 60,
    marginBottom: 20,
  },
  button: {
    width: 150,
    height: 50,
    textTransform: "capitalize",
  },
  buttonFinal: {
    width: 200,
    height: 50,
    textTransform: "capitalize",
  },
  questions: {
    fontSize: 16,
    fontWeight: 550,
    color: "#04003c",
    marginTop: 30,
    marginLeft: 10,
    marginBottom: 20,
  },
  question: {
    fontSize: 14,
    fontWeight: 400,
    color: "#212054",
  },
  content2: {
    borderRadius: 10,
    height: 500,
    margin: "0 auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #80808042",
    backgroundColor: "#f6f6f6",
    padding: 20,
  },
  successIcon: {
    color: "#5c57f2",
    width: 50,
    height: 50,
  },
  successText: {
    color: "#4b4b4b",
    fontSize: 22,
    fontWeight: 200,
    letterSpacing: 2,
    textAlign: "center",
    fontFamily: "Arial",
  },
  button2: {
    margin: "20px 0 auto",
    width: "70%",
    marginBottom: theme.spacing(2),
  },
  terms: {
    marginLeft: 50,
    marginTop: 50,
    fontWeight: 300,
    color: "#7e7e7e",
  },
  paragrapx: {
    marginLeft: 45,
    marginRight: 50,
    fontWeight: 300,
    color: "#000000",
    margin: 1,
  },
  subtext: {
    color: "#7e7e7e",
    fontSize: 14,
    marginBottom: 32,
  },
}));

function getStep(history) {
  switch (history.location.hash) {
    case "#personal":
      return 0;
    case "#acq":
      return 1;
    // case '#goals':
    //   return 3;
    case "#success":
      return 3;
    default:
      history.push("/register#personal");
      return 0;
  }
}

export default function NewPatient() {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useSelector((state) => state.currentUser);

  const [loading, setLoading] = useState(false);

  const [personalInfo, setPersonalInfo] = useState({
    full_name: "",
    cpf: "",
    email: "",
    personal_phone: "",
    birth_date: "",
    height: "",
    weight: "",
  });

  const initError = {
    full_name: false,
    cpf: false,
    email: false,
    personal_phone: false,
    birth_date: false,
    height: false,
    weight: false,
  };

  const [personalInfoErrors, setPersonalInfoErrors] = useState(initError);
  const [acqAnswwers, setACQ] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
  });
  const [barriersAnswers, setBarriers] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
    q9: "",
    q10: "",
  });
  const [goals, setGoals] = useState({
    steps_per_day: 500,
    km_per_week: 1,
    exercises: "N/A",
    respiratory: "N/A",
    observations: "N/A",
  });
  const acqCompleted = useMemo(() => {
    for (const key in acqAnswwers) {
      if (acqAnswwers[key].trim() === "") {
        return false;
      }
    }
    return true;
  }, [acqAnswwers]);

  async function handlePersonal() {
    setLoading(true);
    const err = await validatePersonalInfo(personalInfo, personalInfoErrors);
    if (!err) {
      setPersonalInfoErrors(initError);
      history.push("/register#acq");
    } else {
      setPersonalInfoErrors(err);
    }
    setLoading(false);
  }

  async function createPatient() {
    setLoading(true);
    const res = await submit(
      personalInfo,
      acqAnswwers,
      barriersAnswers,
      goals,
      currentUser
    );
    if (res.err == false) {
      setLoading(false);
      return history.push("/register#success");
    }
    alert(res.msg);
    setLoading(false);
  }

  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  function renderPersonal() {
    return (
      <Grid item xs={12} sm={10} md={8} lg={6} className={classes.form}>
        <Typography color="primary" className={classes.formTitle}>
          Dados Pessoais
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="full_name"
              label="Nome completo"
              id="full_name"
              color="primary"
              InputProps={{ className: classes.input }}
              value={personalInfo.full_name}
              onChange={(e) => {
                const prevInfo = { ...personalInfo };
                prevInfo[e.target.id] = e.target.value;
                setPersonalInfo(prevInfo);
              }}
              size="small"
              helperText={personalInfoErrors.full_name}
              error={!!personalInfoErrors.full_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="cpf"
              label="CPF"
              id="cpf"
              color="primary"
              InputProps={{ className: classes.input }}
              value={personalInfo.cpf}
              onChange={(e) => {
                if (document.documentElement.clientWidth >= 412) {
                  const prevInfo = { ...personalInfo };
                  prevInfo[e.target.id] = e.target.value;
                  setPersonalInfo(prevInfo);
                } else {
                  onlyDigits(e);
                  const prevInfo = { ...personalInfo };
                  cpfAutoCompleteOnPress(e);
                  prevInfo[e.target.id] = e.target.value;
                  setPersonalInfo(prevInfo);
                }
              }}
              size="small"
              helperText={personalInfoErrors.cpf}
              error={!!personalInfoErrors.cpf}
              inputProps={{ maxLength: 14 }}
              onKeyPress={(ev) => {
                if (document.documentElement.clientWidth >= 412) {
                  cpfAutoCompleteOnPress(ev);
                }
              }}
              onKeyUp={(ev) => {
                if (document.documentElement.clientWidth >= 412) {
                  onlyDigits(ev);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label="E-mail"
              id="email"
              color="primary"
              InputProps={{ className: classes.input }}
              value={personalInfo.email}
              onChange={(e) => {
                const prevInfo = { ...personalInfo };
                prevInfo[e.target.id] = e.target.value;
                setPersonalInfo(prevInfo);
              }}
              size="small"
              helperText={personalInfoErrors.email}
              error={!!personalInfoErrors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="personal_phone"
              label="Telefone"
              id="personal_phone"
              color="primary"
              InputProps={{ className: classes.input }}
              value={personalInfo.personal_phone}
              onChange={(e) => {
                if (document.documentElement.clientWidth >= 412) {
                  const prevInfo = { ...personalInfo };
                  prevInfo[e.target.id] = e.target.value;
                  setPersonalInfo(prevInfo);
                } else {
                  onlyDigits(e);
                  const prevInfo = { ...personalInfo };
                  telefoneAutoCompleteOnPress(e);
                  prevInfo[e.target.id] = e.target.value;
                  setPersonalInfo(prevInfo);
                }
              }}
              size="small"
              helperText={personalInfoErrors.personal_phone}
              error={!!personalInfoErrors.personal_phone}
              inputProps={{ maxLength: 12 }}
              //PRESIONAR ENTER
              onKeyPress={(ev) => {
                if (document.documentElement.clientWidth >= 412) {
                  telefoneAutoCompleteOnPress(ev);
                }
              }}
              onKeyUp={(ev) => {
                if (document.documentElement.clientWidth >= 412) {
                  onlyDigits(ev);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              type="date"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="birth_date"
              label="Data de nascimento"
              id="birth_date"
              color="primary"
              InputProps={{
                className: classes.input,
              }}
              value={personalInfo.birth_date}
              onChange={(e) => {
                const prevInfo = { ...personalInfo };
                prevInfo[e.target.id] = e.target.value;
                setPersonalInfo(prevInfo);
              }}
              size="small"
              helperText={personalInfoErrors.birth_date}
              error={!!personalInfoErrors.birth_date}
              placeholder="dd/mm/yyyy"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="weight"
              label="Peso (kg)"
              id="weight"
              color="primary"
              type="number"
              InputProps={{ className: classes.input }}
              value={personalInfo.weight}
              onChange={(e) => {
                const prevInfo = { ...personalInfo };
                prevInfo[e.target.id] = e.target.value;
                setPersonalInfo(prevInfo);
              }}
              size="small"
              helperText={personalInfoErrors.weight}
              error={!!personalInfoErrors.weight}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="height"
              label="Altura (cm)"
              id="height"
              color="primary"
              type="number"
              InputProps={{ className: classes.input }}
              value={personalInfo.height}
              onChange={(e) => {
                const prevInfo = { ...personalInfo };
                prevInfo[e.target.id] = e.target.value;
                setPersonalInfo(prevInfo);
              }}
              size="small"
              helperText={personalInfoErrors.height}
              error={!!personalInfoErrors.height}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              id="password"
              color="primary"
              type={showPassword ? "text" : "password"}
              value={personalInfo.password}
              onChange={(e) => {
                const prevInfo = { ...personalInfo };
                prevInfo[e.target.id] = e.target.value;
                setPersonalInfo(prevInfo);
              }}
              size="small"
              helperText={personalInfoErrors.password}
              error={!!personalInfoErrors.password}
              InputProps={{
                // <-- This is where the toggle button is added.
                className: classes.input,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="passwordConfirmation"
              label="Confirme sua senha"
              id="passwordConfirmation"
              color="primary"
              type={showPassword2 ? "text" : "password"}
              value={personalInfo.passwordConfirmation}
              onChange={(e) => {
                const prevInfo = { ...personalInfo };
                prevInfo[e.target.id] = e.target.value;
                setPersonalInfo(prevInfo);
              }}
              size="small"
              helperText={personalInfoErrors.passwordConfirmation}
              error={!!personalInfoErrors.passwordConfirmation}
              InputProps={{
                // <-- This is where the toggle button is added.
                className: classes.input,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Typography className={classes.subtext}>
          * Senha deve conter no mínimo 8 caracteres, uma letra maiúscula, uma
          letra minúscula, um número e um caractere especial ({" "}
          <strong> @ $ ! % * ? & </strong> )
        </Typography>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="white"
              className={classes.button}
              onClick={() => history.push("/")}
              disabled={loading}
            >
              Cancelar
            </Button>
          </Grid>

          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => handlePersonal()}
              disabled={loading}
            >
              Próximo
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderACQ() {
    return (
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={6}
        spacing={4}
        className={classes.form}
      >
        <Typography color="primary" className={classes.formTitle}>
          Questionário para Controle da Asma (ACQ)
        </Typography>
        {acq.map((question) => {
          return (
            <Grid container spacing={2} direction="column">
              <Typography className={classes.questions}>
                {question.question}
              </Typography>
              <FormControl component="fieldset" style={{ marginLeft: 10 }}>
                <RadioGroup
                  aria-label={question.id}
                  name={question.id}
                  value={acqAnswwers[question.id]}
                  onChange={(e) => {
                    const prevAns = { ...acqAnswwers };
                    prevAns[e.target.name] = e.target.value;
                    setACQ(prevAns);
                  }}
                >
                  {question.options.map((option) => {
                    return (
                      <FormControlLabel
                        value={option}
                        control={<Radio />}
                        label={option}
                        classes={{ label: classes.question }}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          );
        })}

        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          style={{ marginTop: 36 }}
        >
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="white"
              className={classes.button}
              onClick={() => history.push("/register#personal")}
              disabled={loading}
            >
              Voltar
            </Button>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => createPatient()}
              disabled={loading || !acqCompleted}
            >
              Finalizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderSuccess() {
    return (
      <Grid
        className={classes.content2}
        justifyContent="center"
        alignContent="center"
      >
        <CheckCircleOutlineIcon className={classes.successIcon} />

        <Typography className={classes.successText}>
          <strong>Cadastro realizado com sucesso</strong>
          <br></br>
        </Typography>
        <Typography className={classes.successText}>
          Parabéns por monitorar sua saúde respiratória através do Inspir
          <strong>ar</strong>! <br />
          Em breve você receberá em seu e-mail o código de confirmação de conta
        </Typography>

        <Button
          onClick={() => {
            /*https://drive.google.com/uc?export=download&id=1EjVHDrsAm0csNUyN16Ej_-fidhLNvdt2*/
            window.open(
              "https://play.google.com/store/apps/details?id=br.app.inspirar",
              "_self"
            );
          }}
          variant={"contained"}
          color="primary"
          className={classes.button2}
        >
          Download do aplicativo
        </Button>

        <Button
          onClick={() => {
            window.open("https://www.inspirar.app.br/", "_self");
          }}
          variant={"contained"}
          color="#808080"
          className={classes.button2}
        >
          Voltar para home
        </Button>
      </Grid>
    );
  }

  return (
    <>
      <CookieConsent />
      <Grid
        container
        className={classes.root}
        justifyContent="center"
        alignContent="center"
      >
        {loading ? (
          <LinearProgress className={classes.loading} color="primary" />
        ) : (
          ""
        )}
        <Typography className={classes.header}>
          Cadastro de Pacientes
        </Typography>
        <Grid
          container
          className={classes.content}
          spacing={2}
          alignItems="center"
          style={{ flexDirection: "column" }}
        >
          {getStep(history) != 3 ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              className={classes.stepper}
            >
              <RegisterStepper activeStep={getStep(history)} />
            </Grid>
          ) : (
            ""
          )}

          {history.location.hash === "#personal" && renderPersonal()}
          {history.location.hash === "#acq" && renderACQ()}
          {/* {history.location.hash === "#goals" && renderGoals()} */}
        </Grid>

        {history.location.hash == "#success" ? renderSuccess() : ""}
      </Grid>
    </>
  );
}
