import { Auth } from 'aws-amplify';

export default async function logout(history) {

    try {
        await Auth.signOut({ global: true });
        return history.push('/login');
    } catch (error) {
        return alert('error signing out: '+ error.message);
    }

}