import Amplify, { Auth, API } from 'aws-amplify';

export async function validatePersonalInfo(personalInfo, personalInfoErrors) {

    Amplify.configure({
        Auth: {
            region: process.env.REACT_APP_REGION,
            userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
            userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            mandatorySignIn: true
        },
        API: {
            endpoints: [
                {
                name: "webEndpoints",
                endpoint: process.env.REACT_APP_API_ENDPOINT
                }
            ]
        }
        
    });

    //Antiguo /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/ 
    //Nuevo /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/ 
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const birthRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    function parseDate(_input){
        // input yyyy-mm-dd
        // output dd/mm/yyyy
        let strArray = _input.split("-")
        let strOutput = ""
        for(let val of strArray){
          strOutput = val+'/'+strOutput
        }
        return strOutput.substring(0,strOutput.length-1)
    }
    
    function validPhone(input) {
        // extract no digits characters
        input = input.replace(/\D/g,'');
        if (input.length == 11) {
            personalInfo.personal_phone = input
            return true
        } else {
            return false
        }
    }

    function validCFP(input) {
        // extract no digits characters
        input = input.replace(/\D/g,'');
        var typeInput = typeof input.length;
        if (input.length == 11) {
            personalInfo.cpf = input
            return true
        } else {
            return false
        }
    }

    const err = {
        full_name: "",
        cpf: "",
        email: "",
        personal_phone: "",
        birth_date: "",
        height: "",
        weight: ""
    };

    var hasErr =  false;

    if (personalInfo.full_name.length < 5) {
        err.full_name = "Nome muito curto!";
        hasErr = true;
    }
    if (!validCFP(personalInfo.cpf)) {
        err.cpf = "CPF inválido! Utilize apenas números.";
        hasErr = true;
    }
    if (personalInfo.email.length < 5 || !emailRegex.test(personalInfo.email)) {
        err.email = "Email inválido!";
        hasErr = true;
    }
    if (!validPhone(personalInfo.personal_phone)) {
        err.personal_phone = "Telefone inválido!";
        hasErr = true;
    }
    if (personalInfo.birth_date.length < 10 || !birthRegex.test(personalInfo.birth_date)) {
        //console.log(`date enter ${personalInfo.birth_date}`);
        err.birth_date = "Data de nascimento inválida! Verifique que ela segue o formato.";
        hasErr = true;
    } else {
        // change format to send in correct no problem format
        personalInfo.birth_date = parseDate(personalInfo.birth_date)
        //console.log(`parsed date ${personalInfo.birth_date}`);
    }
    /*if (!passwordRegex.test(personalInfo.password)) {
        err.password = `A sua senha deve conter:
                        Pelo menos oito caracteres;
                        Letras maiúsculas e minúsculas;
                        Pelo menos um número;
                        Pelo menos um caractere especial`
        hasErr = true;
    }

    if (personalInfo.password !== personalInfo.passwordConfirmation) {
        err.passwordConfirmation = "As senhas não são as mesmas!";
        hasErr = true;
    }*/

    let myInit = { 
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    }
    
    await API.get("webEndpoints", '/professionals/' + personalInfo.email, myInit).then(async response => {
        err.email = true;
        hasErr = true;
        alert('Email já cadastrado!')
    }).catch(error => {
    });
    if (hasErr) {
        return err;
    }
    
    return false;

}

export async function submit(personal, professional) {

    var err, msg;

    const body = {
        ...personal,
        type : "professional"
    }

    Amplify.configure({
        Auth: {
            region: process.env.REACT_APP_REGION,
            userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
            userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            mandatorySignIn: true
        },
        API: {
            endpoints: [
                {
                name: "webEndpoints",
                endpoint: process.env.REACT_APP_API_ENDPOINT
                }
            ]
        }
        
    });
    
    await Auth.signUp({
        username: personal.email,
        password: process.env.REACT_APP_DEFAULT_PASSWORD,
        email: personal.email
    })
    .then(data => {
        console.log('ok');
    })
    .catch(err => {
        err = true
        msg = 'Erro ao cadastrar usuário, tente novamente mais tarde'
        return { err,msg }
    });

    if (err) return {err,msg}

    let myInit = { 
        // headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
        body: body
    }
    
    var data;

    await API.post("webEndpoints", '/professionals/create', myInit).then(async response => {
        err = false
        msg = 'Sucesso'
        data = response
    }).catch(error => {
        console.error(JSON.stringify(error))
        err = true
        msg = 'Erro ao criar paciente no banco de dados, tente novamente mais tarde'
    });
    return {err, msg, data}

}