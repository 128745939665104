export const buildChartConfigs = (labels) => {
  const symptomNameMap = {
    tosse: "Tosse",
    chiado: "Chiado",
    "falta-ar": "Falta de ar",
    insonia: "Falta de ar ao acordar",
    bombinha: "Uso da bombinha",
    fadiga: "Fadiga",
  };

  const options = {
    chart: {
      type: "bar",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        colors: {
          ranges: [
            {
              from: 3.99,
              to: 3.99,
              color: "#F0EFFF",
            },
          ],
        },
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          colors: "#7E7E82",
          fontSize: 12,
          fontFamily: "Arial, sans-serif",
          fontWeight: 400,
        },
      }
    },
    yaxis: {
      min: 0,
      max: 4,
      tickAmount: 4,
      labels: {
        formatter: (val) => Math.floor(val),
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        const title = symptomNameMap[w.globals.initialSeries[seriesIndex].name];

        if (data === 3.99) {
          return `<div class="apexcharts-tooltip-rangebar">
                    <span>Sem registro</span>
                  </div>`;
        }

        return `<div class="apexcharts-tooltip-rangebar">
                  <span>${title}</span><br>
                  <span>${data}</span><br>
                </div>`;
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ["#40987C", "#FEA9DD", "#7E7BFF", "#FFBF1C", "#FB875A", "#93CBFF"],
  };

  return options;
};

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split("-");
  const date = new Date(year, month - 1, day); // Cria a data ajustando o mês (0-11)

  const months = [
    "jan",
    "fev",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez",
  ];

  const formattedDay = date.getDate().toString().padStart(2, "0");
  const formattedMonth = months[date.getMonth()];

  return `${formattedDay}/${formattedMonth}`;
};

export const transformDataForApexCharts = (data) => {
  if (data.datasets) {
    const series = data.datasets.map((dataset) => ({
      name: dataset.label,
      data: dataset.values.map((value) => (value === null ? 3.99 : value)),
    }));

    const categories = data.labels.map((value) => formatDate(value));

    return { series, categories };
  }

  return {
    series: [],
    categories: [],
  };
};
