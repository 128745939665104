import Modal from "react-modal";
import { useEffect, useMemo, useState } from "react";
import { LuCalendarDays } from "react-icons/lu";
import { IoMdCloseCircle } from "react-icons/io";
import ReactApexChart from "react-apexcharts";
import DatePicker, { registerLocale } from "react-datepicker";

import PropTypes from "prop-types";
import { subDays } from "date-fns";
import { ptBR } from "date-fns/locale";

import { buildChartConfigs, convertData, createChartColors } from "./helpers";

import {
  formatDateDDMMYYYY,
  formatDateYYYYMMDD,
  parseFilterDateString,
} from "../helpers";

import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { formatDate } from "../DailySymptomsChartBySymptom/helpers";

Modal.setAppElement("#root");

registerLocale("pt-BR", ptBR);

const ACQchart = ({ acqChartData, filterDate, setFilterDate }) => {
  const today = new Date();
  const filterDateISO = parseFilterDateString(filterDate);
  const startDate = subDays(filterDateISO, 30);

  const chartData = convertData(acqChartData);
  const chartColors = createChartColors(acqChartData);
  const chartXdata = acqChartData?.labels?.map((item) => formatDate(item));
  
  const options = buildChartConfigs(chartColors, filterDate, chartXdata);
  const [chartSize, setChartSize] = useState({
    chartWidth: window.innerWidth >= 1200 ? "100%" : "950px",
    chartHeight: "150px",
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    startDate,
    filterDateISO,
  ]);

  const datePickerRange = useMemo(() => {
    return [startDate, filterDateISO];
  }, [filterDateISO, startDate]);

  const datesDisplay = useMemo(() => {
    return [formatDateDDMMYYYY(startDate), formatDateDDMMYYYY(filterDate)];
  }, [filterDate, startDate]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const onSelectDate = () => {
    setFilterDate(formatDateYYYYMMDD(selectedDates[1]));
    setModalIsOpen(false);
  };

  const handleChange = (date) => {
    const endDate = date;
    const startDate = subDays(date, 29);
    setSelectedDates([startDate, endDate]);
  };

  useEffect(() => {
    const handleResize = () => {
      setChartSize({
        chartHeight: "100%",
        chartWidth: window.innerWidth >= 1200 ? "100%" : "950px",
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="acq-chart-container">
      <div className="daily-symptoms-chart-header-container">
        <h2 className="daily-symptoms-chart-header-title">
          Histórico mensal de controle de asma
        </h2>

        <div className="chart-filter-selector" onClick={openModal}>
          <p>
            {datesDisplay[0]} {""} - {""}
            {datesDisplay[1]}
          </p>

          <LuCalendarDays className="chart-filter-icon" />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)", // Define o fundo escurecido
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "8px",
            },
          }}
          contentLabel="Select Date"
        >
          <div className="date-picker-modal-header">
            <p className="date-picker-modal-title">Histórico mensal ACQ</p>
            <IoMdCloseCircle
              className="date-picker-modal-icon"
              color="#5c57f2"
              onClick={() => setModalIsOpen(false)}
            />
          </div>

          <DatePicker
            selected={datePickerRange[1]}
            onChange={handleChange}
            startDate={datePickerRange[0]}
            endDate={datePickerRange[1]}
            selectsEnd
            inline
            locale="pt-BR"
            calendarClassName="custom-datepicker"
            maxDate={today}
          />
          <button className="date-picker-modal-btn" onClick={onSelectDate}>
            Confirmar
          </button>
        </Modal>
      </div>

      <div className="acq-chart-wrapper">
        {chartData?.length > 0 && (
          <ReactApexChart
            options={options}
            series={chartData}
            type="rangeBar"
            width={chartSize.chartWidth}
            height={chartSize.chartHeight}
          />
        )}
      </div>

      <div className="custom-legend-container">
        <div className="custom-legend-item-container">
          <div
            style={{
              backgroundColor: "#4FBDB8",
              height: 12,
              width: 12,
              borderRadius: 2,
            }}
          />
          <span className="custom-legend-text">{`Totalmente controlada (score < 0.75)`}</span>
        </div>
        <div className="custom-legend-item-container">
          <div
            style={{
              backgroundColor: "#EAAB08",
              height: 12,
              width: 12,
              borderRadius: 2,
            }}
          />
          <span className="custom-legend-text">{`Parcialmente controlada (score = 0.75 a 1.5)`}</span>
        </div>
        <div className="custom-legend-item-container">
          <div
            style={{
              backgroundColor: "#EF4444",
              height: 12,
              width: 12,
              borderRadius: 2,
            }}
          />
          <span className="custom-legend-text">{`Não controlada (score > 1.5)`}</span>
        </div>
      </div>
    </div>
  );
};

ACQchart.propTypes = {
  acqChartData: PropTypes.object.isRequired,
  setFilterDate: PropTypes.func.isRequired,
  filterDate: PropTypes.string.isRequired,
};

export default ACQchart;
