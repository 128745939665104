import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export const buildChartConfigs = (chartColors, filterDate, chartXdata) => {
  const options = {
    chart: {
      height: 350,
      type: "rangeBar",
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "10%",
        rangeBarGroupRows: true,
      },
    },
    xaxis: {
      type: "datetime",
      max: new Date(filterDate).getTime() +  24 * 60 * 60 * 1000,
      min: new Date(filterDate) - 720 * 60 * 60 * 1000,
      tickAmount: 31,
      labels: {
        formatter: function (value) {
          const date = new Date(value - 24 * 60 * 60 * 1000);
          const day = String(date.getUTCDate()).padStart(2, "0");
        
          const month = format(date, "MMM", { locale: ptBR }).toLowerCase();

          if(day === "01") {
            return `${day}/${monthsDictionary[date.getMonth() + 1]}`;
          }

          return `${day}/${month}`;
        },
        rotateAlways: true,
        minHeight: 45,
        style: {
          colors: "#7E7E82",
          fontSize: 12,
          fontFamily: "Arial, sans-serif",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      show: false,
    },
    colors: chartColors,
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        const startDate = new Date(
          new Date(data.y[0]).getTime() 
        ).toLocaleDateString("pt-BR");

        const endDate = new Date(
          new Date(data.y[1]).getTime() 
        ).toLocaleDateString("pt-BR");

        return `<div class="apexcharts-tooltip-rangebar">
                  <span>${data.x}</span><br>
                  <span>Score: ${data.score}</span><br>
                  <span>${startDate} - ${endDate}</span>
                </div>`;
      },
    },
  };

  return options;
};

const monthsDictionary = {
  0: 'jan',
  1: 'fev',
  2: 'mar',
  3: 'abr',
  4: 'mai',
  5: 'jun',
  6: 'jul',
  7: 'ago',
  8: 'set',
  9: 'out',
  10: 'nov',
  11: 'dez'
};

const statusDictionary = {
  Uncontrolled: "Não controlada",
  PartiallyControlled: "Parcialmente controlada",
  Controlled: "Totalmente controlada",
  null: "Sem registro",
};

const getColorBasedOnScore = (score) => {
  if (score > 1.5) {
    return "#EF4444";
  } else if (score >= 0.75) {
    return "#EAAB08";
  } else if (score < 0.75 && score !== null) {
    return "#4FBDB8";
  } else {
    return "#F0EFFF";
  }
};

// Função para converter os dados
export const convertData = (data) => {
  return data?.datasets?.map((item) => {
    const name = statusDictionary[item.status];
    const startDate = new Date(item.startDate).getTime() + 24 * 60 * 60 * 1000;
    const endDate = new Date(item.endDate).getTime() + 24 * 60 * 60 * 1000;

    return {
      name: name,
      data: [
        {
          x: "ACQ",
          y: [startDate, endDate],
          score: item.score !== null ? item.score.toFixed(2) : "Não registrado",
        },
      ],
    };
  });
};

export const createChartColors = (data) => {
  return data?.datasets?.map((item) => {
    const hexaColor = getColorBasedOnScore(item.score);
    return hexaColor;
  });
};

export function getMonthFromString(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("pt-BR", { month: "long" });
}

export function getMonthFromFullString(fullDateString) {
  const date = new Date(fullDateString);
  return date.toLocaleDateString("pt-BR", { month: "long" });
}
