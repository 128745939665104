import { Button, Typography, Link, Grid, InputLabel, Paper, Card } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';

import { prepareData } from '../../controllers/patientController'
import login from '../../controllers/loginController'
import logo from '../../assets/logo.png'
import Menu from '../../components/menu'
import Header from '../../components/header'

import logo_inspirar from '../../assets/logo-inspirar.png'

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start"
  });
};

const useStyles = makeStyles((theme) => ({
  top: {
    /*position: 'sticky',
    top: '0',
    left: '0',
    right: '0',
    zIndex: '1',*/
    height: '20vh',
    widht: '100vw',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  footer: {
    height: '20vh',
    widht: '100vw',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  root: {
    height: '100vh',
    widht: '100vw',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  tab_to_right: {
    height: 'auto',
    widht: '100vw',
    paddingTop: '5vh',//'20vh'
    paddingLeft: '4vw',// '10vw'
    paddingRight: '4vw',//'4vw'
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  tab_to_left: {
    height: 'auto',
    widht: '100vw',
    paddingTop: '20vh',
    paddingLeft: '4vw',
    paddingRight: '10vw',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  inside_tab_light: {
    backgroundColor: '#9f88f2',
  },
  inside_tab_ultralight: {
    backgroundColor: 'rgba(211, 205, 250, 0.47)',// '#bcbbf2'
  },
  textTitleLight: {
    fontFamily: 'roboto',
    fontSize: 35,
    fontWeight: 800,
    color: '#18191F',
    marginTop: '2vh',
    marginBottom: '4vh',
    letterSpacing: 8
  },
  textLight: {
    fontFamily: 'roboto',
    fontSize: 20,
    fontWeight: 400,
    color: '#18191F',
    letterSpacing: 3
  },
  textTitleUltraLight: {
    fontFamily: 'roboto',
    fontSize: 35,
    fontWeight: 800,
    color: '#18191F',
    marginTop: '2vh',
    marginBottom: '4vh',
    letterSpacing: 8
  },
  textUltraLight: {
    fontFamily: 'roboto',
    fontSize: 20,
    fontWeight: 400,
    color: '#18191F',
    letterSpacing: 3
  },
  textFooter: {
    fontFamily: 'roboto',
    fontSize: 20,
    fontWeight: 400,
    color: '#808080',
    marginLeft: '2vw',
    letterSpacing: 0.4,
    position: 'relative',
    top: -40
  },
  mainContent: {
    width: '100%',
    height: '100%',
  },
  columnContent: {
    height: 'auto',
    paddingTop: '9vh',
    paddingBottom: '9vh',
    paddingLeft: '3vw',
    paddingRight: '3vw'
  },
  columnContentHeader: {
    height: 'auto',
    paddingTop: '6vh',//'12vh'
    paddingBottom: '5vh',//'0vh'
    paddingLeft: '4vw',//'3vw'
    paddingRight: '4vw'//'3vw'
  },
  columnContentFooter: {
    height: 'auto',
    paddingTop: '6vh',//
    paddingBottom: '0vh',
    paddingLeft: '3vw',
    paddingRight: '3vw',
    top: 5,
  },
  name: {
    fontSize: 60,
    fontWeight: 100,
    letterSpacing: 1,
    marginBottom: '10vh',
    marginLeft: '1.8vw'
  },
  skip: {
    backgroundColor: '#ffffff',
    height: '0vh'//'10vh'
  },
  bigSkip: {
    backgroundColor: '#ffffff',
    height: '20vh'
  }, 
  footerButton: {
    marginTop: '1vh', 
    height: '12vh', 
    marginLeft: '0.5vw', 
    width: '35vw',
    minWidth: '35vw',
    [theme.breakpoints.down(800)]: {
      marginLeft: '1vw', 
    },
    [theme.breakpoints.down(1200)]: {
      height: '15vh', 
      marginLeft: '3vw', 
      width: '16vw',
    },
  },
  footerStyle:{
    backgroundColor: 'black',
    padding: theme.spacing(2),
    bottom: 0,
    width: '100%',
  },
  textStyle:{
    fontFamily: 'roboto',
    fontSize: 20,
    fontWeight: 400,
    color: '#18191F',
    letterSpacing: 1,
    overflowWrap: 'break-word',
    minWidth: 260
  }
}));

export default function PrivacyPolicy() {

  const classes = useStyles();
  const history = useHistory();

  const headerRef = useRef(null);
  const buttonOneRef = useRef(null);

  return (
    <Grid>
      <Grid container component="top" className={classes.top} ref={headerRef} >
        <Grid container item={true} md={12} xl={12} sm={12} className={classes.columnContentHeader} direction="row" justifyContent="left">
          <img src={logo_inspirar} style={{ 'margin': '1rem 0' }} />
        </Grid>
      </Grid>
      <Grid container component="main1" className={classes.tab_to_right} ref={buttonOneRef}>
        <Grid container className={classes.inside_tab_ultralight}>
          <Grid container item={true} md={12} xl={12} sm={12} className={classes.columnContent} direction="column" justifyContent="left">
            <div className={classes.textTitleUltraLight}>
              <b>Política de Privacidade e Uso de Cookies</b>
            </div>
            <div className={classes.textStyle}>
              <div >{/**align="justify" */}
                <p><strong>1.  	Coleta de dados pessoais</strong></p>
                  <p>A coleta de dados pessoais é necessária para que o aplicativo inspir<b>ar</b> ofereça serviços e funcionalidades adequados às necessidades dos usuários, bem como para personalizar serviços, fazendo com que sua experiência seja a mais cômoda e satisfatória possível.</p>
                  <p>Os dados pessoais serão solicitados por meio de um profissional de saúde ou diretamente ao usuário, de forma que cabe ao usuário consentir com seu fornecimento e veracidade. Eles são fundamentais para dar sequência ao cadastro e utilização do aplicativo, assim como para o potencial acompanhamento médico posterior.</p>
                <p><strong>2.  	Utilização de dados pessoais</strong></p>
                  <p>Os dados pessoais coletados dos usuários, incluindo aqueles direta ou indiretamente relacionados com a sua saúde, serão tratados para efeitos de prestação de cuidados integrados de saúde, incluindo gestão dos sistemas respiratórios, acompanhamento de atividade física e demais serviços, auditoria e melhoria contínua da experiência do usuário.</p>
                  <p>Este tratamento de dados seguirá as finalidades previstas no consentimento informado, tais como procedimentos realizados por profissionais da saúde e serviços de saúde, comunicações relevantes para a promoção da sua saúde, dentre outros.</p>
                <p><strong>3.  	Compartilhamento de dados pessoais</strong></p>
                  <p>Haverá transmissão e comunicação de dados pessoais entre os profissionais de saúde e pesquisadores eventualmente atrelados ao cadastro do usuário no aplicativo, com acesso de colaboradores designados, sempre que necessário, para possibilitar a melhor experiência e atendimento à necessidade do usuário.</p>
                  <p>Poderá ocorrer, ainda, a transmissão dos seus dados a entidades contratadas que de alguma forma precisem atuar colaborando para sua melhor experiência e para o melhor atendimento, como por exemplo, conselho de classes, instituições de ensino, instituições médicas e laboratórios, assumindo o aplicativo Inspir<b>ar</b> o compromisso de junto a seus contratados exigir aderência às regulamentações aplicáveis.</p>
                  <p>Poderemos também transmitir dados pessoais dos usuários a terceiros quando tais comunicações de dados se tornem necessárias ou adequadas: (i) à luz da lei aplicável, (ii) no cumprimento de obrigações legais/ordens judiciais, (iii) por determinação da Autoridade Nacional de Proteção de Dados ou de outra autoridade de controle competente, ou (iv) para responder a solicitações de autoridades públicas ou governamentais.</p>
                <p><strong>4.  	Transferência internacional de dados</strong></p>
                  <p>Não haverá aluguel, venda e tampouco liberação de dados a terceiros com a finalidade de permitir qualquer comercialização dos serviços, mas informamos que seus dados poderão ser transferidos e mantidos em ambiente fora do seu município, estado ou país onde as leis de proteção de dados podem ser diferentes das vigentes no Brasil mas asseguram grau de proteção de dados pessoais adequado ao previsto na Lei Geral de Proteção de Dados.</p>
                  <p>Tomaremos todas as medidas razoavelmente necessárias para garantir que seus dados sejam tratados de forma confiável, segura e de acordo com esta Política de Privacidade.​</p>
                <p><strong>5.  	Conservação de dados pessoais</strong></p>
                  <p>Os dados são conservados pelo período estritamente necessário para cada uma das finalidades descritas acima e/ou de acordo com prazos legais vigentes. Em caso de litígio pendente, os dados podem ser conservados até trânsito em julgado da decisão.</p>
                  <p>Adicionalmente, serão mantidos em funcionamento todos os meios técnicos ao alcance para evitar a perda, má utilização, alteração, acesso não autorizado e apropriação indevida dos dados pessoais dos usuários. Em qualquer caso, note-se que, circulando os dados em rede de Internet aberta, não é possível eliminar totalmente o risco de acesso e utilização não autorizados, pelo que o usuário deverá programar medidas de segurança adequadas para a navegação nos portais do aplicativo.</p>
                <p><strong>6.  	Cookies e tecnologias semelhantes</strong></p>
                  <p>O aplicativo inspir<b>ar</b> poderá receber e armazenar dados dos seus usuários mediante a utilização de cookies. A maioria dos programas de navegação está definida para aceitar cookies automaticamente, embora seja possível configurar o navegador para recusar todos os cookies, ou para indicar quando um cookie será enviado. Quando o cookie é aceito, em uma próxima visita ao aplicativo o servidor de Internet reconhecerá o computador ou dispositivo móvel do usuário.</p>
                  <p>O aplicativo poderá utilizar cookies persistentes ou de sessão, sendo sua finalidade meramente analítica, de forma a fornecer uma análise estatística da navegação com o objetivo de melhorar a experiência de navegação dos usuários.</p>
                  <p>O aplicativo poderá registrar dados relacionados à sua navegação e utilização dos recursos da plataforma e comunicar esses dados aos desenvolvedores, com o intuito de aperfeiçoar a sua experiência. Nenhum dado pessoal é coletado. Ao usar o aplicativo, você consente com essa coleta e com a transferência ao desenvolvimento.</p>
                <p><strong>7.  	Direitos dos usuários</strong></p>
                  <p>Nos termos da legislação aplicável, o titular do dado poderá a qualquer tempo solicitar o acesso aos dados que lhe digam respeito, bem como a sua retificação, eliminação ou a limitação de uso do dado pessoal, a portabilidade dos seus dados, ou ainda opor-se ao seu tratamento, exceto nos casos previstos em lei. Poderá exercer estes direitos mediante pedido escrito dirigido ao e-mail <a href="appinspirar@gmail.com">appinspirar@gmail.com</a>.</p>
                <p><strong>8.  	Reclamações e dúvidas</strong></p>
                  <p>Caso tenha qualquer dúvida relacionada com o tratamento dos seus dados pessoais e com os direitos que lhe são conferidos pela legislação aplicável e, em especial, referidos nesta Política, poderá acionar, através do e-mail <a href="appinspirar@gmail.com">appinspirar@gmail.com</a>, a equipe do aplicativo inspir<b>ar</b>.</p>
                  <p>O usuário tem ainda o direito de apresentar uma reclamação à Autoridade Nacional de Proteção de Dados, conforme previsto em lei.</p>
                <p><strong>9.  	Alterações à política de privacidade e cookies</strong></p>
                  <p>Poderemos alterar esta Política de Privacidade de Dados e Uso de Cookies a qualquer momento. Estas alterações serão devidamente disponibilizadas e, caso represente uma mudança substancial relativamente à forma como os seus dados serão tratados, haverá contato conforme dados disponibilizados.</p>
              </div>
              <div style={ {fontFamily: 'roboto',fontSize: 20,fontWeight: 400,color: '#18191F',letterSpacing: 1}}>
                <div >{/**align="justify" */}
                    <b>1.  	Coleta de dados pessoais</b><br/><br/>
                    A coleta de dados pessoais é necessária para que o aplicativo inspir<b>ar</b> ofereça serviços e funcionalidades adequados às necessidades dos usuários, bem como para personalizar serviços, fazendo com que sua experiência seja a mais cômoda e satisfatória possível.<br/><br/>
                    Os dados pessoais serão solicitados por meio de um profissional de saúde ou diretamente ao usuário, de forma que cabe ao usuário consentir com seu fornecimento e veracidade. Eles são fundamentais para dar sequência ao cadastro e utilização do aplicativo, assim como para o potencial acompanhamento médico posterior.<br/><br/>
                    <b>2.  	Utilização de dados pessoais</b><br/><br/>
                    Os dados pessoais coletados dos usuários, incluindo aqueles direta ou indiretamente relacionados com a sua saúde, serão tratados para efeitos de prestação de cuidados integrados de saúde, incluindo gestão dos sistemas respiratórios, acompanhamento de atividade física e demais serviços, auditoria e melhoria contínua da experiência do usuário.<br/><br/>
                    Este tratamento de dados seguirá as finalidades previstas no consentimento informado, tais como procedimentos realizados por profissionais da saúde e serviços de saúde, comunicações relevantes para a promoção da sua saúde, dentre outros.<br/><br/>
                    <b>3.  	Compartilhamento de dados pessoais</b><br/><br/>
                    Haverá transmissão e comunicação de dados pessoais entre os profissionais de saúde e pesquisadores eventualmente atrelados ao cadastro do usuário no aplicativo, com acesso de colaboradores designados, sempre que necessário, para possibilitar a melhor experiência e atendimento à necessidade do usuário.<br/><br/>
                    Poderá ocorrer, ainda, a transmissão dos seus dados a entidades contratadas que de alguma forma precisem atuar colaborando para sua melhor experiência e para o melhor atendimento, como por exemplo, conselho de classes, instituições de ensino, instituições médicas e laboratórios, assumindo o aplicativo inspir<b>ar</b> o compromisso de junto a seus contratados exigir aderência às regulamentações aplicáveis.<br/><br/>
                    Poderemos também transmitir dados pessoais dos usuários a terceiros quando tais comunicações de dados se tornem necessárias ou adequadas: (i) à luz da lei aplicável, (ii) no cumprimento de obrigações legais/ordens judiciais, (iii) por determinação da Autoridade Nacional de Proteção de Dados ou de outra autoridade de controle competente, ou (iv) para responder a solicitações de autoridades públicas ou governamentais.<br/><br/>
                    <b>4.  	Transferência internacional de dados</b><br/><br/>
                    Não haverá aluguel, venda e tampouco liberação de dados a terceiros com a finalidade de permitir qualquer comercialização dos serviços, mas informamos que seus dados poderão ser transferidos e mantidos em ambiente fora do seu município, estado ou país onde as leis de proteção de dados podem ser diferentes das vigentes no Brasil mas asseguram grau de proteção de dados pessoais adequado ao previsto na Lei Geral de Proteção de Dados.<br/><br/>
                    Tomaremos todas as medidas razoavelmente necessárias para garantir que seus dados sejam tratados de forma confiável, segura e de acordo com esta Política de Privacidade.​<br/><br/>
                    <b>5.  	Conservação de dados pessoais</b><br/><br/>
                    Os dados são conservados pelo período estritamente necessário para cada uma das finalidades descritas acima e/ou de acordo com prazos legais vigentes. Em caso de litígio pendente, os dados podem ser conservados até trânsito em julgado da decisão.<br/><br/>
                    Adicionalmente, serão mantidos em funcionamento todos os meios técnicos ao alcance para evitar a perda, má utilização, alteração, acesso não autorizado e apropriação indevida dos dados pessoais dos usuários. Em qualquer caso, note-se que, circulando os dados em rede de Internet aberta, não é possível eliminar totalmente o risco de acesso e utilização não autorizados, pelo que o usuário deverá programar medidas de segurança adequadas para a navegação nos portais do aplicativo.<br/><br/>
                    <b>6.  	Cookies e tecnologias semelhantes</b><br/><br/>
                    O aplicativo inspir<b>ar</b> poderá receber e armazenar dados dos seus usuários mediante a utilização de cookies. A maioria dos programas de navegação está definida para aceitar cookies automaticamente, embora seja possível configurar o navegador para recusar todos os cookies, ou para indicar quando um cookie será enviado. Quando o cookie é aceito, em uma próxima visita ao aplicativo o servidor de Internet reconhecerá o computador ou dispositivo móvel do usuário.<br/><br/>
                    O aplicativo poderá utilizar cookies persistentes ou de sessão, sendo sua finalidade meramente analítica, de forma a fornecer uma análise estatística da navegação com o objetivo de melhorar a experiência de navegação dos usuários.<br/><br/>
                    <b>7.  	Direitos dos usuários</b><br/><br/>
                    Nos termos da legislação aplicável, o titular do dado poderá a qualquer tempo solicitar o acesso aos dados que lhe digam respeito, bem como a sua retificação, eliminação ou a limitação de uso do dado pessoal, a portabilidade dos seus dados, ou ainda opor-se ao seu tratamento, exceto nos casos previstos em lei. Poderá exercer estes direitos mediante pedido escrito dirigido ao e-mail appinspirar@gmail.com.<br/><br/>
                    <b>8.  	Reclamações e dúvidas</b><br/><br/>
                    Caso tenha qualquer dúvida relacionada com o tratamento dos seus dados pessoais e com os direitos que lhe são conferidos pela legislação aplicável e, em especial, referidos nesta Política, poderá acionar, através do e-mail appinspirar@gmail.com, a equipe do aplicativo inspir<b>ar</b>.<br/><br/>
                    O usuário tem ainda o direito de apresentar uma reclamação à Autoridade Nacional de Proteção de Dados, conforme previsto em lei.<br/><br/>
                    <b>9.  	Alterações à política de privacidade e cookies</b><br/><br/>
                    Poderemos alterar esta Política de Privacidade de Dados e Uso de Cookies a qualquer momento. Estas alterações serão devidamente disponibilizadas e, caso represente uma mudança substancial relativamente à forma como os seus dados serão tratados, haverá contato conforme dados disponibilizados.<br/><br/>
                </div>
              </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.skip}></Grid>

        <Grid container component="footer_buttons" style={{backgroundColor:'black'}} >
          <Grid container item={true} md={12} xl={12} sm={12}  className={classes.columnContentHeader} direction="row" justifyContent="left">
            <Button onClick={() => {scrollTo(headerRef.current)}} variant={"outlined"} color="primary" style={{marginTop:'3vh', height:'12vh', marginLeft:'2vw', width:'18vw',minWidth:'25vw'}}>
                Ir para o topo da página
            </Button>
            <Button onClick={() => {scrollTo(headerRef.current); history.push('/')}} variant={"outlined"} color="primary" style={{marginTop:'3vh', height:'12vh', marginLeft:'2vw', width:'20vw',minWidth:'25vw'}}>
                Voltar para a página inicial
            </Button>
          </Grid>
        </Grid>
        <Grid container component="footer_buttons"  style={{backgroundColor:'black'}} >
              <Grid container item={true} md={12} xl={12} sm={12}  className={classes.columnContentHeader} direction="row" justifyContent="left">
                <Button onClick={() => {scrollTo(headerRef.current); history.push('/termsofuse')}} variant={"outlined"} color="secondary" style={{marginTop:'3vh', height:'12vh', marginLeft:'2vw', width:'16vw',minWidth:'25vw'}}>
                    Termos de Serviço
                </Button>
                <Button onClick={() => {scrollTo(headerRef.current); history.push('/privacypolicy')}} variant={"outlined"} color="secondary" style={{marginTop:'3vh', height:'12vh', marginLeft:'2vw', width:'16vw',minWidth:'25vw'}}>
                    Política de Privacidade
                </Button>
              </Grid>
         </Grid>   

        <Grid container component="footer_line" style={{backgroundColor:'black'}} >
          <Grid container item={true} md={12} xl={12} sm={12} className={classes.columnContentFooter} direction="row" justifyContent="left">
            <div className={classes.textFooter}>
                © 2024 inspir<b>ar</b>. Todos os direitos reservados.

            </div>
          </Grid>
        </Grid>
      </Grid>
  );
}