import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 250;

export const useDetailsStyles = makeStyles(() => ({
    root: {
      display: "flex",
      alignItems: "start",
      height: "100vh",
      width: "100%",
      backgroundColor: "#FAFAFA",
    },
    "@media(min-width: 968px)": {
      root: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: 250,
      },
    },
    loading: {
      width: "100%",
      position: "fixed",
      top: 0,
      height: 7,
    },
    mainContent: {
      width: "100%",
      height: "100%",
      overflow: "auto",
    },
    box: {
      width: "100%",
      justifyContent: "center",
    },
    box_buttons: {
      marginBottom: 10,
    },
    button: {
      marginLeft: 5,
      marginRight: 5,
    },
    hugeSkip: {
      marginBottom: 60,
    },
    bigSkip: {
      marginBottom: 40,
    },
    skip: {
      marginTop: 10,
      marginBottom: 20,
    },
    columnContent: {
      marginTop: 50,
      height: "auto",
      paddingLeft: 20,
      paddingRight: 20,
    },
    content: {
      flexGrow: 1,
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: "white",
      borderRadius: 5,
      minHeight: 200,
      width: "100%",
      justifyContent: "flex-start",
      padding: 5,
    },
    title: {
      fontSize: 18,
      letterSpacing: 1,
      fontWeight: 600,
      marginBottom: 18,
      marginLeft: 20,
      marginTop: 15,
      width: "100%",
    },
    title_2: {
      fontSize: 16,
      letterSpacing: 1,
      fontWeight: 550,
      marginBottom: 10,
      marginLeft: 20,
      marginTop: 0,
      width: "100%",
    },
    cardColumn: {
      paddingLeft: 20,
    },
    text: {
      fontSize: 14,
      marginBottom: 10,
      color: "#858585",
    },
    textMedium: {
      fontSize: 15,
      fontWeight: 500,
      marginBottom: 10,
      color: "#858585",
    },
    textRegular: {
      fontSize: 14,
      fontWeight: 400,
      marginBottom: 3,
      marginTop: 0,
      color: "#858585",
    },
    textBig: {
      fontSize: 17,
      fontWeight: 600,
      marginBottom: 10,
      color: "#858585",
    },
    textBigGreen: {
      fontSize: 21,
      fontWeight: 600,
      marginBottom: 10,
      marginLeft: 30,
      color: "#62aa7d",
    },
    textBigBlue: {
      fontSize: 21,
      fontWeight: 600,
      marginBottom: 10,
      marginLeft: 40,
      color: "#8888d8",
    },
    textBigRed: {
      fontSize: 21,
      fontWeight: 600,
      marginBottom: 10,
      marginLeft: 40,
      color: "#b50404",
    },
    medTable: {
      height: `calc(1.5*${drawerWidth}px)`,
      marginTop: -5,
      marginLeft: 15,
      marginRight: 15,
    },
    datePicker: {
      paddingBottom: 20,
      paddingLeft: 20,
    },
  }));
  