import {
  Button,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { prepareData } from "../../controllers/professionalController";
import Header from "../../components/header";
import Menu from "../../components/menu";
import CookieConsent from "../../components/cookiesConsent";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
  },

  loading: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    position: "fixed",
    top: 0,
    height: 7,
  },
  upperContent: {
    flexGrow: 1,
    marginTop: 80,
    paddingRight: 30,
    paddingLeft: 30,
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    padding: 30,
    justifyContent: "center",
  },
  add: {
    width: 240,
    textTransform: "capitalize",
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: 400,
    height: 50,
    borderRadius: 8,
  },
  paper: {
    width: "100%",
    minHeight: 600,
    padding: 20,
  },
  title: {
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: 600,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 30,
  },
  professionalsTable: {
    height: "95%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  "@media(min-width: 968px)": {
    contentRoot: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    upperContent: {
      justifyContent: "flex-end",
    },
  },
}));

export default function Professionals() {
  const classes = useStyles();
  const history = useHistory();
  const [professionalsTable, setData] = useState({ rows: [], columns: [] });
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const [rows, columns] = await prepareData();
    var professionals = {
      rows: rows,
      columns: columns,
    };
    setData(professionals);
    setLoading(false);
  }, []);

  return (
    <>
      <CookieConsent />
      <Grid container className={classes.contentRoot} alignContent="flex-start">
        <Header label="Profissionais" />
        {loading && <LinearProgress className={classes.loading} />}
        <Menu history={history} />
        <Grid container className={classes.upperContent}>
          <Button
            variant="contained"
            color="primary"
            className={classes.add}
            onClick={() => history.push("newprofessional#personal")}
            disabled={loading}
          >
            Cadastrar novo profissional
          </Button>
        </Grid>
        <Grid container className={classes.content}>
          <Paper elevation={1} className={classes.paper}>
            <Typography className={classes.title}>Profissionais</Typography>
            <DataGrid
              className={classes.professionalsTable}
              rows={professionalsTable.rows}
              columns={professionalsTable.columns}
              pageSize={10}
              rowHeight={40}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
