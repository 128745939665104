import {Button, Typography, Link, Grid, InputLabel, Paper, Card } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; 
import {useParams} from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';

import {prepareData} from '../../controllers/patientController'
import login from '../../controllers/loginController'
import logo from '../../assets/logo.png'
import Menu from '../../components/menu'  
import Header from '../../components/header'
import logo_inspirar from '../../assets/logo-inspirar.png'


const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start"
  });
};

const useStyles = makeStyles((theme) => ({
    top: {
      /*position: 'sticky',
      top: '0',
      left: '0',
      right: '0',
      zIndex: '1',*/
      height: '20vh',
      widht: '100vw',
      backgroundColor: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    footer: {
      height: '20vh',
      widht: '100vw',
      backgroundColor: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    root: {
      height: '100vh',
      widht: '100vw',
      backgroundColor: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    tab_to_right: {
      height: 'auto',
      widht: '100vw',
      paddingTop: '5vh',
      paddingLeft: '4vw',// '10vw'
      paddingRight: '4vw',
      backgroundColor: '#ffffff',//#ffffff
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    tab_to_left: {
      height: 'auto',
      widht: '100vw',
      paddingTop: '20vh',
      paddingLeft: '4vw',
      paddingRight: '10vw',
      backgroundColor: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    inside_tab_light: {
      backgroundColor: '#9f88f2',
    },
    inside_tab_ultralight: {
      backgroundColor: 'rgba(211, 205, 250, 0.47)',// '#bcbbf2'
    },
    textTitleLight: {
        fontFamily: 'roboto',
        fontSize: 35,
        fontWeight: 800,
        color: '#18191F',
        marginTop: '2vh',
        marginBottom: '4vh',
        letterSpacing: 8
    },
    textLight: {
        fontFamily: 'roboto',
        fontSize: 20,
        fontWeight: 400,
        color: '#18191F',
        letterSpacing: 3
    },
    textTitleUltraLight: {
        fontFamily: 'roboto',
        fontSize: 35,
        fontWeight: 800,
        color: '#18191F',
        marginTop: '2vh',
        marginBottom: '4vh',
        letterSpacing: 8
    },
    textUltraLight: {
        fontFamily: 'roboto',
        fontSize: 20,
        fontWeight: 400,
        color: '#18191F',
        letterSpacing: 3
    },
    textFooter: {
        fontFamily: 'roboto',
        fontSize: 20,
        fontWeight: 400,
        color: '#808080',
        marginLeft:'2vw',
        letterSpacing: 0.4,
       // backgroundColor: 'yellow',
        position: 'relative',
        top: -40
     
    },
    mainContent: {
      width: '100%',
      height: '100%',
    },
    columnContent: {
      height: 'auto',
      paddingTop: '9vh',
      paddingBottom: '9vh',
      paddingLeft: '3vw',
      paddingRight: '3vw'
    },
    columnContentHeader: {
      height: 'auto',
      paddingTop: '6vh',//'12vh'
      paddingBottom: '5vh',//0vh
      paddingLeft: '4vw',//'10vw'
      paddingRight: '4vw',
     // backgroundColor: 'red'
    },
    columnContentFooter: {
      height: 'auto',
      paddingTop: '6vh',//'6vh'
      paddingBottom: '0vh',
      paddingLeft: '3vw',
      paddingRight: '3vw',
      //backgroundColor: 'blue',
      //marginTop: 20,
      ///position: 'fixed',
      top: 5,

    },
    name: {
      fontSize: 60,
      fontWeight: 100,
      letterSpacing: 1,
      marginBottom: '10vh',
      marginLeft: '1.8vw'
    },
    skip: {
      backgroundColor: '#ffffff',
      height: '0vh'//'10vh'
    },
    bigSkip: {
      backgroundColor: '#ffffff',
      height: '20vh'
    },
    footerButton: {
      marginTop: '1vh', 
      height: '12vh', 
      marginLeft: '0.5vw', 
      width: '35vw',
      minWidth: '35vw',
      [theme.breakpoints.down(800)]: {
        marginLeft: '1vw', 
      },
      [theme.breakpoints.down(1200)]: {
        height: '15vh', 
        marginLeft: '3vw', 
        width: '16vw',
      },
    },
    footerStyle:{
      backgroundColor: 'black',
      padding: theme.spacing(2),
      bottom: 0,
      width: '100%',
    },
    textStyle:{
      fontFamily: 'roboto',
      fontSize: 20,
      fontWeight: 400,
      color: '#18191F',
      letterSpacing: 1,
      overflowWrap: 'break-word',
      minWidth: 260
    }
  }));
  
export default function TermsOfUse() {




  const classes = useStyles();
  const history = useHistory();

  const headerRef = useRef(null);
  const buttonOneRef = useRef(null);

  return (
    <Grid>
      <Grid container component="top" className={classes.top} ref={headerRef} >
        <Grid container item={true} md={12} xl={12} sm={12} className={classes.columnContentHeader} direction="row" justifyContent="left">
          <img src={logo_inspirar} style={{'margin':'1rem 0'}}/>    
        </Grid>
      </Grid>
      <Grid container component="main1" className={classes.tab_to_right} ref={buttonOneRef}>
        
        <Grid container className={classes.inside_tab_ultralight}>
          <Grid container item={true} md={12} xl={12} sm={12} className={classes.columnContent} direction="column" justifyContent="left">

            <div className={classes.textTitleUltraLight}>
              <b>Termos de Serviço e Uso</b>
            </div>
            <div className={classes.textStyle}>


                <div>{/**align="justify" */}

                  <p>O sistema e plataforma do Inspir<strong>ar</strong> seguirá as condições e termos descritos neste documento.</p>
                  <p>Ao baixar o aplicativo inspirar e utilizar sua plataforma, você concorda com o termo de serviço e uso aqui descrito. Essa autorização é necessária para utilização dos serviços da plataforma, e, sem a sua aceitação, o serviço não poderá ser provido</p>
                  <p>Mudanças deste documento podem ser feitas ao longo do tempo, e, assim que efetuadas, entram em vigor imediatamente. Logo, recomenda-se uma revisão periódica por parte do usuário deste documento. As mudanças serão consideradas aceitas por um usuário se este continuar a utilizar os serviços da plataforma.</p>

                  <p> <strong> 1.Uso do inspirar</strong></p>
                      <p>A plataforma do inspirar não deve ser utilizada como substituta para tratamentos médicos, diagnósticos, acompanhamento médico continuado ou uso de medicamentos, devendo ser utilizada apenas como uma auxiliar no controle, documentação e acompanhamento dos sintomas da asma.</p>
                      <p>As informações contidas em links que levam a sites externos à plataforma não são verificadas, podendo estar desatualizadas. Portanto, aconselha-se que estas informações sejam sempre verificadas antes de se fazer uso destes dados.</p>
                      <p>Qualquer material presente na plataforma não deve ser utilizado de forma comercial, apenas para uso próprio (por parte dos pacientes) ou profissional (por parte dos profissionais de saúde cadastrados).</p>

                  <p> <strong> 2.Uso malicioso da plataforma</strong></p>
                      <p>Ao concordar com este termo, você concorda em não efetuar nenhuma ação que resulte ou possa resultar em algum dano para o desempenho, disponibilidade ou segurança da plataforma.</p>

                  <p> <strong> 3. Condutas e regras </strong></p>
                      <p>Ao concordar com este termo, você concorda em não fornecer informações falsas, imprecisas ou de dupla interpretação.</p>
                      <p>Além disso, concorda também em não adicionar mensagens na plataforma que sejam ofensivas, que contenham preconceito de qualquer forma ou apresentem conteúdo impróprio.</p>

                  <p> <strong> 4. Registro de conta</strong></p>
                      <p>O registro de uma conta de usuário (paciente) deve ser feito em conjunto com um profissional da saúde previamente registrado. Ao efetuar o registro, o paciente confirma que seu registro foi feito de maneira correta, em companhia de um profissional de saúde já cadastrado.</p>
                      <p>Ao se cadastrar na plataforma, o usuário confirma que é maior de idade e consente em nos informar os dados necessários para o cadastro.</p>
                      <p>O registro de um profissional da saúde na plataforma deve ocorrer em conjunto com um responsável por uma conta administrador. Ao se registrar, o profissional da saúde confirma que seu cadastro foi realizado em companhia do detentor da conta de administrador atual da plataforma.</p>
                      <p>No momento do cadastro, a senha é enviada somente por e-mail próprio da plataforma, e deve ser guardada de forma segura pelos usuários. Fica isenta a plataforma de responsabilidade da perda de senha por parte dos seus usuários.</p>

                  <p> <strong> 5. Direitos de uso </strong></p>
                      <p>Aos cadastrados na plataforma se reservam os direitos de uso da plataforma, de forma limitada, mas não-exclusiva, com período de duração de uso indefinido.</p>
                      <p>O usuário não concorda mais com o termo aqui descrito ao deletar sua conta e revogar o seu direito de uso do aplicativo/plataforma.</p>

                  <p> <strong> 6. Limitação de responsabilidades</strong></p>
                      <p>O download e uso do aplicativo ocorre de acordo com o risco próprio do usuário, logo, a plataforma não é responsável por qualquer dano que possa ser causado ao aparelho celular ou outros aparelhos, incluindo danos causados ao aplicativo por meio de vírus.</p>
                      <p>A plataforma também não será responsabilizada por qualquer dano físico causado ao usuário por conta de uso indevido, incluindo dano direto, indireto, incidental, recomendando sempre que o usuário realize suas atividades dentro das metas recomendadas pelos profissionais de saúde.</p>

                  <p> <strong> 7. Nossos direitos às informações registradas </strong></p>
                      <p>Ao adicionar novas informações à plataforma do inspirar, você consente com o uso e processamento destes dados, conforme descrito no termo de privacidade da plataforma.</p>
                      <p>Ao usar o serviço, você consente com a coleta anonimizada de dados relacionados à sua navegação e utilização da plataforma, com o intuito de aperfeiçoamento da experiência de usuário. Consente também com a visualização, de forma anônima, de seus dados para estudos e pesquisas médicas futuras, que terão o intuito de auxiliar na compreensão da asma, assim como para criação de novos tratamentos.</p>

                  <p> <strong> 8. Contato </strong></p>
                      <p>Se houver qualquer dúvida em relação ao termo descrito, entre em contato com a equipe do inspirar por e-mail <a href="appinspirar@gmail.com">(appinspirar@gmail.com)</a>.</p>

                </div>

            </div>
    
    
          </Grid>
        </Grid>
      </Grid>

      
          <Grid container component="footer_buttons" className={classes.footerStyle}>
                <Grid container item={true} md={12} xl={12} sm={12}  className={classes.columnContentHeader} direction="row" justifyContent="left">
                  <Button onClick={() => {scrollTo(headerRef.current)}} variant={"outlined"} color="primary" className={classes.footerButton}>
                      Ir para o topo da página
                  </Button>
                  <Button onClick={() => {scrollTo(headerRef.current); history.push('/')}} variant={"outlined"} color="primary" className={classes.footerButton}>
                      Voltar para a página inicial
                  </Button>
                </Grid>
          </Grid>

          <Grid container component="footer_buttons"  className={classes.footerStyle} >
            <Grid container item={true} md={12} xl={12} sm={12} className={classes.columnContentHeader} direction="row" justifyContent="left">
              <Button onClick={() => {scrollTo(headerRef.current); history.push('/termsofuse')}} variant={"outlined"} color="secondary" className={classes.footerButton}>
                  Termos de Serviço
              </Button>
              <Button onClick={() => {scrollTo(headerRef.current); history.push('/privacypolicy')}} variant={"outlined"} color="secondary" className={classes.footerButton}>
                  Política de Privacidade
              </Button>
            </Grid>
          </Grid>               

          <Grid container component="footer_line"  style={{backgroundColor:'black'}}>
            <Grid container item={true} md={12} xl={12} sm={12}  className={classes.columnContentFooter} direction="row" justifyContent="left">
              <div className={classes.textFooter}>
                  ©2024 Inspir<b>ar</b>. Todos os direitos reservados.
              </div>
            </Grid>
          </Grid>          
    </Grid>
  );
}