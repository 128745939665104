import {
  Button,
  Typography,
  TextField,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import lateral from "../../assets/lateral.png";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {
  removePatients,
  removeUser,
  setToken as addToken,
} from "../../actions";
import { requestAccessWithToken } from "../../services/userData";
import CookieConsent from "../../components/cookiesConsent";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  lateral: {
    backgroundImage: `url(${lateral})`,
    height: "100vh",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: 30,
    paddingRight: ({ isMobile }) => (isMobile ? 30 : 100),
    paddingLeft: ({ isMobile }) => (isMobile ? 30 : 150),
    position: "relative",
    minHeight: "100vh",
  },
  logo: {
    height: 80,
    width: 80,
  },
  name: {
    fontSize: 70,
    marginLeft: 10,
    fontWeight: 300,
    letterSpacing: 1,
  },
  submit: {
    textTransform: "unset",
    width: "100%",
    marginTop: 20,
    height: 45,
  },
  loading: {
    width: "100%",
    position: "fixed",
    top: 0,
    height: 7,
  },
  error: {
    fontSize: 12,
  },
  forgot: {
    fontSize: 14,
    cursor: "pointer",
    position: "absolute",
    top: "70px",
  },
}));

export default function TokenAccess() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const isMobile = useMediaQuery("(max-width:968px)");
  const classes = useStyles({ isMobile });

  const [openErrorWarning, setOpenErrorWarning] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorWarning(false);
  };

  async function access() {
    if (!token) {
      setError("Token é obrigatório");
      return;
    }
    setLoading(true);

    try {
      // Verifica se o paciente existe, e armazena o token na store para mais tarde
      const result = await requestAccessWithToken(token);
      dispatch(addToken({ token, email: result.data.data.email }));
      dispatch(removePatients());
      dispatch(removeUser());
      history.push(`patient/${result.data.data.email}`);
    } catch (e) {
      if (e.response.status === 404) {
        setError("Paciente não encontrado.");
      } else {
        setOpenErrorWarning(true);
        console.error(e?.response?.data?.message);
      }
    }
    setLoading(false);
  }

  const handleChange = (event) => {
    const value = event.target.value;

    if (/^\d{0,6}$/.test(value)) {
      setToken(value);
      if (value.length !== 6) {
        setError("O token deve ter exatamente 6 dígitos numéricos");
      } else {
        setError("");
      }
    }
  };

  return (
    <>
      <CookieConsent />
      <Grid
        container
        component="main"
        className={classes.root}
        alignItems="center"
      >
        {loading ? (
          <LinearProgress className={classes.loading} color="primary" />
        ) : (
          ""
        )}
        {!isMobile && <Grid item className={classes.lateral} lg={5}></Grid>}
        <Snackbar
          open={openErrorWarning}
          autoHideDuration={3500}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Algo deu errado!
          </Alert>
        </Snackbar>
        <Grid item className={classes.content} xs={12} lg={7}>
          <Typography
            disabled={loading}
            className={classes.forgot}
            onClick={() => history.go(-1)}
          >
            <ArrowBack style={{ fill: "#646469" }} />
          </Typography>

          <div>
            <h1 style={{ color: "#4B4A50" }}>Entrar com token</h1>
            <Typography style={{ color: "#7E7E82", marginTop: "5px" }}>
              Se você é um profissional de saúde e deseja acessar diretamente um
              painel de dados de saúde, digite o código de identificação (token)
              de seu/sua paciente no campo abaixo. Esse token é informado na
              área de perfil do aplicativo Inspirar.
            </Typography>
          </div>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="token"
            label="Token"
            name="text"
            autoFocus
            color="primary"
            value={token}
            onChange={handleChange}
            error={!!error}
            helperText={error}
          />
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading || !token || error}
            onClick={access}
          >
            Acessar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
