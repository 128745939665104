import {
  Button,
  Typography,
  Link,
  Grid,
  InputLabel,
  Paper,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import Amplify, { Auth, API } from "aws-amplify";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";

import { prepareData } from "../../controllers/patientController";
import login from "../../controllers/loginController";
import logo from "../../assets/logo.png";
import Menu from "../../components/menu";
import Header from "../../components/header";

import logo_inspirar from "../../assets/logo-inspirar.png";
import CookieConsent from "../../components/cookiesConsent";
const scrollTo = (ele) => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const useStyles = makeStyles((theme) => ({
  top: {
    /*position: 'sticky',
      top: '0',
      left: '0',
      right: '0',
      zIndex: '1',*/
    height: "20vh",
    widht: "100vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  footer: {
    height: "20vh",
    widht: "100vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  root: {
    height: "100vh",
    widht: "100vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  //
  tab_to_center: {
    height: "auto",
    widht: "100vw",
    paddingTop: "2vh",
    paddingLeft: "4vw",
    paddingRight: "4vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  //
  tab_to_right: {
    height: "auto",
    widht: "100vw",
    paddingTop: "20vh",
    paddingLeft: "10vw",
    paddingRight: "4vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tab_to_left: {
    height: "auto",
    widht: "100vw",
    paddingTop: "20vh",
    paddingLeft: "4vw",
    paddingRight: "10vw",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  inside_tab_light: {
    backgroundColor: "#9f88f2",
  },
  inside_tab_ultralight: {
    backgroundColor: "rgba(211, 205, 250, 0.47)", //bcbbf2
  },
  textTitleLight: {
    fontFamily: "roboto",
    fontSize: 35,
    fontWeight: 800,
    color: "#18191F",
    marginTop: "2vh",
    marginBottom: "4vh",
    letterSpacing: 8,
  },
  textLight: {
    fontFamily: "roboto",
    fontSize: 20,
    fontWeight: 400,
    color: "#18191F",
    letterSpacing: 1.5,
  },
  textTitleUltraLight: {
    fontFamily: "roboto",
    fontSize: 35,
    fontWeight: 800,
    color: "#18191F",
    marginTop: "2vh",
    marginBottom: "4vh",
    letterSpacing: 8,
  },
  textUltraLight: {
    fontFamily: "roboto",
    fontSize: 20,
    fontWeight: 800,
    color: "#18191F",
    letterSpacing: 1, //3
  },
  textFooter: {
    fontFamily: "roboto",
    fontSize: 20,
    fontWeight: 400,
    color: "#808080",
    marginLeft: "2vw",
    letterSpacing: 0.4,
    position: "relative",
    top: -40,
  },
  mainContent: {
    width: "100%",
    height: "100%",
  },
  columnContent: {
    height: "auto",
    paddingTop: "9vh",
    paddingBottom: "9vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  columnContentHeader: {
    height: "auto",
    paddingTop: "12vh",
    paddingBottom: "0vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  columnContentFooter: {
    height: "auto",
    paddingTop: "6vh",
    paddingBottom: "0vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  name: {
    fontSize: 60,
    fontWeight: 100,
    letterSpacing: 1,
    marginBottom: "5vh", //'10vh'
    marginLeft: "3.8vw", //'1.8vw'
  },
  skip: {
    backgroundColor: "#ffffff",
    height: "10vh",
  },

  bigSkip: {
    backgroundColor: "#ffffff",
    height: "20vh",
  },
  medSkip: {
    backgroundColor: "#ffffff",
    height: "5vh",
  },
  faqContent: {
    padding: "10px",
    backgroundColor: "rgba(211, 205, 250, 0.47)",
    justifyContent: "center",
    textAlign: "left",
    width: "70%",
    [theme.breakpoints.down(800)]: {
      width: "100%",
    },
  },
  footerButton: {
    marginTop: "1vh",
    height: "12vh",
    marginLeft: "0.5vw",
    width: "35vw",
    minWidth: "35vw",
    [theme.breakpoints.down(800)]: {
      marginLeft: "1vw",
    },
    [theme.breakpoints.down(1200)]: {
      height: "15vh",
      marginLeft: "3vw",
      width: "16vw",
    },
  },
}));

export default function FAQ() {
  const classes = useStyles();
  const history = useHistory();

  const headerRef = useRef(null);
  const buttonOneRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState(false);

  useEffect(async () => {
    setLoading(true);
    await API.get("webEndpoints", "/materials")
      .then(async (response) => {
        setFaqs(response.faqs);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <CookieConsent />
      <Grid>
        <Grid container component="top" className={classes.top} ref={headerRef}>
          <Grid
            container
            item={true}
            md={12}
            xl={12}
            sm={12}
            className={classes.columnContentHeader}
            direction="row"
            justifyContent="left"
          >
            <img src={logo_inspirar} style={{ margin: "1rem 0" }} />
          </Grid>
        </Grid>
        <Grid className={classes.medSkip}></Grid>
        <Grid
          container
          className={{
            height: "auto",
            widht: "100vw",
            paddingTop: "20vh",
            paddingLeft: "4vw",
            paddingRight: "10vw",
            backgroundColor: "#ffffff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography className={classes.name} color="black">
            <b>Perguntas Frequentes</b>
          </Typography>
        </Grid>

        <Grid container component="main1" ref={buttonOneRef}>
          {faqs.length > 0 ? (
            <List style={{ justifyContent: "center" }}>
              {faqs.map((faq, index) => (
                <ListItem
                  key={faq.material_id}
                  style={{ justifyContent: "center" }}
                >
                  <Paper elevation={1} className={classes.faqContent}>
                    <ListItemText
                      primary={
                        <Typography className={classes.textUltraLight}>
                          {`${index + 1}. ${faq.question_or_title}`}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="body1"
                          className={classes.textLight}
                        >
                          {faq.answer_or_uri}
                        </Typography>
                      }
                    />
                  </Paper>
                </ListItem>
              ))}
            </List>
          ) : (
            ""
          )}
        </Grid>

        <Grid className={classes.skip}></Grid>

        <Grid
          container
          component="footer_buttons"
          style={{ backgroundColor: "black" }}
        >
          <Grid
            container
            item={true}
            md={12}
            xl={12}
            sm={12}
            xs={12}
            className={classes.columnContentHeader}
            direction="row"
            justifyContent="left"
          >
            <Button
              onClick={() => {
                scrollTo(headerRef.current);
              }}
              variant={"outlined"}
              color="primary"
              className={classes.footerButton}
            >
              Ir para o topo da página
            </Button>
            <Button
              onClick={() => {
                scrollTo(headerRef.current);
                history.push("/");
              }}
              variant={"outlined"}
              color="primary"
              className={classes.footerButton}
            >
              Voltar para a página inicial
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          component="footer_buttons"
          style={{ backgroundColor: "black" }}
        >
          <Grid
            container
            item={true}
            md={12}
            xl={12}
            sm={12}
            xs={12}
            className={classes.columnContentHeader}
            direction="row"
            justifyContent="left"
          >
            <Button
              onClick={() => {
                scrollTo(headerRef.current);
                history.push("/termsofuse");
              }}
              variant={"outlined"}
              color="secondary"
              className={classes.footerButton}
            >
              Termos de Serviço
            </Button>
            <Button
              onClick={() => {
                scrollTo(headerRef.current);
                history.push("/privacypolicy");
              }}
              variant={"outlined"}
              color="secondary"
              className={classes.footerButton}
            >
              Política de Privacidade
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          component="footer_line"
          style={{ backgroundColor: "black" }}
        >
          <Grid
            container
            item={true}
            md={12}
            xl={12}
            sm={12}
            className={classes.columnContentFooter}
            direction="row"
            justifyContent="left"
          >
            <Grid className={classes.skip}></Grid>
            <div className={classes.textFooter}>
              © 2024 inspir<b>ar</b>. Todos os direitos reservados.
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
